import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsageMetricsComponent } from './usage-metrics.component';

@NgModule({
  imports: [CommonModule, MatTableModule, MatTooltipModule],
  declarations: [UsageMetricsComponent],
  exports: [UsageMetricsComponent],
})
export class UsageMetricsModule {}
