<h2 *ngIf="creatingNewTemplate">Add Policy</h2>
<div *ngIf="!creatingNewTemplate">
  <h2>{{ getConfigureTemplateTitle() }}</h2>
  <p *ngIf="!!getSelectedTemplateTypeDescriptiveText()">{{ getSelectedTemplateTypeDescriptiveText() }}</p>
</div>
 
<div *ngIf="!!allForms" mat-dialog-content>
  <mat-vertical-stepper linear="true" #stepper (animationDone)="setAllMatStepsAsInteracted()">
    <div *ngIf="creatingNewTemplate">
      <mat-step [stepControl]="templateTypeForm">
        <ng-template matStepLabel>Select Policy Type</ng-template>
        <form
          [formGroup]="templateTypeForm"
          class="stepper-form template-type-form"
          spellcheck="false"
          autocomplete="off"
        >
          <div class="template-type-selection-container">
            <mat-form-field>
              <mat-label>My policy is of type...</mat-label>
              <mat-select 
                #selectTemplateType
                formControlName="template_type"
                (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectTemplateType)"
                (selectionChange)="onTemplateTypeSelection($event.value)"
              >
                <mat-option value=""></mat-option>
                <mat-option
                  *ngFor="let typeOptionData of templateTypeOptionData"
                  [value]="typeOptionData.type"
                  [matTooltip]="getTemplateTypeDescriptiveText(typeOptionData.type)"
                >
                  {{ getFormatedTemplateType(typeOptionData.text) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon
              class="help-icon custom-help-icon"
              [matTooltip]="getTemplateTypeSelectionTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>
        </form>

        <div class="template-definition-container">
          <p>{{ getTemplateDescriptiveTextForStepper() }}</p>
        </div>

        <div class="nav-buttons">
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="nameForm">
        <ng-template matStepLabel>Name</ng-template>
        <portal-stepper-form-field
          placeholder="My policy is called..."
          [formCtrl]="nameForm.get('name')"
          required="true"
          tooltip="Add text here"
        >
        </portal-stepper-form-field>
  
        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
    </div>

    <!-- MFA Config Options -->
    <div *ngIf="getSelectedTemplateTypeFromForm() === policyTemplateType.mfa">
      <mat-step [stepControl]="mfaConfigForm">
        <ng-template matStepLabel>Multi-factor Authentication Configuration</ng-template>
        <portal-stepper-form-field
          placeholder="My policy's second factor request duration is..."
          [formCtrl]="mfaConfigForm.get('duration')"
          required="true"
          [tooltip]="getPolicyTemplateMfaDurationTooltipText()"
        >
        </portal-stepper-form-field>

        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
    </div>

    <!-- Device Info Config Options -->
    <div *ngIf="getSelectedTemplateTypeFromForm() === policyTemplateType.source_info">
      <mat-step [stepControl]="deviceInfoConfigForm">
        <ng-template matStepLabel>Device Information Configuration (optional)</ng-template>
        <!-- Invert -->
        <div class="radio-group-container">
          <label
            class="radio-button-group-label"
          >
            My policy will:
          </label>
          <mat-radio-group
            aria-labelledby="policy template invert selection radio buttons"
            [(ngModel)]="sourceInfoPolicyTemplateElement.invert"
          >
            <mat-radio-button
              [value]="invertFalseValue"
              (change)="onInvertOptionChange(invertFalseValue)"
            >
              deny requests from devices that <b>do</b> match my specified IP subnet and country code lists
            </mat-radio-button>
            <mat-radio-button
              [value]="invertTrueValue"
              (change)="onInvertOptionChange(invertTrueValue)"
            >
              deny requests from devices that <b>do not</b> match my specified IP subnet and country code lists
            </mat-radio-button>
          </mat-radio-group>
        </div>
        
        <!-- Source Subnets -->
        <portal-custom-chiplist-input
          class="chiplist-form-field"
          [chiplistInput]="sourceSubnetsChiplistInput"
          [element]="getTemplateForChiplists()"
          placeholder="My policy applies to IP subnets..."
          [filterChipOptions]="filterChipOptions"
          [isChipRemovable]="true"
          [keyTabManager]="keyTabManager"
          (removeChip)="removeSourceSubnetsChip($event)"
        >
        </portal-custom-chiplist-input>
        <mat-icon 
          class="help-icon custom-help-icon" 
          [matTooltip]="getPolicyTemplateSourceSubnetsTooltipText()"
        >
          help_outline
        </mat-icon>

        <!-- ISO Country Codes -->
        <portal-custom-chiplist-input
          class="chiplist-form-field"
          [chiplistInput]="isoCountryCodesChiplistInput"
          [element]="getTemplateForChiplists()"
          placeholder="My policy applies to country codes..."
          [filterChipOptions]="filterChipOptions"
          [isChipRemovable]="true"
          [keyTabManager]="keyTabManager"
          (removeChip)="removeIsoCountryCodesChip($event)"
        >
        </portal-custom-chiplist-input>
        <mat-icon 
          class="help-icon custom-help-icon" 
          [matTooltip]="getPolicyTemplateIsoCountryCodesTooltipText()"
        >
          help_outline
        </mat-icon>

        <!-- Log Message -->
        <portal-stepper-form-field
          placeholder="My policy's log message is..."
          [formCtrl]="deviceInfoConfigForm.get('log_message')"
          tooltip="If set, will emit a log message with this value when matched"
        >
        </portal-stepper-form-field>

        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
    </div>

    <mat-step [stepControl]="optionalConfigForm">
      <ng-template matStepLabel>Additional Configuration (optional)</ng-template>

      <!-- Description -->
      <portal-stepper-form-field
        placeholder="My policy can be described as..."
        [formCtrl]="optionalConfigForm.get('description')"
      >
      </portal-stepper-form-field>

      <!-- Labels -->
      <div *ngIf="canAssignLabels(getSelectedTemplateTypeFromForm())">
        <portal-custom-chiplist-input
          class="chiplist-form-field"
          [chiplistInput]="labelsChiplistInput"
          [element]="getTemplateForChiplists()"
          placeholder="My policy applies to resources with labels..."
          [filterChipOptions]="filterChipOptions"
          [isChipRemovable]="true"
          [keyTabManager]="keyTabManager"
          (removeChip)="removeLabelChip($event)"
        >
        </portal-custom-chiplist-input>
        <mat-icon 
          class="help-icon custom-help-icon" 
          [matTooltip]="getPolicyTemplateLabelsTooltipText()"
        >
          help_outline
        </mat-icon>
      </div>

      <div class="nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Summary</ng-template>
      <body>
        <div class="summary-container">
          <p>Here is a summary of the policy you have configured. If this information is correct, continue to the "Apply" step to save the policy. Otherwise, return to previous steps to make changes.</p>
          <br>
          <p><b>Type:</b> {{ getTemplateTypeOptionValueText(getSelectedTemplateTypeFromForm()) }}</p>
          <p><b>Name:</b> {{ getNameValueFromForm() }}</p>
          <div *ngIf="getSelectedTemplateTypeFromForm() === policyTemplateType.mfa">
            <p *ngIf="getDurationValueFromForm() !== ''"><b>Duration:</b> {{ getDurationValueFromForm() }}</p>
          </div>
          <div *ngIf="getSelectedTemplateTypeFromForm() === policyTemplateType.source_info">
            <p *ngIf="getLogMessageValueFromForm() !== ''"><b>Log message:</b> {{ getLogMessageValueFromForm() }}</p>
            <p *ngIf="!!getInvertValueFromForm()"><b>Matching inverted:</b> My policy will deny requests from devices that <b>do not</b> match my specified IP subnet and country code lists</p>
            <p><b>Source subnets:</b> {{ getSourceSubnetsListAsString(sourceInfoPolicyTemplateElement) }}</p>
            <p><b>Country codes:</b> {{ getIsoCountryCodesListAsString(sourceInfoPolicyTemplateElement) }}</p>
          </div>
          <p *ngIf="!!getDescriptionValueFromForm()"><b>Description:</b> {{ getDescriptionValueFromForm() }}</p>
          <p *ngIf="hasAtLeastOneLabel()"><b>Label(s):</b> {{ getLabelsListAsStringFromTemplate() }}</p>
        </div>
      </body>

      <div class="nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Apply</ng-template>
      <body>
        <span *ngIf="creatingNewTemplate">Click "Add" to save your new policy</span>
        <span *ngIf="!creatingNewTemplate">Click "Update" to save changes to your policy</span>
        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button
            mat-raised-button
            color="primary"
            matTooltip="Click to save the policy"
            aria-label="A button to save the policy"
            [disabled]="addDisabled"
            (click)="savePolicyTemplate()"
          >
            <span *ngIf="creatingNewTemplate">Add</span>
            <span *ngIf="!creatingNewTemplate">Update</span>
          </button>
        </div>
      </body>
    </mat-step>
  </mat-vertical-stepper>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">CANCEL</button>
  </mat-dialog-actions>
</div>