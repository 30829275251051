import { Column } from './table-layout/column-definitions';
import { ColumnTypes } from './column-types.enum';
import { MatTableDataSource } from '@angular/material/table';
import { getPageSizeOptions } from '@app/shared/utilities/tables/pagination';
import { TableElement } from './table-layout/table-element';
import { updateTableElements } from './utils';
import { ExpandedTableData } from './table-layout/expanded-table-data';
import { OptionalTableElement } from './optional-types';
import { MatMenuTrigger } from '@angular/material/menu';
import { PaginatorConfig } from './table-paginator/table-paginator.component';

export function getColumnDefs<T extends TableElement>(columnDefs: Map<string, Column<T>>, columnType?: ColumnTypes): Array<Column<T>> {
  const allColumnDefs = Array.from(columnDefs.values());
  if (!columnType) {
    // return all columnDefs
    return allColumnDefs;
  }
  return allColumnDefs.filter((columndef) => columndef.type === columnType);
}

export function getDisplayedColumnNames<T extends TableElement>(columnDefs: Map<string, Column<T>>): Array<string> {
  const values = Array.from(columnDefs.values());
  return values.filter((value) => value.showColumn).map((value) => value.name);
}

export function usePaginator(dataSource: MatTableDataSource<any>): boolean {
  return dataSource.data.length > 25;
}

export function useSharedPageSizeOptions<T extends TableElement>(
  dataSource: MatTableDataSource<T>,
  paginatorConfig?: PaginatorConfig<T>
): Array<number> {
  return getPageSizeOptions(dataSource.data.length, paginatorConfig?.paginatorPageSize);
}

export function getDefaultTableProperties(index: number): TableElement {
  return {
    isChecked: false,
    isNew: false,
    showRow: true,
    dirty: false,
    index,
    isValid: true,
    resetIsValid: false,
    isHighlightedRow: false,
    isRowExpanded: false,
    elementIdentifyingProperty: 'name',
  };
}

export function getDefaultExpandableTableProperties(index: number, tableData: Array<TableElement>): TableElement {
  return {
    ...getDefaultTableProperties(index),
    // If the row is already expanded then keep it expanded. Otherwise, it is closed.
    isRowExpanded: tableData[index] !== undefined ? tableData[index].isRowExpanded : false,
  };
}

export function getDefaultNewRowProperties(): TableElement {
  return {
    isChecked: false,
    isNew: true,
    showRow: true,
    dirty: false,
    isRowExpanded: false,
    index: -1,
    isValid: true,
    resetIsValid: false,
    isHighlightedRow: false,
    elementIdentifyingProperty: 'name',
  };
}

export function createTableElement<T>(data: T, index: number): T & TableElement {
  const dataElement: T & TableElement = {
    ...getDefaultTableProperties(index),
    ...data,
  };
  return dataElement;
}

export function buildTableData<T>(data: Array<T>, tableData: Array<T & TableElement>): void {
  const newTableData: Array<T & TableElement> = [];
  if (!!data) {
    for (let i = 0; i < data.length; i++) {
      newTableData.push(createTableElement(data[i], i));
    }
  }
  updateTableElements(tableData, newTableData);
}

export function updateGenericTable<T, S extends TableElement>(
  list: Array<T>,
  tableData: Array<S>,
  buildFunction: (list: Array<T>, tableData: Array<S>) => void,
  replaceFunction: () => void
): void {
  buildFunction(list, tableData);
  replaceFunction();
}

export function getDefaultNestedDataProperties<T extends OptionalTableElement>(element: T): ExpandedTableData<T> {
  return {
    parentId: element.index,
    nestedTableData: [],
    nestedColumnDefs: new Map(),
    nestedRowObjectName: '',
    hideNestedFilter: false,
    fixedTable: false,
  };
}

export function isAtLeastOneRowSelected<T extends OptionalTableElement>(data: Array<T>): boolean {
  for (const element of data) {
    if (element.isChecked) {
      return true;
    }
  }
  return false;
}

export function openRightClickMenu(event: MouseEvent, buttonId: string, menuTrigger: MatMenuTrigger): void {
  // This will stop the regular right click menu from opening
  event.preventDefault();
  const buttonRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
  const x = buttonRect.left + window.pageXOffset;
  const y = buttonRect.top + window.pageYOffset;
  const button = document.getElementById(buttonId);
  button.style.position = 'fixed';
  button.style.left = `${x + 30}px`;
  button.style.top = `${y + 50}px`;
  menuTrigger.openMenu();
}
