import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { getColumnDefs, getDisplayedColumnNames, openRightClickMenu, usePaginator, useSharedPageSizeOptions } from '../table-layout-utils';
import { Column, handleCollapseColumn, handleExpandColumn } from '../table-layout/column-definitions';
import { ColumnTypes } from '../column-types.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { IconColor } from '../icon-color.enum';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { FilterManager } from '../filter/filter-manager';
import { ParamSpecificFilterManager } from '../param-specific-filter-manager/param-specific-filter-manager';
import { TablePaginatorComponent } from '../table-paginator/table-paginator.component';
import { TableElement } from '../table-layout/table-element';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { FilterMenuOption } from '../table-filter/table-filter.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormLayoutComponent } from '../form-layout/form-layout.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { generateRandomUuid } from '../utils';

@Component({
  selector: 'portal-table-layout-readonly',
  templateUrl: './table-layout-readonly.component.html',
  styleUrls: ['./table-layout-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableLayoutReadonlyComponent<T extends TableElement> implements OnChanges {
  @Input() public tableData: Array<T> = [];
  @Input() public columnDefs: Map<string, Column<T>> = new Map();
  @Input() public paginatorPageSize = 25;
  @Input() public filterMenuOptions: Map<string, FilterMenuOption> = new Map();
  @Input() public filterManager: FilterManager;
  @Input() public paramSpecificFilterManager: ParamSpecificFilterManager;
  // For setting enter key to change input focus.
  @Input() public keyTabManager: KeyTabManager = new KeyTabManager();
  @Input() public hideFilter = true;
  @Input() public useBackendFilter = false;
  @Input() public linkDataSource = false;
  @Input() public isNestedTable = false;
  @Input() public getDisplayedColumnNames = getDisplayedColumnNames;
  @Input() public hideRow: (row: T) => boolean = () => false;
  @Output() public filterSearchDelay = new EventEmitter<any>();
  @Output() public filterBySearchParam = new EventEmitter<any>();
  public dataSource: MatTableDataSource<T> = new MatTableDataSource(this.tableData);
  /**
   * The index of the element that is expanded. Otherwise, null.
   */
  public expandedElementId: number | null = null;
  private selectedColumn: Column<T>;
  // These need to be unique for each table so that the right click menu
  // knows which table to attach to when multiple tables exist on the same screen:
  public rightClickMenuId = `right-click-menu-button-${generateRandomUuid()}`;
  public rightClickMenuIdNested = `${this.rightClickMenuId}-nested`;

  // This is required in order to reference the enums in the html template.
  public columnTypes = ColumnTypes;
  public inputSize = InputSize;
  public iconColor = IconColor;

  public getColumnDefs = getColumnDefs;
  public usePaginator = usePaginator;
  public useSharedPageSizeOptions = useSharedPageSizeOptions;
  public openRightClickMenu = openRightClickMenu;

  @ViewChild('tableSort', { static: true }) public tableSort: MatSort;
  @ViewChild(TablePaginatorComponent, { static: true }) public paginator: TablePaginatorComponent<any>;
  @ViewChild('rightClickMenuTrigger') public rightClickMenuTrigger: MatMenuTrigger;
  @ViewChildren('nestedFormLayout') private nestedFormLayouts: QueryList<FormLayoutComponent<T>>;
  @ViewChildren('nestedTable') private nestedTables: QueryList<TableLayoutReadonlyComponent<T>>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.updateDataSource();
  }

  public updateDataSource(): void {
    if (this.expandedElementId !== undefined && this.expandedElementId !== null) {
      // When the data refreshes we need to ensure the expanded row icon displays correctly:
      const expandedRow = this.tableData[this.expandedElementId];
      expandedRow.isRowExpanded = true;
    }
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.tableSort;
    if (this.linkDataSource) {
      // This links the paginator length to the datasource length.
      this.dataSource.paginator = this.paginator.paginator;
    }
    if (!this.hideFilter) {
      this.filterManager.createNestedFilterPredicate(this.dataSource, this.columnDefs);
      this.filterManager.applyFilter(this.dataSource);
    }
  }

  public filterBySearchParamEventFunc(): void {
    this.filterBySearchParam.emit();
  }

  public filterSearchDelayEventFunc(event: any): void {
    this.filterSearchDelay.emit(event);
  }

  /**
   * When an element is clicked, it will expand that element while closing any existing
   * expanded elements. If the existing expanded element is clicked, all elements are closed.
   * If no elements, then nothing is expanded.
   */
  public toggleRow(element: T): void {
    this.expandedElementId = this.expandedElementId === element.index ? null : element.index;
  }

  public toggleRowExpansionIcon(element: T, column: Column<T>): void {
    if (!!column?.disableField(element)) {
      return;
    }
    this.toggleRow(element);
    for (const item of this.tableData) {
      item.isRowExpanded = item.index === this.expandedElementId;
    }
  }

  public toggleRowOnRowClick(element: T): void {
    const expandColumn = this.columnDefs.get('expandRow');
    if (element.index === this.expandedElementId) {
      return;
    }
    this.toggleRowExpansionIcon(element, expandColumn);
  }

  public isExpandableRow(element: T): boolean {
    const expandColumn = this.columnDefs.get('expandRow');
    return !!this.isNestedTable && !element.isRowExpanded && !expandColumn.disableField(element);
  }

  public isEvenRow(element: T): boolean {
    return element.index % 2 === 0;
  }

  public triggerChangeDetectionFromParentComponent(): void {
    const nestedFormLayoutsArray = !!this.nestedFormLayouts ? this.nestedFormLayouts.toArray() : [];
    for (const nestedFormLayout of nestedFormLayoutsArray) {
      nestedFormLayout.triggerFormResetFromParentComponent();
    }
    const nestedTableComponentsArray = !!this.nestedTables ? this.nestedTables.toArray() : [];
    for (const nestedTableComponent of nestedTableComponentsArray) {
      nestedTableComponent.triggerChangeDetectionFromParentComponent();
    }
    this.changeDetector.detectChanges();
  }

  public onExpandColumn(column: Column<T>): void {
    handleExpandColumn(column, this.columnDefs);
  }

  public onCollapseColumn(): void {
    handleCollapseColumn(this.selectedColumn, this.columnDefs);
  }

  public onHeaderRightClick(event: MouseEvent, column: Column<T>): void {
    this.selectedColumn = column;
    openRightClickMenu(event, this.rightClickMenuId, this.rightClickMenuTrigger);
  }

  public getFirstColumnName(): string {
    const columnNames = getDisplayedColumnNames(this.columnDefs);
    return !!columnNames && columnNames.length !== 0 ? columnNames[0] : '';
  }

  public getLastColumnName(): string {
    const columnNames = getDisplayedColumnNames(this.columnDefs);
    return !!columnNames && columnNames.length !== 0 ? columnNames[columnNames.length - 1] : '';
  }
}
