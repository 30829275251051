import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import {
  Audit,
  AuditsService,
  ListUsersRequestParams,
  UsersService,
  User,
  ListAuditsResponse,
  ListUsersResponse,
  ListAuditsRequestParams,
  Connector,
  ConnectorsService,
} from '@agilicus/angular';
import { Observable, combineLatest } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { updateTableElements } from '@app/shared/components/utils';
import {
  TimeIntervalOption,
  getDefaultTimeIntervalOptions,
  getStartDateMaxSetter,
  getEndDateMinSetter,
} from '@app/shared/components/date-utils';
import { FilterManager, CheckboxOption } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject } from 'rxjs';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectCanReadAudits } from '@app/core/user/permissions/audits.selectors';
import { createCombinedPermissionsSelector, OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanReadUsers } from '@app/core/user/permissions/users.selectors';
import { Column, createReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { downloadDataToCsv } from '../file-utils';
import { Papa } from 'ngx-papaparse';
import { getDefaultTableProperties } from '../table-layout-utils';
import { DiagnosticDateRange } from '../diagnostic-types';
import { PaginatorActions, PaginatorConfig, UpdateTableParams } from '../table-paginator/table-paginator.component';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { FilterType } from '../filter-type.enum';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { capitalizeFirstLetter } from '../utils';
import { Router } from '@angular/router';
import { getConnectors } from '@app/core/api/connectors/connectors-api-utils';
import { TableLayoutComponent } from '../table-layout/table-layout.component';

interface AuditRecord {
  audit: Audit;
  user?: User;
}

interface AuditsState {
  audits: Array<AuditRecord>;
}

export interface AuditData {
  time?: Date;
  type?: string;
  action?: string;
  email?: string;
  api?: string;
  attributes?: string;
  description?: string;
  resource?: string;
}

export interface AuditElement extends AuditData, TableElement {}

export enum AuditFilterLabel {
  CONNECTOR = 'Connector',
}

export interface AuditRoute {
  org_id: string;
  target_resource_id?: string;
  target_resource_name?: string;
  resources_behind_connector_id?: string;
}

@Component({
  selector: 'portal-audit-subsystem',
  templateUrl: './audit-subsystem.component.html',
  styleUrls: ['./audit-subsystem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditSubsystemComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public columnDefs: Map<string, Column<AuditElement>> = new Map();
  public tableData: Array<AuditElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = true;
  public readonlyInputs = true;
  private users$: Observable<ListUsersResponse>;
  private audits$: Observable<ListAuditsResponse>;
  private connectors$: Observable<Connector[]>;
  public timeIntervalOptions: Array<TimeIntervalOption> = getDefaultTimeIntervalOptions();
  public maxDate: Date = new Date();
  public auditForm: UntypedFormGroup;
  public downloadButtonDescription = 'AUDITS';
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  public auditRecords: AuditRecord[];
  public pageDescriptiveText = `Global organisation-wide API audits. Who has modified what, when. Use this screen to understand the changes made to your organisation, when those changes happened, and who made them.`;
  public linkDataSource = false;
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public selectedConnectorId: string = 'none';
  public selectedResource: string = 'none';

  public auditResp: ListAuditsResponse;
  public usersResp: ListUsersResponse;
  public connectorsResp: Connector[];
  private connectorNames: Set<string> = new Set([]);
  private connectorIdToConnectorMap: Map<string, Connector> = new Map();
  private connectorNameToConnectorMap: Map<string, Connector> = new Map();

  public paginatorConfig = new PaginatorConfig<AuditElement>(true, true, 25, 5, new PaginatorActions<AuditElement>(), 'email', {
    previousKey: '',
    nextKey: '',
    previousWindow: [],
  });

  public getStartDateMaxSetter = getStartDateMaxSetter;
  public getEndDateMinSetter = getEndDateMinSetter;

  public filterOptions = {
    showAunthenticationRows: false,
  };

  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'category',
      {
        name: 'category',
        displayName: AuditFilterLabel.CONNECTOR,
        icon: 'label',
        type: FilterMenuOptionType.checkbox,
      },
    ],
  ]);

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<AuditElement>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private auditsService: AuditsService,
    private usersService: UsersService,
    private papa: Papa,
    private renderer: Renderer2,
    private connectorsService: ConnectorsService,
    public router: Router
  ) {}

  private setConnectorMaps(connectors: Array<Connector>): void {
    this.connectorNameToConnectorMap.clear();
    this.connectorIdToConnectorMap.clear();
    for (const connector of connectors) {
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
    }
  }

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.initializeColumnDefs();
    this.permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanReadAudits, selectCanReadUsers)));
    this.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((permissions) => {
      this.orgId = permissions.orgId;
      this.hasPermissions = permissions.hasPermission;
      if (!this.orgId || !this.hasPermissions) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.updateTable();
      this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
        this.updateTable(params.limit);
      });
      this.changeDetector.detectChanges();
    });
  }

  public viewAudits(): void {
    let connector = undefined;
    let resourceId = undefined;
    if (this.selectedConnectorId !== 'none') {
      connector = this.selectedConnectorId;
    }
    if (this.selectedResource !== 'none') {
      resourceId = this.selectedResource;
    }
    this.updateTable(
      this.paginatorConfig.getQueryLimit(),
      connector,
      connector ? undefined : true,
      resourceId,
      resourceId ? undefined : true
    );
  }

  public reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorConfig.actions.reloadFirstPage();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAuditStateFromAuditList(auditRecordsResponse: ListAuditsResponse, usersResponse: ListUsersResponse): AuditsState {
    const records = auditRecordsResponse.audits ? auditRecordsResponse.audits : [];
    const state: AuditsState = {
      audits: records.map((record) => {
        const result = {
          audit: record,
          user: usersResponse.users.find((user) => user.id === record.user_id),
        };
        return result;
      }),
    };
    return state;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        this.getTimeStampColumn(),
        this.getTypeColumn(),
        this.getResourceColumn(),
        this.getActionColumn(),
        this.getUserColumn(),
        this.getApiColumn(),
        this.getAttributesColumn(),
      ],
      this.columnDefs
    );
  }

  private getTimeStampColumn(): Column<AuditElement> {
    const timeColumn = createReadonlyColumn('time');
    timeColumn.displayName = 'Timestamp';
    return timeColumn;
  }

  private getTypeColumn(): Column<AuditElement> {
    const typeColumn = createReadonlyColumn('type');
    typeColumn.inputSize = InputSize.SMALL;
    return typeColumn;
  }

  private getActionColumn(): Column<AuditElement> {
    const actionColumn = createReadonlyColumn('action');
    actionColumn.inputSize = InputSize.SMALL;
    return actionColumn;
  }

  private getUserColumn(): Column<AuditElement> {
    const emailColumn = createReadonlyColumn('email');
    return emailColumn;
  }

  private getApiColumn(): Column<AuditElement> {
    const apiColumn = createReadonlyColumn('api');
    apiColumn.displayName = 'API Name';
    return apiColumn;
  }

  private getAttributesColumn(): Column<AuditElement> {
    const secondaryObjectColumn = createReadonlyColumn('attributes');
    return secondaryObjectColumn;
  }

  private getResourceColumn(): Column<AuditElement> {
    const resourceColumn = createReadonlyColumn('resource');
    return resourceColumn;
  }

  public removeResourceIdParamFromUrl(): void {
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        org_id: this.orgId,
        resources_behind_connector_id: null,
        target_resource_id: null,
        target_resource_name: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public updateTable(
    limitParam = this.paginatorConfig.getQueryLimit(),
    connector?: string,
    applyConnectorFilter?: boolean,
    resourceId?: string,
    applyResourceFilter?: boolean
  ): void {
    // Set the connector data:
    let connectorId = connector;
    if (!connector && !applyConnectorFilter) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      connectorId = urlParams.get('resources_behind_connector_id');
      this.removeResourceIdParamFromUrl();
    }
    this.selectedConnectorId = connectorId ? connectorId : this.selectedConnectorId;

    // Set the resource data:
    let targetResourceId = resourceId;
    let targetResourceName = undefined;
    if (!resourceId && !applyResourceFilter) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      targetResourceId = urlParams.get('target_resource_id');
      targetResourceName = urlParams.get('target_resource_name');
      this.removeResourceIdParamFromUrl();
    }
    this.selectedResource = targetResourceId ? targetResourceId : this.selectedResource;

    const AuditFilterData = this.getAuditFilterData(limitParam, this.selectedConnectorId, targetResourceId);
    this.audits$ = this.auditsService.listAudits(AuditFilterData);
    const userParams: ListUsersRequestParams = {
      org_id: this.orgId,
    };
    this.users$ = this.usersService.listUsers(userParams);
    this.connectors$ = getConnectors(this.connectorsService, this.orgId);
    combineLatest([this.audits$, this.users$, this.connectors$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([auditsResp, usersResp, connectorsResp]) => {
          this.auditResp = auditsResp;
          this.usersResp = usersResp;
          this.connectorsResp = connectorsResp;
          const auditState = this.getAuditStateFromAuditList(auditsResp, usersResp);
          this.auditRecords = auditState.audits;
          if (!auditState.audits) {
            this.resetEmptyTable();
            return;
          }
          this.buildData(auditState.audits);
          this.replaceTableWithCopy();
          this.setConnectorCategoriesForFiltering(connectorsResp);
          this.setConnectorMaps(connectorsResp);
          if (!connector && !applyConnectorFilter && this.selectedConnectorId !== 'none') {
            const connectorOptions = this.filterManager.checkboxOptions.filter(
              (option) => option.label === this.filterMenuOptions.get('category').displayName
            );
            for (const checkboxOption of connectorOptions) {
              const targetConnector = this.connectorIdToConnectorMap.get(this.selectedConnectorId);
              if (checkboxOption.name === targetConnector?.spec?.name && !checkboxOption.isChecked) {
                // Add the checkbox option to the filter bar:
                this.filterManager.onCheckBoxToggle(checkboxOption, this.tableLayoutComp.dataSource);
              }
            }
          }
          if (!resourceId && !applyResourceFilter && this.selectedResource !== 'none') {
            this.filterManager.addInputFilterOption(
              targetResourceName ? targetResourceName : targetResourceId,
              this.tableLayoutComp.dataSource
            );
          }
          this.paginatorConfig.actions.dataFetched({
            data: this.tableData,
            searchDirection: 'forwards',
            limit: limitParam,
            nextKey: '',
            previousKey: '',
          });
          this.changeDetector.detectChanges();
        },
        (error) => {
          this.paginatorConfig.actions.errorHandler(error);
        }
      );
  }

  /**
   * Gets the list of connectors and adds them to the list of checkbox options
   * so a user can filter columns by connectors.
   * @param connectors The response from the connectors api call.
   */
  private setConnectorCategoriesForFiltering(connectors: Array<Connector>): void {
    // Use Set to remove duplicates.
    const newConnectorCategoryNames: Set<string> = new Set(connectors.map((app) => app.spec.name));
    this.updateConnectorCategoryOptions(newConnectorCategoryNames);
  }

  private updateConnectorCategoryOptions(newConnectorCategoryNames: Set<string>): void {
    // Remove connectors that no longer exist
    // and remove the connectors from checkboxOptions.
    this.connectorNames.forEach((connector) => {
      if (!newConnectorCategoryNames.has(connector)) {
        this.connectorNames.delete(connector);
        this.filterManager.removeCheckboxFilterOption(connector, this.filterMenuOptions.get('category').displayName);
      }
    });
    // Add new connectors
    // and add the connectors to checkboxOptions.
    newConnectorCategoryNames.forEach((name) => {
      if (!this.connectorNames.has(name)) {
        this.connectorNames.add(name);
        this.filterManager.addCheckboxFilterOption({
          name,
          displayName: capitalizeFirstLetter(name),
          label: this.filterMenuOptions.get('category').displayName,
          type: FilterType.CHECKBOX,
          isChecked: false,
          doFilter: this.updateConnectorFilter.bind(this),
        });
      }
    });
  }

  public updateConnectorFilter(check: CheckboxOption): void {
    this.connectorNames.forEach((connector) => {
      let checkboxOption = this.filterManager.checkboxOptions.find((i) => i.name === connector);
      if (check.name.toLowerCase() !== connector.toLowerCase() && checkboxOption.isChecked) {
        checkboxOption.isChecked = false;
        const index = this.filterManager.filterBar.indexOf(checkboxOption);
        if (index >= 0) {
          this.filterManager.filterBar.splice(index, 1);
        }
      }
      if (check.name.toLowerCase() === connector.toLowerCase()) {
        if (check.isChecked) {
          this.updateTable(this.paginatorConfig.getQueryLimit(), this.connectorNameToConnectorMap.get(check.name).metadata.id);
          return;
        }
        this.selectedConnectorId = 'none';
        this.updateTable(this.paginatorConfig.getQueryLimit(), undefined, true);
      }
    });
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private buildData(auditRecords: Array<AuditRecord>): void {
    this.tableData = [];
    const data: Array<AuditElement> = [];
    for (let i = 0; i < auditRecords.length; i++) {
      data.push(this.createAuditElement(auditRecords[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createAuditElement(auditRecord: AuditRecord, index: number): AuditElement {
    const value = auditRecord.audit.audit_attributes
      .map((attr) => attr.attribute_type + ':' + (attr.attribute_name ? attr.attribute_name : attr.attribute_id))
      .join(', ');
    const data: AuditElement = {
      time: auditRecord.audit.time,
      type: auditRecord.audit.target_resource_type,
      action: auditRecord.audit.action,
      email: auditRecord.user ? auditRecord.user.email : '',
      api: auditRecord.audit.api_name,
      attributes: value,
      resource: auditRecord.audit.target_resource_name ? auditRecord.audit.target_resource_name : auditRecord.audit.target_id,
      description: auditRecord.audit.description,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private initializeFormGroup(): void {
    const today = new Date();
    this.auditForm = this.formBuilder.group({
      // Set the initial filter to the past 7 days:
      dtFrom: new Date(new Date(today).setDate(today.getDate() - 7)),
      dtTo: today,
    });
  }

  public getAuditFilterData(limitParam: number, connectorId?: string, resourceId?: string): ListAuditsRequestParams {
    const AuditFilterData: ListAuditsRequestParams = {
      org_id: this.orgId,
      limit: limitParam,
    };
    if (!!connectorId && connectorId !== 'none') {
      AuditFilterData.resources_behind_connector_id = connectorId;
    }
    if (!!resourceId && resourceId !== 'none') {
      AuditFilterData.target_id = resourceId;
    }
    const targetDates = this.setStartAndEndDates();
    if (targetDates.startDate !== null) {
      AuditFilterData.dt_from = targetDates.startDate.toISOString();
    }
    if (targetDates.endDate !== null) {
      AuditFilterData.dt_to = targetDates.endDate.toISOString();
    }
    return AuditFilterData;
  }

  public setStartAndEndDates(): DiagnosticDateRange {
    const targetDates: DiagnosticDateRange = {
      startDate: null,
      endDate: null,
    };
    targetDates.startDate = this.auditForm.value.dtFrom;
    targetDates.endDate = this.auditForm.value.dtTo;
    return targetDates;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Reformats the data so the csv display format
   * will match the table display format.
   */
  public reformatAuditData(data: Array<AuditElement>): Array<AuditData> {
    const AuditData: Array<AuditData> = [];
    for (const Audit of data) {
      AuditData.push({
        time: Audit.time,
        type: Audit.type,
        action: Audit.action,
        email: Audit.email,
        api: Audit.api,
        attributes: Audit.attributes,
        resource: Audit.resource,
        description: Audit.description,
      });
    }
    return AuditData;
  }

  public downloadAuditData(): void {
    const reformatedAuditData = this.reformatAuditData(this.tableData);
    downloadDataToCsv(reformatedAuditData, this.papa, this.renderer, 'audit-data');
  }

  public filterBySearchParam(): void {
    this.updateTable();
  }
}
