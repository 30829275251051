import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InboxItem, MessageAction } from '@agilicus/angular';
import { NotificationService } from '@app/core';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';

@Component({
  selector: 'portal-inbox-message-dialog',
  templateUrl: './inbox-message-dialog.component.html',
  styleUrls: ['./inbox-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxMessageDialogComponent {
  public inboxItem: InboxItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InboxItem,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<InboxMessageDialogComponent>,
    private env: DynamicEnvironmentService
  ) {
    this.inboxItem = data;
  }

  public fetchActionUri(action: MessageAction | undefined): void {
    if (action.uri) {
      const url = action.uri;
      const currentHost = window.location.host;
      const targetHost = new URL(url).host;
      const apiDomain = this.env.environment.overrideApiDomain;

      if (currentHost === targetHost) {
        window.location.href = url;
      } else if (url.includes(apiDomain) || url.includes('api.agilicus.com')) {
        this.fetchAction(action.uri)
          .then((res) => {
            this.notificationService.success('Request has been processed');
          })
          .catch((err) => this.notificationService.error('Failed to fetch the requested uri'));
      } else {
        window.open(url, '_blank');
      }
    }
    this.dialogRef.close();
  }

  public openMessageUri(event: MouseEvent): void {
    event.stopPropagation();
    const url = (<HTMLAnchorElement>event.target).href;
    const currentHost = window.location.host;
    const targetHost = new URL(url).host;
    if (currentHost === targetHost) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
    event.preventDefault(); // prevent the default anchor click behavior
    this.dialogRef.close();
  }

  private fetchAction(url): Promise<any> {
    return fetch(url).then((response) => response.json());
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public showCustomActionButtons(inboxItem: InboxItem): boolean {
    return inboxItem?.status?.message?.actions && inboxItem.status.message.actions.length > 0;
  }
}
