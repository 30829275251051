import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { TableElement } from '../table-layout/table-element';
import { AppState } from '@app/core';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { select, Store } from '@ngrx/store';
import {
  ApplicationServicesService,
  Connector,
  ConnectorSpec,
  DesktopRemoteApp,
  DesktopResource,
  DesktopResourceSpec,
  RuntimeStatus,
  VNCPasswordAuthentication,
  LabelledObject,
  Resource,
} from '@agilicus/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from '../button-type.enum';
import { FilterManager } from '../filter/filter-manager';
import {
  Column,
  createInputColumn,
  createSelectColumn,
  createSelectRowColumn,
  SelectColumn,
  setColumnDefs,
  createIconColumn,
  createExpandColumn,
  IconColumn,
  createResourceIconColumn,
  createCheckBoxColumn,
  createActionsColumn,
  ActionMenuOptions,
  ChiplistColumn,
} from '../table-layout/column-definitions';
import { isValidHostnameOrIp4, isValidPort, isValidResourceName, isVncPasswordValid } from '../validation-utils';
import {
  capitalizeFirstLetter,
  createEnumChecker,
  getIconURIFromResource,
  getStatusIcon,
  getStatusIconColor,
  updateTableElements,
} from '../utils';
import { OptionalDesktopResourceElement } from '../optional-types';
import {
  isConnectorBeingRemovedFromResource,
  openRemoveConnectorFromResourceWarningDialog,
  setConnectorColumnAllowedValues,
} from '../connector-utils';
import { getDefaultTableProperties, getDefaultNestedDataProperties, getDefaultNewRowProperties } from '../table-layout-utils';
import { IconColor } from '../icon-color.enum';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { getDefaultNetworkServiceConfig } from '@app/core/models/network-service-config/network-service-config-model-utils';
import { savingIconDesktopResources, initDesktopResources, resetIconStatusDesktopResources } from '@app/core/desktop-state/desktop.actions';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList, selectConnectorRefreshDataValue } from '@app/core/connector-state/connector.selectors';
import {
  selectDesktopResourceEntity,
  selectDesktopResourceIconStatus,
  selectDesktopResourceList,
  selectDesktopResourceRefreshDataValue,
} from '@app/core/desktop-state/desktop.selectors';
import { cloneDeep } from 'lodash-es';
import { deletingDesktopResources, savingDesktopResource } from '@app/core/desktop-state/desktop.actions';
import {
  getDesktopServerConfiguration,
  getRemoteDesktopApplicationCommandPathTooltipText,
} from '@app/core/application-service-state/application-services-utils';
import { ResourceLogoDialogComponent, ResourceLogoDialogData } from '../resource-logo-dialog/resource-logo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getDefaultLogoDialogConfig } from '../dialog-utils';
import { ResourceType } from '../resource-type.enum';
import { InputData } from '../custom-chiplist-input/input-data';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import { FileHelperService } from '@app/core/services/file-helper/file-helper.service';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  PolicyTemplateInstanceWithLabels,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { getTrimmedResourceName } from '../resource-utils';

export interface DesktopResourceElement extends ResourceTableElement {
  name: string;
  address: string;
  port: string;
  connector_name: string;
  prevous_connector_name: string;
  session_type: DesktopResourceSpec.SessionTypeEnum | undefined;
  overallStatus: string;
  allow_non_domain_joined_users: boolean;
  backingObject: DesktopResource;
}

export interface VNCPasswordAuthenticationBindElement extends VNCPasswordAuthentication, TableElement {
  parentId: string | number;
}

export interface DesktopRemoteAppElement extends DesktopRemoteApp, TableElement {
  parentId: string | number;
}

@Component({
  selector: 'portal-desktop-overview',
  templateUrl: './desktop-overview.component.html',
  styleUrls: ['./desktop-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasAppsPermissions: boolean;
  private orgId: string;
  public tableData: Array<DesktopResourceElement> = [];
  public columnDefs: Map<string, Column<DesktopResourceElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'DESKTOP';
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      `ADD ${this.rowObjectName}`,
      ButtonColor.PRIMARY,
      `Add a new ${this.rowObjectName.toLowerCase()}`,
      `Button that adds a new ${this.rowObjectName.toLowerCase()}`,
      () => {
        this.router.navigate([`/${this.rowObjectName.toLowerCase()}-new`], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
  ];
  public warnOnNOperations = 1;
  private connectorIdToConnectorMap: Map<string, Connector> = new Map();
  private connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public pageDescriptiveText = `A desktop is an interactive graphical remote machine, over protocols such as RDP or VNC, accessed via the identity-aware proxy`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/zero-trust-desktop-access/`;
  private localDesktopRefreshDataValue = 0;
  private localConnectorsRefreshDataValue = 0;
  // For policy column:
  private policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels> = [];
  // For policy column:
  private resources: Array<Resource> = [];
  // For policy column:
  private labelledObjects: Array<LabelledObject> = [];
  // For policy column:
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstanceWithLabels> = new Map();
  // For policy column:
  private localPoliciesRefreshDataValue = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog,
    private routerHelperService: RouterHelperService,
    private applicationServicesService: ApplicationServicesService,
    private fileHelperService: FileHelperService,
    private renderer: Renderer2,
    private policyResourceLinkService: PolicyResourceLinkService<DesktopResourceElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initDesktopResources({ force: true, blankSlate: false }));
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    // For policy column:
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const desktopResourceListState$ = this.store.pipe(select(selectDesktopResourceList));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const refreshDesktopDataState$ = this.store.pipe(select(selectDesktopResourceRefreshDataValue));
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    combineLatest([
      hasAppsPermissions$,
      this.policyResourceLinkService.getPolicyAndResourceAndLabelDataWithPermissions$(),
      desktopResourceListState$,
      connectorListState$,
      refreshDesktopDataState$,
      refreshConnectorDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasAppsPermissionsResp,
          getPermissionsAndResourceAndLabelDataResp,
          desktopResourceListStateResp,
          connectorListStateResp,
          refreshDesktopDataStateResp,
          refreshConnectorDataStateResp,
        ]) => {
          this.orgId = hasAppsPermissionsResp.orgId;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          // For policy column:
          this.policyTemplateInstanceList = getPermissionsAndResourceAndLabelDataResp.policyTemplateInstanceList;
          // For policy column:
          this.resources = getPermissionsAndResourceAndLabelDataResp.resourcesList;
          // For policy column:
          this.labelledObjects = getPermissionsAndResourceAndLabelDataResp.labelledObjects;
          if (
            !this.hasAppsPermissions ||
            !connectorListStateResp ||
            !desktopResourceListStateResp ||
            desktopResourceListStateResp.length === 0
          ) {
            this.resetEmptyTable();
            return;
          }
          this.setConnectorMaps(connectorListStateResp);
          // For policy column:
          this.policyResourceLinkService.setPolicyNameAndTypeStringMap(
            this.policyNameAndTypeStringToPolicyMap,
            this.policyTemplateInstanceList
          );
          // For policy column:
          this.policyResourceLinkService.setResourceTablePoliciesColumnAllowedValuesList(this.columnDefs, this.policyTemplateInstanceList);
          if (
            this.tableData.length === 0 ||
            this.localDesktopRefreshDataValue !== refreshDesktopDataStateResp ||
            this.localConnectorsRefreshDataValue !== refreshConnectorDataStateResp ||
            this.localPoliciesRefreshDataValue !== getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue
          ) {
            this.localDesktopRefreshDataValue = refreshDesktopDataStateResp;
            this.localConnectorsRefreshDataValue = refreshConnectorDataStateResp;
            this.localPoliciesRefreshDataValue = getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue;
            if (
              this.localDesktopRefreshDataValue !== 0 &&
              this.localConnectorsRefreshDataValue !== 0 &&
              this.localPoliciesRefreshDataValue !== 0
            ) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.updateTable(desktopResourceListStateResp);
            }
          }
          setConnectorColumnAllowedValues(
            this.columnDefs.get('connector_name'),
            connectorListStateResp,
            this.orgId,
            ConnectorSpec.ConnectorTypeEnum.agent,
            [ConnectorSpec.ConnectorTypeEnum.agent]
          );

          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setConnectorMaps(connectors: Array<Connector>): void {
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  /**
   * Parent table column
   */
  private getTypeColumn(): IconColumn<DesktopResourceElement> {
    const typeColumn = createIconColumn('type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    typeColumn.getDisplayValue = (element: OptionalDesktopResourceElement) => {
      if (element.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.rdp) {
        return 'desktop_windows';
      }
      if (element.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.vnc) {
        return 'screen_share';
      }
      return '';
    };
    typeColumn.getTooltip = (element: OptionalDesktopResourceElement) => {
      if (element.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.rdp) {
        return 'RDP Desktop';
      }
      if (element.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.vnc) {
        return 'VNC Desktop';
      }
      return '';
    };
    return typeColumn;
  }

  /**
   * Parent table column
   */
  private getIconColumn(): IconColumn<DesktopResourceElement> {
    return createResourceIconColumn<InputData, DesktopResourceElement>(
      this.getIconURIFromElement.bind(this),
      this.openIconDialog.bind(this)
    );
  }

  private getIconURIFromElement(element: DesktopResourceElement): string {
    return getIconURIFromResource(element.backingObject);
  }

  public openIconDialog(element: DesktopResourceElement): void {
    const dialogData: ResourceLogoDialogData<DesktopResource> = {
      resourceType: ResourceType.desktop,
      saveAction: savingIconDesktopResources,
      resetStatusAction: resetIconStatusDesktopResources,
      getResourceStateSelector: selectDesktopResourceEntity({ id: element.backingObject.metadata.id }),
      getIconStatusStateSelector: selectDesktopResourceIconStatus,
    };
    const dialogRef = this.dialog.open(
      ResourceLogoDialogComponent,
      getDefaultLogoDialogConfig({
        data: dialogData,
      })
    );
  }

  /**
   * Parent table column
   */
  private getNameColumn(): Column<DesktopResourceElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.isEditable = true;
    nameColumn.isValidEntry = (name: string): boolean => {
      return isValidResourceName(name);
    };
    nameColumn.getFormattedValue = (name: string, element: DesktopResourceElement): string => {
      return getTrimmedResourceName(name);
    };
    return nameColumn;
  }

  /**
   * Parent table column
   */
  private getAddressColumn(): Column<DesktopResourceElement> {
    const addressColumn = createInputColumn('address');
    addressColumn.isEditable = true;
    addressColumn.isValidEntry = (address: string): boolean => {
      return isValidHostnameOrIp4(address);
    };
    return addressColumn;
  }

  /**
   * Parent table column
   */
  private getPortColumn(): Column<DesktopResourceElement> {
    const portColumn = createInputColumn('port');
    portColumn.isEditable = true;
    portColumn.requiredField = () => true;
    portColumn.isValidEntry = (port: string): boolean => {
      return isValidPort(port);
    };
    return portColumn;
  }

  /**
   * Parent table column
   */
  private getConnectorNameColumn(): SelectColumn<DesktopResourceElement> {
    const connectorNameColumn = createSelectColumn('connector_name');
    connectorNameColumn.displayName = 'Connector';
    connectorNameColumn.isEditable = true;
    return connectorNameColumn;
  }

  /**
   * Parent table column
   */
  private getSessionTypeColumn(): SelectColumn<DesktopResourceElement> {
    const sessionTypeColumn = createSelectColumn('session_type');
    sessionTypeColumn.allowedValues = ['user', 'admin'];
    sessionTypeColumn.displayName = 'Session Type';
    sessionTypeColumn.isEditable = true;
    sessionTypeColumn.getDisplayValue = (element: OptionalDesktopResourceElement): any => {
      if (!element.session_type || element.session_type === DesktopResourceSpec.SessionTypeEnum.user) {
        return 'user';
      }
      return 'admin';
    };
    return sessionTypeColumn;
  }

  /**
   * Parent table column
   */
  private getOverallStatusColumn(): Column<DesktopResourceElement> {
    const overallStatusColumn = createInputColumn('overallStatus');
    overallStatusColumn.displayName = 'Status';
    overallStatusColumn.isReadOnly = () => true;
    overallStatusColumn.getDisplayValue = (element: OptionalDesktopResourceElement): any => {
      if (!element.overallStatus) {
        return '';
      }
      return capitalizeFirstLetter(element.overallStatus);
    };
    overallStatusColumn.getIconPrefix = this.getStatusIconFromElement.bind(this);
    overallStatusColumn.getIconColor = this.getStatusIconColorFromElement.bind(this);
    return overallStatusColumn;
  }

  /**
   * Parent Table Column
   */
  private getPoliciesColumn(): ChiplistColumn<DesktopResourceElement> {
    return this.policyResourceLinkService.getResourceTablePoliciesColumn(this.policyNameAndTypeStringToPolicyMap);
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<DesktopResourceElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<DesktopResourceElement>> = [
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: OptionalDesktopResourceElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.backingObject.spec.org_id,
            target_resource_id: element.backingObject.metadata.id,
            target_resource_name: element.name,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
      },
      {
        displayName: 'Download Configuration File',
        icon: 'cloud_download',
        tooltip:
          'Remote applications require setup on the server hosting them. Click to download a pre-built registry file to run on the remote Windows server.',
        onClick: (element: OptionalDesktopResourceElement) => {
          getDesktopServerConfiguration(this.applicationServicesService, this.orgId, element.backingObject.metadata.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp) => {
              this.fileHelperService.downloadDesktopConfigToBrowser(resp, element.name, this.renderer);
            });
        },
        isDisabled: (element: OptionalDesktopResourceElement) => {
          return (
            element.backingObject?.spec?.desktop_type !== DesktopResourceSpec.DesktopTypeEnum.rdp ||
            !element.backingObject?.spec?.remote_app
          );
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private setNestedDesktopRemoteAppElementData(element: DesktopResourceElement): void {
    element.expandedData = {
      ...getDefaultNestedDataProperties(element),
      hideNestedFilter: true,
      fixedTable: true,
      makeEmptyNestedTableElement: (): DesktopRemoteAppElement => {
        return {
          command_path: '',
          command_arguments: '',
          working_directory: '',
          parentId: element.index,
          ...getDefaultNewRowProperties(),
        };
      },
    };
    this.initializeRDPNestedColumnDefs(element.expandedData.nestedColumnDefs);
    const backingElement = element.backingObject;
    let command_path = '';
    let command_arguments = '';
    let working_directory = '';
    if (backingElement.spec.remote_app) {
      command_path = backingElement.spec.remote_app.command_path;
      command_arguments = backingElement.spec.remote_app.command_arguments;
      working_directory = backingElement.spec.remote_app.working_directory;
    }
    const elementData = {
      command_path: command_path,
      command_arguments: command_arguments,
      working_directory: working_directory,
      parentId: element.index,
      ...getDefaultTableProperties(0),
    };
    element.expandedData.nestedTableData.push(elementData);
  }

  private setNestedVNCPasswordAuthenticationBindElementData(element: DesktopResourceElement): void {
    element.expandedData = {
      ...getDefaultNestedDataProperties(element),
      hideNestedFilter: true,
      fixedTable: true,
      makeEmptyNestedTableElement: (): VNCPasswordAuthenticationBindElement => {
        return {
          read_only_username: '',
          read_only_password: '',
          read_write_username: '',
          read_write_password: null,
          parentId: element.index,
          ...getDefaultNewRowProperties(),
        };
      },
    };
    this.initializeVNCNestedColumnDefs(element.expandedData.nestedColumnDefs);
    const backingElement = element.backingObject;
    let read_only_username = '';
    let read_only_password = '';
    let read_write_username = '';
    let read_write_password = '';
    if (backingElement.spec.connection_info) {
      read_only_username = backingElement.spec.connection_info.vnc_connection_info.password_authentication_info.read_only_username;
      read_only_password = backingElement.spec.connection_info.vnc_connection_info.password_authentication_info.read_only_password;
      read_write_username = backingElement.spec.connection_info.vnc_connection_info.password_authentication_info.read_write_username;
      read_write_password = backingElement.spec.connection_info.vnc_connection_info.password_authentication_info.read_write_password;
    }
    const elementData = {
      read_only_username: read_only_username,
      read_only_password: read_only_password,
      read_write_username: read_write_username,
      read_write_password: read_write_password,
      parentId: element.index,
      ...getDefaultTableProperties(0),
    };
    element.expandedData.nestedTableData.push(elementData);
  }

  private setNestedTableData(data: Array<DesktopResourceElement>): void {
    for (const element of data) {
      if (element.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.rdp) {
        this.setNestedDesktopRemoteAppElementData(element);
      } else if (element.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.vnc) {
        this.setNestedVNCPasswordAuthenticationBindElementData(element);
      }
    }
  }

  /**
   * VNC nested column
   */
  private getReadOnlyUsernameColumn(): SelectColumn<VNCPasswordAuthenticationBindElement> {
    const column = createInputColumn('read_only_username');
    column.displayName = 'Read Only Username';
    column.isEditable = true;
    return column;
  }

  /**
   * VNC nested column
   */
  private getReadOnlyPasswordColumn(): SelectColumn<VNCPasswordAuthenticationBindElement> {
    const readOnlyPasswordColumn = createInputColumn('read_only_password');
    readOnlyPasswordColumn.displayName = 'Read Only Password';
    readOnlyPasswordColumn.isEditable = true;
    readOnlyPasswordColumn.inputType = 'password';
    readOnlyPasswordColumn.isValidEntry = (password: string): boolean => {
      return isVncPasswordValid(password);
    };
    return readOnlyPasswordColumn;
  }

  /**
   * VNC nested column
   */
  private getReadWriteUsernameColumn(): SelectColumn<VNCPasswordAuthenticationBindElement> {
    const column = createInputColumn('read_write_username');
    column.displayName = 'Read Write Username';
    column.isEditable = true;
    return column;
  }

  /**
   * VNC nested column
   */
  private getReadWritePasswordColumn(): SelectColumn<VNCPasswordAuthenticationBindElement> {
    const readWritePasswordColumn = createInputColumn('read_write_password');
    readWritePasswordColumn.displayName = 'Read Write Password';
    readWritePasswordColumn.isEditable = true;
    readWritePasswordColumn.inputType = 'password';
    readWritePasswordColumn.isValidEntry = (password: string): boolean => {
      return isVncPasswordValid(password);
    };
    return readWritePasswordColumn;
  }

  private initializeVNCNestedColumnDefs(nestedColumnDefs: Map<string, Column<VNCPasswordAuthenticationBindElement>>): void {
    setColumnDefs(
      [
        this.getReadWriteUsernameColumn(),
        this.getReadWritePasswordColumn(),
        this.getReadOnlyUsernameColumn(),
        this.getReadOnlyPasswordColumn(),
      ],
      nestedColumnDefs
    );
  }

  /**
   * RDP nested column
   */
  private getCommandPathColumn(): Column<DesktopRemoteAppElement> {
    const commandPathColumn = createInputColumn('command_path');
    commandPathColumn.isEditable = true;
    commandPathColumn.getHeaderTooltip = () => {
      return getRemoteDesktopApplicationCommandPathTooltipText();
    };
    return commandPathColumn;
  }

  /**
   * RDP nested column
   */
  private getCommandArgumentsColumn(): Column<DesktopRemoteAppElement> {
    const commandArgumentshColumn = createInputColumn('command_arguments');
    commandArgumentshColumn.isEditable = true;
    return commandArgumentshColumn;
  }

  /**
   * RDP nested column
   */
  private getWorkingDirectoryColumn(): Column<DesktopRemoteAppElement> {
    const workingDirectoryColumn = createInputColumn('working_directory');
    workingDirectoryColumn.isEditable = true;
    workingDirectoryColumn.getHeaderTooltip = () => {
      return `The directory to start the application in`;
    };
    return workingDirectoryColumn;
  }

  /**
   * RDP Nested Form Column
   */
  private getAllowNonDomainJoinedUsersColumn(): Column<DesktopResourceElement> {
    const allowNonDomainJoinedUsersColumn = createCheckBoxColumn('allow_non_domain_joined_users');
    allowNonDomainJoinedUsersColumn.displayName = 'I have clients who are not joined to my domain that use this desktop';
    allowNonDomainJoinedUsersColumn.isEditable = true;
    allowNonDomainJoinedUsersColumn.getHeaderTooltip = () => {
      return `Whether to allow non-domian-joined users`;
    };
    return allowNonDomainJoinedUsersColumn;
  }

  private initializeRDPNestedColumnDefs(nestedColumnDefs: Map<string, Column<DesktopRemoteAppElement>>): void {
    setColumnDefs([this.getCommandPathColumn(), this.getCommandArgumentsColumn(), this.getWorkingDirectoryColumn()], nestedColumnDefs);
  }

  private getParentElementFromParentIdInTable(
    tableData: Array<DesktopResourceElement>,
    nestedElement: VNCPasswordAuthenticationBindElement | DesktopRemoteAppElement
  ): DesktopResourceElement | undefined {
    return tableData[nestedElement.parentId];
  }

  private getStatusIconFromElement(element: DesktopResourceElement): string {
    const isRuntimeStatusEnum = createEnumChecker(RuntimeStatus.OverallStatusEnum);
    if (isRuntimeStatusEnum(element.overallStatus)) {
      return getStatusIcon(element.overallStatus);
    }
    return '';
  }

  private getStatusIconColorFromElement(element: DesktopResourceElement): IconColor {
    const isRuntimeStatusEnum = createEnumChecker(RuntimeStatus.OverallStatusEnum);
    if (isRuntimeStatusEnum(element.overallStatus)) {
      return getStatusIconColor(element.overallStatus);
    }
    return IconColor.none;
  }

  private initializeColumnDefs(): void {
    if (this.shouldGateAccess()) {
      // Do not include policies column:
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getTypeColumn(),
          this.getIconColumn(),
          this.getNameColumn(),
          this.getAddressColumn(),
          this.getPortColumn(),
          this.getConnectorNameColumn(),
          this.getSessionTypeColumn(),
          this.getOverallStatusColumn(),
          this.getActionsColumn(),
          createExpandColumn<DesktopResourceElement>(),
        ],
        this.columnDefs
      );
    } else {
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getTypeColumn(),
          this.getIconColumn(),
          this.getNameColumn(),
          this.getAddressColumn(),
          this.getPortColumn(),
          this.getConnectorNameColumn(),
          this.getSessionTypeColumn(),
          this.getOverallStatusColumn(),
          this.getPoliciesColumn(),
          this.getActionsColumn(),
          createExpandColumn<DesktopResourceElement>(),
        ],
        this.columnDefs
      );
    }
  }

  private updateTable(desktopResources: Array<DesktopResource>): void {
    this.buildData(desktopResources);
    this.replaceTableWithCopy();
  }

  private buildData(desktopResources: Array<DesktopResource>): void {
    const data: Array<DesktopResourceElement> = [];
    for (let i = 0; i < desktopResources.length; i++) {
      const desktopResource = desktopResources[i];
      data.push(this.createTableElement(desktopResource, i));
    }
    this.setNestedTableData(data);
    updateTableElements(this.tableData, data);
  }

  private createTableElement(item: DesktopResource, index: number): DesktopResourceElement {
    const connectorName = this.connectorIdToConnectorMap.get(item.spec.connector_id)?.spec.name;
    const data: DesktopResourceElement = {
      name: item.spec.name,
      address: item.spec.address,
      port: '',
      connector_name: connectorName ? connectorName : '',
      prevous_connector_name: connectorName ? connectorName : '',
      session_type: item.spec.session_type || DesktopResourceSpec.SessionTypeEnum.user,
      allow_non_domain_joined_users: !!item.spec.allow_non_domain_joined_users,
      backingObject: cloneDeep(item),
      overallStatus: item.status?.stats?.overall_status,
      nestedFormColumnDefs: new Map(),
      hideNestedForm: (element: DesktopResourceElement) =>
        element.backingObject.spec.desktop_type !== DesktopResourceSpec.DesktopTypeEnum.rdp,
      ...getDefaultTableProperties(index),
      ...this.policyResourceLinkService.getResourceElementWithPolicies(
        item,
        this.resources,
        this.labelledObjects,
        this.policyTemplateInstanceList
      ),
    };
    if (!!item.spec.config?.ports && item.spec.config.ports.length !== 0) {
      data.port = item.spec.config.ports[0].port;
    }
    setColumnDefs([this.getAllowNonDomainJoinedUsersColumn()], data.nestedFormColumnDefs);
    return data;
  }

  public deleteSelected(itemElementsToDelete: Array<DesktopResourceElement>): void {
    const itemsToDelete: Array<DesktopResource> = this.getItemsFromTableElements(itemElementsToDelete);
    // Need to make a copy of the desktopResourcesToDelete or it will be
    // converted to readonly.
    this.store.dispatch(deletingDesktopResources({ objs: cloneDeep(itemsToDelete), trigger_update_side_effects: false, notifyUser: true }));
  }

  public showNoPermissionsText(): boolean {
    return this.hasAppsPermissions !== undefined && !this.hasAppsPermissions;
  }

  public getItemsFromTableElements(tableElements: Array<DesktopResourceElement>): Array<DesktopResource> {
    return tableElements.map((item) => item.backingObject);
  }

  private getItemFromTableElement(tableElement: DesktopResourceElement): DesktopResource {
    const result: DesktopResource = tableElement.backingObject;
    if (!result.spec.config) {
      result.spec.config = getDefaultNetworkServiceConfig(result.spec.desktop_type);
    }
    if (!result.spec.config.ports) {
      result.spec.config.ports = getDefaultNetworkServiceConfig(result.spec.desktop_type).ports;
    }
    result.spec.name = tableElement.name;
    result.spec.address = tableElement.address;
    result.spec.config.ports[0].port = tableElement.port;
    result.spec.session_type = tableElement.session_type;
    result.spec.allow_non_domain_joined_users = tableElement.allow_non_domain_joined_users;
    return result;
  }

  private updateParentElementWithDesktopResourceData(updatedElement: DesktopRemoteAppElement, parentElement: DesktopResourceElement): void {
    if (!updatedElement.command_path) {
      // If command path is not set then the remote app is removed, since the command path is a required property.
      delete parentElement.backingObject.spec.remote_app;
      updatedElement.command_path = '';
      updatedElement.command_arguments = '';
      updatedElement.working_directory = '';
      parentElement.expandedData.nestedTableData[updatedElement.index] = updatedElement;
      return;
    }
    if (!parentElement.backingObject.spec.remote_app) {
      parentElement.backingObject.spec.remote_app = {
        command_path: '',
      };
    }
    parentElement.backingObject.spec.remote_app.command_path = updatedElement.command_path;
    parentElement.backingObject.spec.remote_app.command_arguments = updatedElement.command_arguments;
    parentElement.backingObject.spec.remote_app.working_directory = updatedElement.working_directory;
  }

  private updateParentElementWithVNCPasswordAuthenticationeData(
    updatedElement: VNCPasswordAuthenticationBindElement,
    parentElement: DesktopResourceElement
  ): void {
    if (!!parentElement.backingObject.spec.connection_info) {
      parentElement.backingObject.spec.connection_info.vnc_connection_info.password_authentication_info.read_only_username =
        updatedElement.read_only_username;
      parentElement.backingObject.spec.connection_info.vnc_connection_info.password_authentication_info.read_only_password =
        updatedElement.read_only_password;
      parentElement.backingObject.spec.connection_info.vnc_connection_info.password_authentication_info.read_write_username =
        updatedElement.read_write_username;
      parentElement.backingObject.spec.connection_info.vnc_connection_info.password_authentication_info.read_write_password =
        updatedElement.read_write_password;
    } else {
      parentElement.backingObject.spec.connection_info = {
        vnc_connection_info: {
          password_authentication_info: {
            read_only_username: updatedElement.read_only_username,
            read_only_password: updatedElement.read_only_password,
            read_write_username: updatedElement.read_write_username,
            read_write_password: updatedElement.read_write_password,
          },
        },
      };
    }
  }

  private updateNestedElement(updatedElementAsNestedElement: VNCPasswordAuthenticationBindElement | DesktopRemoteAppElement): void {
    // We need to make a copy of the table data and then re-assign the tableData value
    // in order to clear the remote app columns in the UI when the command path is removed by a user.
    const copyOfTableData = cloneDeep(this.tableData);
    const parentElement = this.getParentElementFromParentIdInTable(copyOfTableData, updatedElementAsNestedElement);
    if (parentElement.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.rdp) {
      this.updateParentElementWithDesktopResourceData(updatedElementAsNestedElement as DesktopRemoteAppElement, parentElement);
    } else if (parentElement.backingObject.spec.desktop_type === DesktopResourceSpec.DesktopTypeEnum.vnc) {
      this.updateParentElementWithVNCPasswordAuthenticationeData(
        updatedElementAsNestedElement as VNCPasswordAuthenticationBindElement,
        parentElement
      );
    }
    this.tableData = copyOfTableData;
    const updatedParentElement = this.getParentElementFromParentIdInTable(this.tableData, updatedElementAsNestedElement);
    this.saveItem(updatedParentElement);
  }

  /**
   * Receives a DesktopResourceElement or nested element from the table then updates and saves
   * the desktop resource.
   */
  public updateEvent(updatedElement: DesktopResourceElement | VNCPasswordAuthenticationBindElement | DesktopRemoteAppElement): void {
    const updatedElementAsNestedElement = updatedElement as VNCPasswordAuthenticationBindElement | DesktopRemoteAppElement;
    if (updatedElementAsNestedElement.parentId !== undefined && updatedElementAsNestedElement.parentId !== null) {
      this.updateNestedElement(updatedElementAsNestedElement);
      return;
    }
    const updatedElementAsDesktopResourceElement = updatedElement as DesktopResourceElement;
    if (isConnectorBeingRemovedFromResource(updatedElementAsDesktopResourceElement)) {
      openRemoveConnectorFromResourceWarningDialog(updatedElementAsDesktopResourceElement, this.dialog, this.saveItem.bind(this));
      return;
    }
    this.saveItem(updatedElementAsDesktopResourceElement);
  }

  private saveItem(updatedTableElement: DesktopResourceElement): void {
    const updatedItem = this.getItemFromTableElement(updatedTableElement);
    this.policyResourceLinkService
      .submitPoliciesResult$(updatedTableElement, this.orgId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(updatedTableElement, resourceAndLabelsAndPolicyResponse);
        // Need to make a copy of the object or it will be converted to readonly.
        this.store.dispatch(savingDesktopResource({ obj: cloneDeep(updatedItem), trigger_update_side_effects: false, notifyUser: true }));
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<DesktopResourceElement>; element: DesktopResourceElement }): void {
    if (params.column.name === 'session_type') {
      if (params.value === 'admin') {
        params.element.session_type = DesktopResourceSpec.SessionTypeEnum.admin;
      } else {
        params.element.session_type = DesktopResourceSpec.SessionTypeEnum.user;
      }
    } else if (params.column.name === 'connector_name') {
      const elementCopy = cloneDeep(params.element);
      params.element.prevous_connector_name = elementCopy.connector_name;
      params.element.connector_name = params.value;
      const targetConnector = this.connectorNameToConnectorMap.get(params.value);
      if (!!targetConnector) {
        params.element.backingObject.spec.connector_id = targetConnector.metadata.id;
      } else {
        params.element.backingObject.spec.connector_id = '';
      }
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
