import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { ActionIssuerClientsInit, ActionIssuerClientsDeletingIssuerClient } from '@app/core/issuer-clients/issuer-clients.actions';
import { selectIssuerId } from '@app/core/user/user.selectors';
import { selectApiApplicationsList, selectApiApplicationsRefreshData } from '@app/core/api-applications/api-applications.selectors';
import { selectIssuerClients, selectIssuerClientsRefreshData } from '@app/core/issuer-clients/issuer-clients.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { Subject, Observable, combineLatest, of } from 'rxjs';
import {
  Column,
  createInputColumn,
  createChipListColumn,
  createSelectColumn,
  SelectColumn,
  createFileColumn,
  InputColumn,
  setColumnDefs,
  ChiplistColumn,
  FileColumn,
} from '../table-layout/column-definitions';
import {
  Application,
  Issuer,
  IssuerClient,
  IssuersService,
  ListUpstreamAliasesRequestParams,
  Organisation,
  UpstreamAlias,
} from '@agilicus/angular';
import { TableElement } from '../table-layout/table-element';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OptionalApplication, OptionalIssuerClientElement } from '../optional-types';
import { FilterManager, FilterOption } from '../filter/filter-manager';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { updateTableElements, generateRandomUuid, useValueIfNotInMap, pluralizeString, replaceCharacterWithSpace } from '../utils';
import { createBlankApplication } from '@app/core/api-applications/api-applications.models';
import { OrgQualifiedPermission, createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { OrganisationsState } from '@app/core/organisations/organisations.models';
import { selectOrganisations } from '@app/core/organisations/organisations.selectors';
import { ButtonType } from '../button-type.enum';
import { MatTableDataSource } from '@angular/material/table';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { MatDialog } from '@angular/material/dialog';
import {
  ApplicationAuthClientsDialogComponent,
  ApplicationDefaultOption,
  AuthClientDialogData,
} from './application-auth-clients-dialog.component';
import { HttpClient } from '@angular/common/http';
import { getDefaultDialogConfig } from '../dialog-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { IssuerClientsState } from '@app/core/issuer-clients/issuer-clients.models';
import { FilterMenuOption } from '../table-filter/table-filter.component';
import { initIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer } from '@app/core/issuer-state/issuer.selectors';

export interface IssuerClientElement extends IssuerClient, TableElement {}

@Component({
  selector: 'portal-application-auth-clients',
  templateUrl: './application-auth-clients.component.html',
  styleUrls: ['./application-auth-clients.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationAuthClientsComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<IssuerClientElement>> = new Map();
  private orgId: string;
  private issuerId$: Observable<string>;
  private issuerId: string;
  private issuer: Issuer;
  private applications$: Observable<Array<Application>>;
  private applications: Array<Application>;
  private issuerClientsState$: Observable<IssuerClientsState>;
  private issuerClientsCopy: Array<IssuerClient>;
  public tableData: Array<IssuerClientElement> = [];
  public rowObjectName = 'CLIENT';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public filterManager: FilterManager = new FilterManager();
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  private orgsState$: Observable<OrganisationsState>;
  private allOrganisations: Array<Organisation>;
  private orgIdToOrgNameMap: Map<string, string> = new Map();
  private orgNameToOrgIdMap: Map<string, string> = new Map();
  public buttonsToShow: Array<ButtonType> = [ButtonType.ADD];
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map();
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(this.tableData);
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public IdPMetadataUri = '';
  private readonly metadataPathname = 'saml/metadata.xml';
  private upstreamAliases: Array<UpstreamAlias>;
  public pageDescriptiveText = `Authentication clients (oauth2 or openid connect client id) are used per unique resource in the system for diagnostic, tracing, and audit purposes. 
  Usually these are created automatically, but create them manually for external applications.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/authentication-clients/`;
  private localIssuerRefreshDataValue = 0;
  private localApplicationRefreshDataValue = 0;

  public replaceCharacterWithSpace = replaceCharacterWithSpace;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    public renderer: Renderer2,
    public http: HttpClient,
    private issuersService: IssuersService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new ActionIssuerClientsInit(true));
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.issuerId$ = this.store.pipe(select(selectIssuerId));
    const currentIssuerState$ = this.store.pipe(select(selectCurrentIssuer));
    this.applications$ = this.store.pipe(select(selectApiApplicationsList));
    this.issuerClientsState$ = this.store.pipe(select(selectIssuerClients));
    this.permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanAdminApps, selectCanAdminIssuers)));
    this.orgsState$ = this.store.pipe(select(selectOrganisations));
    const refreshIssuerClientsDataState$ = this.store.pipe(select(selectIssuerClientsRefreshData));
    const refreshApplicationDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    combineLatest([
      this.issuerId$,
      currentIssuerState$,
      this.applications$,
      this.issuerClientsState$,
      this.permissions$,
      this.orgsState$,
      refreshIssuerClientsDataState$,
      refreshApplicationDataState$,
    ])
      .pipe(
        concatMap(
          ([
            issuerIdResp,
            currentIssuerStateResp,
            appsResp,
            issuerClientsStateResp,
            permissions,
            orgsStateResp,
            refreshIssuerClientsDataStateResp,
            refreshApplicationDataStateResp,
          ]: [string, Issuer, Array<Application>, IssuerClientsState, OrgQualifiedPermission, OrganisationsState, number, number]) => {
            let upstreamAliases$: Observable<Array<UpstreamAlias>> = of(undefined);
            if (!!currentIssuerStateResp) {
              upstreamAliases$ = this.getUpstreamAliases(currentIssuerStateResp);
            }
            return combineLatest([
              of(issuerIdResp),
              of(currentIssuerStateResp),
              of(appsResp),
              of(issuerClientsStateResp),
              of(permissions),
              of(orgsStateResp),
              upstreamAliases$,
              of(refreshIssuerClientsDataStateResp),
              of(refreshApplicationDataStateResp),
            ]);
          }
        )
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          issuerIdResp,
          currentIssuerStateResp,
          appsResp,
          issuerClientsStateResp,
          permissions,
          orgsStateResp,
          upstreamAliasesResp,
          refreshIssuerClientsDataStateResp,
          refreshApplicationDataStateResp,
        ]: [
          string,
          Issuer,
          Array<Application>,
          IssuerClientsState,
          OrgQualifiedPermission,
          OrganisationsState,
          Array<UpstreamAlias>,
          number,
          number
        ]) => {
          this.hasPermissions = permissions.hasPermission;
          if (!this.hasPermissions) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.resetEmptyTable();
            return;
          }
          this.orgId = permissions.orgId;
          this.issuerId = issuerIdResp;
          this.issuer = currentIssuerStateResp;
          this.upstreamAliases = upstreamAliasesResp;
          this.doWhenIssuerStateDataIsLoaded(currentIssuerStateResp);
          this.doWhenAppsDataIsLoaded(appsResp);
          this.doWhenIssuerClientDataIsLoaded(issuerClientsStateResp);
          this.doWhenOrgsDataIsLoaded(orgsStateResp);
          if (
            appsResp === undefined ||
            issuerClientsStateResp === undefined ||
            orgsStateResp === undefined ||
            orgsStateResp.all_organisations === undefined ||
            orgsStateResp.org_id_to_org_name_map === undefined ||
            orgsStateResp.org_name_to_org_id_map === undefined
          ) {
            this.resetEmptyTable();
            return;
          }
          if (
            this.tableData.length === 0 ||
            this.localIssuerRefreshDataValue !== refreshIssuerClientsDataStateResp ||
            this.localApplicationRefreshDataValue !== refreshApplicationDataStateResp
          ) {
            this.localIssuerRefreshDataValue = refreshIssuerClientsDataStateResp;
            this.localApplicationRefreshDataValue = refreshApplicationDataStateResp;
            if (this.localIssuerRefreshDataValue !== 0 && this.localApplicationRefreshDataValue !== 0) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.doWhenAllDataIsLoaded();
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private doWhenIssuerStateDataIsLoaded(currentIssuerStateResp: Issuer): void {
    if (!!currentIssuerStateResp?.issuer) {
      const base = new URL(currentIssuerStateResp.issuer);
      base.pathname = this.metadataPathname;
      this.IdPMetadataUri = base.href;
    }
  }

  private doWhenAppsDataIsLoaded(appsResp: Array<Application>): void {
    if (appsResp === undefined) {
      return;
    }
    this.applications = appsResp.filter((app: Application) => app.maintained);
    // Adding a blank application will allow the user to select 'All' as an option.
    this.columnDefs.get('application').allowedValues = [createBlankApplication(), ...this.applications];
  }

  private doWhenIssuerClientDataIsLoaded(issuerClientsStateResp: IssuerClientsState): void {
    if (issuerClientsStateResp === undefined) {
      return;
    }
    this.issuerClientsCopy = cloneDeep(issuerClientsStateResp.current_issuer_client_list);
  }

  private doWhenOrgsDataIsLoaded(orgsStateResp: OrganisationsState): void {
    if (!orgsStateResp || !orgsStateResp.all_organisations) {
      return;
    }
    this.allOrganisations = orgsStateResp.all_organisations;
    this.orgIdToOrgNameMap = orgsStateResp.org_id_to_org_name_map;
    this.orgNameToOrgIdMap = orgsStateResp.org_name_to_org_id_map;
    const orgScopeAllowedValues: Array<IssuerClient.OrganisationScopeEnum | string> = [
      IssuerClient.OrganisationScopeEnum.here_only,
      IssuerClient.OrganisationScopeEnum.here_and_down,
      IssuerClient.OrganisationScopeEnum.any,
    ];
    this.allOrganisations.forEach((org: Organisation) => {
      orgScopeAllowedValues.push(org.organisation);
    });
    this.columnDefs.get('organisation_scope').allowedValues = orgScopeAllowedValues;
  }

  private doWhenAllDataIsLoaded(): void {
    if (this.applications === undefined || this.issuerClientsCopy === undefined) {
      this.resetEmptyTable();
      return;
    }
    if (this.hasPermissions) {
      this.updateTable();
    }
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<IssuerClientElement> = [];
    for (let i = 0; i < this.issuerClientsCopy.length; i++) {
      data.push(this.createIssuerClientElement(this.issuerClientsCopy[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createIssuerClientElement(issuerClient: IssuerClient, index: number): IssuerClientElement {
    const data: IssuerClientElement = {
      name: '',
      ...getDefaultTableProperties(index),
      ...issuerClient,
    };
    return data;
  }

  private getClientIdColumn(): InputColumn<IssuerClientElement> {
    const clientIdColumn = createInputColumn('name');
    clientIdColumn.displayName = 'Client Id';
    clientIdColumn.requiredField = () => true;
    clientIdColumn.isEditable = true;
    clientIdColumn.isCaseSensitive = true;
    clientIdColumn.isUnique = true;
    clientIdColumn.isValidEntry = (name: string): boolean => {
      return name.length < 101;
    };
    return clientIdColumn;
  }

  private getApplicationColumn(): SelectColumn<IssuerClientElement> {
    const applicationColumn = createSelectColumn('application');
    applicationColumn.isEditable = true;
    applicationColumn.getDisplayValue = (issuerClientElem: OptionalIssuerClientElement) => {
      if (!issuerClientElem.application) {
        return ApplicationDefaultOption.ALL;
      }
      return issuerClientElem.application;
    };
    applicationColumn.getOptionValue = this.getApplicationValue;
    applicationColumn.getOptionDisplayValue = this.getApplicationValue;
    return applicationColumn;
  }

  private getSecretColumn(): InputColumn<IssuerClientElement> {
    const secretColumn = createInputColumn('secret');
    secretColumn.isEditable = true;
    secretColumn.isCaseSensitive = true;
    secretColumn.isValidEntry = (secret: string): boolean => {
      return secret.length < 256;
    };
    return secretColumn;
  }

  private getAllowedOrgsColumn(): SelectColumn<IssuerClientElement> {
    const allowedOrgsColumn = createSelectColumn('organisation_scope');
    allowedOrgsColumn.multiple = true;
    allowedOrgsColumn.displayName = 'Allowed Organisations';
    allowedOrgsColumn.getMultipleDisplayValues = (issuerClientElem: OptionalIssuerClientElement) => {
      return this.getAllowedOrganisationsValuesArray(issuerClientElem);
    };
    allowedOrgsColumn.getDisplayValue = (issuerClientElem: OptionalIssuerClientElement) => {
      const valuesArray: Array<string> = this.getAllowedOrganisationsValuesArray(issuerClientElem);
      return valuesArray.join(',');
    };
    allowedOrgsColumn.disableOption = (
      issuerClientElem: OptionalIssuerClientElement,
      column: SelectColumn<OptionalIssuerClientElement>,
      value: string
    ) => {
      return (
        // If only one option is currently checked, then we cannot uncheck it. This ensures at least one option is always checked.
        column.getMultipleDisplayValues(issuerClientElem).length === 1 && column.getMultipleDisplayValues(issuerClientElem).includes(value)
      );
    };
    allowedOrgsColumn.getTooltip = () => {
      return 'Please select one of the organisation scopes. Alternatively, you may select one or more specific organisations.';
    };
    return allowedOrgsColumn;
  }

  private getMfaColumn(): SelectColumn<IssuerClientElement> {
    const mfaColumn = createSelectColumn('mfa_challenge');
    mfaColumn.displayName = 'Multi-Factor';
    mfaColumn.allowedValues = Object.keys(IssuerClient.MfaChallengeEnum);
    return mfaColumn;
  }

  private getSingleSignOnColumn(): SelectColumn<IssuerClientElement> {
    const singleSignOnColumn = createSelectColumn('single_sign_on');
    singleSignOnColumn.displayName = 'Single Sign-On';
    singleSignOnColumn.allowedValues = Object.keys(IssuerClient.SingleSignOnEnum);
    return singleSignOnColumn;
  }

  private getRedirectsColumn(): ChiplistColumn<IssuerClientElement> {
    const redirectsColumn = createChipListColumn('redirects');
    redirectsColumn.isEditable = true;
    redirectsColumn.hasAutocomplete = false;
    redirectsColumn.isFreeform = true;
    return redirectsColumn;
  }

  private getSamlMetadataColumn(): FileColumn<IssuerClientElement> {
    const samlMetadataColumn = createFileColumn('saml_metadata_file');
    samlMetadataColumn.displayName = 'SAML SP Metadata';
    samlMetadataColumn.isEditable = true;
    return samlMetadataColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        this.getClientIdColumn(),
        this.getApplicationColumn(),
        this.getSecretColumn(),
        this.getAllowedOrgsColumn(),
        this.getMfaColumn(),
        this.getSingleSignOnColumn(),
        this.getRedirectsColumn(),
        this.getSamlMetadataColumn(),
      ],
      this.columnDefs
    );
  }

  private getApplicationValue(app: OptionalApplication): string {
    if (app.name === '') {
      return ApplicationDefaultOption.ALL;
    }
    return app.name;
  }

  private getAllowedOrganisationsValuesArray(issuerClientElem: OptionalIssuerClientElement): Array<string> {
    let valuesArray: Array<string> = [];
    if (!issuerClientElem) {
      return valuesArray;
    }
    if (issuerClientElem.restricted_organisations && issuerClientElem.restricted_organisations.length !== 0) {
      valuesArray = issuerClientElem.restricted_organisations.map((orgId) => {
        return useValueIfNotInMap(orgId, this.orgIdToOrgNameMap);
      });
    } else if (issuerClientElem.organisation_scope) {
      valuesArray = [issuerClientElem.organisation_scope];
    }
    return valuesArray;
  }

  public makeEmptyTableElement(): IssuerClientElement {
    return {
      name: '',
      application: ApplicationDefaultOption.ALL,
      secret: generateRandomUuid(),
      redirects: [],
      mfa_challenge: IssuerClient.MfaChallengeEnum.user_preference,
      single_sign_on: IssuerClient.SingleSignOnEnum.user_preference,
      org_id: this.orgId,
      issuer_id: this.issuerId,
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.dataSource = new MatTableDataSource(this.tableData);
    this.changeDetector.detectChanges();
  }

  public deleteSelected(issuerClientToDelete: IssuerClientElement): void {
    this.store.dispatch(new ActionIssuerClientsDeletingIssuerClient(cloneDeep(issuerClientToDelete)));
  }

  public getFilterMenuOptions(): Array<FilterMenuOption> {
    return Array.from(this.filterMenuOptions.values());
  }

  public getFilterDisplayName(option: FilterOption): string {
    if (option.label !== '') {
      return option.label + ': ' + option.displayName;
    } else {
      return option.name;
    }
  }

  public filterData(event): void {
    this.filterManager.addInputChipFilterOption(event, this.dataSource);
    this.filterManager.createNestedFilterPredicate(this.dataSource, this.columnDefs);
    this.filterManager.applyFilter(this.dataSource);
  }

  public openEditDialog(dataElement: IssuerClientElement): void {
    const originalRedirects = dataElement.redirects.slice();
    const originalMetadata = dataElement.saml_metadata_file ? dataElement.saml_metadata_file.slice() : dataElement.saml_metadata_file;
    const data: AuthClientDialogData = {
      data: dataElement,
      type: 'Edit',
      columnDefs: this.columnDefs,
      orgMap: this.orgNameToOrgIdMap,
      store: this.store,
      org_id: this.orgId,
      identyProviderNames: this.getAllIdentityProviderNames(),
      upstreamAlias: this.getUpstreamAliasesForClientId(dataElement.id),
      issuer: this.issuer,
    };
    const dialogRef = this.dialog.open(
      ApplicationAuthClientsDialogComponent,
      getDefaultDialogConfig({
        data,
      })
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        // don't save redirects
        dataElement.redirects.splice(0, dataElement.redirects.length, ...originalRedirects);
        dataElement.saml_metadata_file = originalMetadata;
      }
    });
  }

  public openAddDialog(): void {
    const dataElement = this.makeEmptyTableElement();
    const data: AuthClientDialogData = {
      data: dataElement,
      type: 'Add',
      columnDefs: this.columnDefs,
      orgMap: this.orgNameToOrgIdMap,
      store: this.store,
      org_id: this.orgId,
      identyProviderNames: this.getAllIdentityProviderNames(),
      upstreamAlias: undefined,
      issuer: this.issuer,
    };
    const dialogRef = this.dialog.open(
      ApplicationAuthClientsDialogComponent,
      getDefaultDialogConfig({
        data,
      })
    );
  }

  public getRedirectsLength(data: IssuerClientElement): string {
    const length = data.redirects.length;
    return length === 1 ? length.toString() + ' URI' : length.toString() + ' URIs';
  }

  public getAttributesLength(data: IssuerClientElement): string {
    const length = data.attributes ? data.attributes.length : 0;
    return length === 1 ? length.toString() + ' Attribute' : length.toString() + ' Attributes';
  }

  public getAliasesLength(data: IssuerClientElement): string {
    const aliases = this.getUpstreamAliasesForClientId(data.id);
    const length = aliases ? aliases.spec.aliases.length : 0;
    return `${length.toString()} ${pluralizeString('Alias')}`;
  }

  public disableIdPMetadataDownload(): boolean {
    // hide the button if the issuer is not set
    if (this.IdPMetadataUri.length <= this.metadataPathname.length) {
      return true;
    }

    // metadata won't be generated until an xml has been uploaded
    for (const element of this.tableData) {
      if (element.saml_metadata_file) {
        if (element.saml_metadata_file.length !== 0) {
          return false;
        }
      }
    }

    return true;
  }

  public downloadIdPMetadata(): void {
    this.http
      .get(this.IdPMetadataUri, { responseType: 'blob' })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        const link = this.renderer.createElement('a');
        const blob = new Blob([response], { type: 'text/xml' });
        link.href = window.URL.createObjectURL(blob);
        link.download = 'metadata.xml';
        link.click();
      });
  }

  private getAllIdentityProviderNames(): Array<string> {
    if (!this.issuer) {
      return [];
    }
    return [
      ...this.issuer.application_upstreams.map((item) => item.name),
      ...this.issuer.local_auth_upstreams.map((item) => item.name),
      ...this.issuer.oidc_upstreams.map((item) => item.name),
    ];
  }

  private getUpstreamAliases(issuer: Issuer): Observable<Array<UpstreamAlias>> {
    const listUpstreamAliasesRequestParams: ListUpstreamAliasesRequestParams = {
      issuer_id: issuer.id,
      org_id: issuer.org_id,
    };
    return this.issuersService.listUpstreamAliases(listUpstreamAliasesRequestParams).pipe(
      map((resp) => {
        return resp.upstream_aliases;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }

  private getUpstreamAliasesForClientId(clientId: string): UpstreamAlias | undefined {
    if (!this.upstreamAliases) {
      return undefined;
    }
    for (const upstreamAlias of this.upstreamAliases) {
      if (upstreamAlias.spec.client_id === clientId) {
        return upstreamAlias;
      }
    }
    return undefined;
  }
}
