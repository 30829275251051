import {
  InboxItem,
  MessageEndpoint,
  MessageEndpointsConfig,
  MessagesService,
  UpdateMessageEndpointRequestParams,
  User,
} from '@agilicus/angular';
import { ChangeDetectionStrategy, Component, OnInit, Input, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InboxMessageDialogComponent } from '../inbox-message-dialog/inbox-message-dialog.component';
import { getUnreadInboxItemsCount } from '@app/core/api/inbox-items/inbox-items-api-utils';
import { catchError, interval, map, mergeMap, Observable, of, startWith, Subject, takeUntil, combineLatest, concatMap } from 'rxjs';
import { AppState, NotificationService } from '@app/core';
import { select, Store } from '@ngrx/store';
import {
  deletingInboxItem,
  deletingInboxItems,
  getInboxItems,
  initInboxItems,
  savingInboxItem,
  savingInboxItems,
} from '@app/core/inbox-item-state/inbox-item.actions';
import { SwPush } from '@angular/service-worker';
import { ChallengeType } from '../challenge-type.enum';
import { detect } from 'detect-browser';
import moment from 'moment';
import { selectInboxItemList, selectInboxItemUnreadCount } from '@app/core/inbox-item-state/inbox-item.selectors';
import { selectCanAdminInboxItems } from '@app/core/user/permissions/inboxItems.selectors';
import { cloneDeep } from 'lodash-es';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';

@Component({
  selector: 'portal-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public user: User;
  private orgId: string;
  public hasPermissions: boolean;
  public inboxItems: Array<InboxItem>;
  public unreadInboxItemsCount = 0;
  private cachedUnreadInboxItemCount = 0;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private localUserCache: User;
  private vapidPublicKey = '';
  public subtoken = '';
  private browser = detect();
  public accessToken = '';
  public webPushEnabled: boolean;
  public enrollMessageSent = false;

  constructor(
    public dialog: MatDialog,
    private messagesService: MessagesService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private swPush: SwPush
  ) {
    this.webPushEnabled = this.swPush.isEnabled;
  }

  public ngOnInit(): void {
    this.store.dispatch(initInboxItems({ force: true, blankSlate: false }));
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasMessagesPermissionsResp,
          inboxItemListStateResp,
          unreadInboxItemsCountResp,
          listMessageConfigResp,
          unreadInboxItemsCountStateResp,
        ]) => {
          this.hasPermissions = hasMessagesPermissionsResp?.hasPermission;
          this.orgId = hasMessagesPermissionsResp?.orgId;
          if (!this.hasPermissions) {
            return;
          }
          if (listMessageConfigResp) {
            if (listMessageConfigResp.web_push && listMessageConfigResp.web_push.public_key) {
              this.vapidPublicKey = listMessageConfigResp.web_push.public_key;
            }
          } else {
            this.notificationService.error(
              'A network error prevented fetching the messaging keys. You will be unable to add a new web push endpoint. You may browse or change the existing methods, or refresh the browser to retry.'
            );
          }
          if (unreadInboxItemsCountResp !== this.cachedUnreadInboxItemCount) {
            // The number from the polling has changed, so this is the most up to date number
            const unreadInboxItemsCount = unreadInboxItemsCountResp !== undefined ? unreadInboxItemsCountResp : 0;
            this.unreadInboxItemsCount = unreadInboxItemsCount;
            this.cachedUnreadInboxItemCount = unreadInboxItemsCount;
          } else {
            // Otherwise, set to the number from the latest state
            this.unreadInboxItemsCount = unreadInboxItemsCountStateResp;
          }
          this.inboxItems = inboxItemListStateResp;
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnChanges(): void {
    if (!this.localUserCache && !!this.user) {
      this.store.dispatch(initInboxItems({ force: true, blankSlate: false }));
      this.localUserCache = this.user;
    }
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAllData$(): Observable<[OrgQualifiedPermission, InboxItem[], number, MessageEndpointsConfig, number]> {
    const hasMessagesPermissions$ = this.store.pipe(select(selectCanAdminInboxItems));
    return hasMessagesPermissions$.pipe(
      concatMap((hasMessagesPermissionsResp) => {
        if (!hasMessagesPermissionsResp?.hasPermission) {
          // Do not call the inbox api if user does not have permissions.
          return combineLatest([of(hasMessagesPermissionsResp), of(undefined), of(undefined), of(undefined), of(undefined)]);
        }
        const inboxItemListState$ = this.store.pipe(select(selectInboxItemList));
        const listMessageConfig$ = this.messagesService.listMessagesConfig();
        const orgId = hasMessagesPermissionsResp.orgId;
        const unreadInboxItemsCount$ = interval(30 * 1000)
          .pipe(startWith(0))
          .pipe(mergeMap(() => getUnreadInboxItemsCount(this.messagesService, this.user.id, orgId)));
        const unreadInboxItemsCountState$ = this.store.pipe(select(selectInboxItemUnreadCount));
        return combineLatest([
          of(hasMessagesPermissionsResp),
          inboxItemListState$,
          unreadInboxItemsCount$,
          listMessageConfig$,
          unreadInboxItemsCountState$,
        ]);
      })
    );
  }

  public openInboxItem(item: InboxItem): void {
    const dialogData: InboxItem = item;
    const dialogRef = this.dialog.open(InboxMessageDialogComponent, {
      data: dialogData,
    });

    if (!item.spec.has_been_read) {
      this.markInboxItemAsRead(item, true);
    }
  }

  public deleteInboxItem(item: InboxItem): void {
    this.store.dispatch(deletingInboxItem({ obj: cloneDeep(item), trigger_update_side_effects: false, notifyUser: true }));
  }

  public markInboxItemAsRead(item: InboxItem, doNotSendNotificationToastr?: boolean): void {
    item.spec.has_been_read = !item.spec.has_been_read;
    this.store.dispatch(
      savingInboxItem({
        obj: cloneDeep(item),
        trigger_update_side_effects: false,
        notifyUser: !!doNotSendNotificationToastr ? !doNotSendNotificationToastr : true,
      })
    );
  }

  public getAllInboxItems(): void {
    if (!this.orgId) {
      return;
    }
    this.store.dispatch(getInboxItems({ org_id: this.orgId, blankSlate: false }));
  }

  private updateWebPushMessageEndpoint$(sub: PushSubscription): Observable<MessageEndpoint | undefined> {
    const sub1 = JSON.stringify(sub);
    this.subtoken = sub1;
    const mpa: UpdateMessageEndpointRequestParams = {
      user_id: this.user.id,
      MessageEndpoint: {
        spec: {
          endpoint_type: ChallengeType.web_push,
          nickname: this.browser?.name + '-' + this.browser?.os + '-' + this.browser?.version,
          address: sub1,
        },
      },
    };
    return this.messagesService.updateMessageEndpoint(mpa).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the message endpoint id.');
        return of(undefined);
      })
    );
  }

  public subscribeToNotifications(): void {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.vapidPublicKey,
      })
      .then((sub) => this.updateWebPushMessageEndpoint$(sub).pipe(takeUntil(this.unsubscribe$)).subscribe())
      .catch((err) => this.notificationService.error('Could not subscribe to notifications ' + err));
  }

  public getUserFriendlyTime(date: string): string {
    return moment(new Date(date)).fromNow();
  }

  public checkNotificationPermission(): boolean {
    return 'Notification' in window && Notification.permission === 'granted';
  }

  public markAllInboxItemsAsRead(inboxItems: Array<InboxItem>): void {
    const filteredItems = inboxItems.filter((item) => !item.spec.has_been_read);

    const updatedItems = filteredItems.map((item) => {
      return {
        ...item,
        spec: {
          ...item.spec,
          has_been_read: true,
        },
      };
    });

    this.replaceManyInboxItems(updatedItems);
  }

  public markAllInboxItemsAsUnRead(inboxItems: Array<InboxItem>): void {
    const filteredItems = inboxItems.filter((item) => item.spec.has_been_read);

    const updatedItems = filteredItems.map((item) => {
      return {
        ...item,
        spec: {
          ...item.spec,
          has_been_read: false,
        },
      };
    });
    this.replaceManyInboxItems(updatedItems);
  }

  public replaceManyInboxItems(updatedItems: Array<InboxItem>): void {
    this.store.dispatch(savingInboxItems({ objs: cloneDeep(updatedItems), trigger_update_side_effects: false, notifyUser: true }));
  }

  public deleteAllInboxItems(inboxItems: Array<InboxItem>): void {
    this.store.dispatch(deletingInboxItems({ objs: cloneDeep(inboxItems), trigger_update_side_effects: false, notifyUser: true }));
  }

  public getMatBadgeNumber(): number {
    return this.unreadInboxItemsCount > 0 ? this.unreadInboxItemsCount : null;
  }
}
