import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { KeyTabManager } from '../../key-tab-manager/key-tab-manager';

@Component({
  selector: 'portal-stepper-form-field',
  templateUrl: './stepper-form-field.component.html',
  styleUrls: ['./stepper-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperFormFieldComponent {
  @Input() public placeholder = '';
  @Input() public label = '';
  @Input() public formControlName: string;
  @Input() public required = false;
  // Workaround for angular stepper bug:
  // See: https://github.com/angular/components/issues/20923
  @Input() public accessOptionChanged = false;
  @Input() public formCtrl: UntypedFormControl;
  @Input() public tooltip = '';
  @Output() public formBlur = new EventEmitter<any>();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor() {}

  public onFormBlurEvent(): void {
    this.formBlur.emit();
  }

  public getLabel(): string {
    return !!this.label ? this.label : this.placeholder;
  }
}
