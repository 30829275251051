<portal-page-info
  pageTitle="Authentication Audit Records"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions" class="auth-audit-container">
  <div class="auth-audit-form-container">
    <form
      class="auth-audit-form"
      [formGroup]="authAuditForm"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="set-log-time">
        <mat-form-field class="auth-audit-filter-field">
          <mat-label>Select a time frame:</mat-label>
          <mat-select 
            #select1
            formControlName="quick_select_time"
            (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
          >
            <mat-option value="custom">Custom</mat-option>
            <mat-option
              *ngFor="let time of timeIntervalOptions"
              value="{{ time.interval }}"
              >{{ time.displayedInterval }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="auth-audit-filter-field"
          *ngIf="authAuditForm.value.quick_select_time === 'custom'"
        >
          <mat-label>Choose start date</mat-label>
          <input
            matInput
            [max]="maxDate"
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            formControlName="dtFrom"
            [owlDateTimeFilter]="getStartDateMaxSetter(authAuditForm)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          />
          <owl-date-time #dt1></owl-date-time>
          <mat-error>Enter valid date and time</mat-error>
        </mat-form-field>

        <p
          class="auth-audit-filter-field"
          *ngIf="authAuditForm.value.quick_select_time === 'custom'"
        >
          to
        </p>

        <mat-form-field
          class="auth-audit-filter-field"
          *ngIf="authAuditForm.value.quick_select_time === 'custom'"
        >
          <mat-label>Choose end date</mat-label>
          <input
            matInput
            [owlDateTime]="dt2"
            [owlDateTimeTrigger]="dt2"
            formControlName="dtTo"
            [owlDateTimeFilter]="getEndDateMinSetter(authAuditForm)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          />
          <owl-date-time #dt2></owl-date-time>
          <mat-error>Enter valid date and time</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="auth-audit-filter-field">
        <mat-label>Max number of results</mat-label>
        <input
          matInput
          type="text"
          formControlName="limit"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <mat-error>Please enter a valid number</mat-error>
      </mat-form-field>

      <div class="button-container">
        <button
          type="button"
          mat-raised-button
          color="primary"
          class="show-logs-button"
          [disabled]="!authAuditForm.valid"
          (click)="getAuthAudits()"
          matTooltip="Click to update the information in the audit table"
        >
          <fa-icon [icon]="['fas', 'search']"></fa-icon> VIEW AUDITS 
        </button>

        <portal-download-button
          class="download-button-container"
          [buttonDescription]="downloadButtonDescription"
          tooltipText="Click to download the audit information"
          (downloadFile)="downloadAuthAuditData()"
        >
        </portal-download-button>
      </div>
    </form>
  </div>

  <div class="auth-table-container">
    <portal-table-layout-readonly
      [tableData]="tableData"
      [columnDefs]="columnDefs"
      [isNestedTable]="true"
    >
    </portal-table-layout-readonly>
  </div>
  
</div>
  
