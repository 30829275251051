import { ColumnTypes } from '../column-types.enum';
import { UntypedFormControl } from '@angular/forms';
import { capitalizeFirstLetter, replaceCharacterWithSpace } from '../utils';
import { IconColor } from '../icon-color.enum';
import { createChiplistInput, getFilteredValues, onDefaultInputValueChange } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { FormInput } from '../custom-chiplist-input/form-input';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { AutocompleteInput } from '../custom-chiplist-input/autocomplete-input';
import { DropdownInput } from '../custom-chiplist-input/dropdown-input';
import { InputData } from '../custom-chiplist-input/input-data';
import { TableElement } from './table-element';

export interface ActionMenuOptions<T extends InputData> {
  displayName: string;
  icon: string;
  tooltip: string;
  onClick?: (element: T) => void;
  fileReplace?: boolean;
  isDisabled?: (element: T) => boolean;
  isHidden?: (element: T) => boolean;
}

export interface Column<T extends InputData> extends FormInput<T> {
  displayName: string;
  type: ColumnTypes;
  showColumn: boolean;
  warnValue?: (element: T) => boolean;
  sticky?: boolean;
}

export interface InputColumn<T extends InputData> extends Column<T> {
  copyToClipboard?: boolean;
  hasIconPrefix?: boolean;
  getIconPrefix?: (element: T) => string;
  getIconColor?: (element: T) => IconColor;
  inputType?: string;
}

export interface AutoInputColumn<T extends InputData> extends InputColumn<T>, AutoCompleteColumn<T> {
  allowAnyValue?: boolean;
  /**
   * Converts the autocomplete input field into a readonly input field when the value is set.
   */
  freezeWhenSet?: boolean;
}

export type DropdownColumn<T extends InputData> = DropdownInput<T>;

export interface SelectColumn<T extends InputData> extends Column<T>, DropdownColumn<T> {
  multiple?: boolean;
  getMultipleDisplayValues?: (element: T) => Array<string>;
}

export interface CheckBoxColumn<T extends InputData> extends Column<T> {
  isChecked?: (element: T) => boolean;
  isIndeterminate?: (element: T) => boolean;
  setElementFromCheckbox?: (element: T, isBoxChecked: boolean) => T;
}

export interface FileColumn<T extends InputData> extends Column<T> {
  downloadFile?: (element: object) => void;
}

export interface InputLinkColumn<T extends InputData> extends Column<T> {
  onClick?: (element: T) => void;
}

export interface IconColumn<T extends InputData> extends Column<T> {
  getLink?: (element: T) => string;
  isImageLink?: (element: T) => boolean;
  onImageClick?: (element: T) => void;
}

export interface ReadonlyColumn<T extends InputData> extends Column<T> {
  hasIconPrefix?: boolean;
  getIconPrefix?: (element: T) => string;
  getIconColor?: (element: T) => IconColor;
}

export interface AutoCompleteColumn<T extends InputData> extends Column<T>, DropdownColumn<T>, AutocompleteInput<T> {}

export interface ChiplistColumn<T extends InputData> extends Column<T>, ChiplistInput<T> {}

export function createSelectRowColumn<T extends InputData>(): Column<T> {
  return {
    name: 'selectRow',
    displayName: '',
    type: ColumnTypes.SELECT_ROW,
    filterOn: false,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.SELECT_ROW,
    disableField: () => false,
    requiredField: () => false,
  };
}

export function createIconColumn<T extends InputData>(name: string): IconColumn<T> {
  return {
    name,
    displayName: '',
    type: ColumnTypes.ICON,
    filterOn: false,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: true,
    showColumn: true,
    inputSize: InputSize.STANDARD,
    getTooltip: () => {
      return '';
    },
    isImageLink: () => false,
    disableField: () => false,
    requiredField: () => false,
  };
}

export function createResourceIconColumn<T extends InputData, DataTypeElement extends TableElement>(
  getIconURIFromElement: (element: DataTypeElement) => string,
  openIconDialog: (element: DataTypeElement) => void
): IconColumn<T> {
  const iconColumn = createIconColumn('icon');
  /**
   * Determines the mat-icon name to be passed into the mat-icon
   * html tag for display in the table. The name is a string that
   * identifies the type of mat-icon.
   */
  iconColumn.getDisplayValue = () => {
    return 'add_photo_alternate';
  };
  iconColumn.getTooltip = (element: DataTypeElement) => {
    const icon = getIconURIFromElement(element);
    return !!icon ? 'Click to modify icon' : 'Click to add icon';
  };
  iconColumn.getLink = (element: DataTypeElement) => {
    return getIconURIFromElement(element);
  };
  iconColumn.isImageLink = () => true;
  iconColumn.onImageClick = (element: DataTypeElement) => {
    openIconDialog(element);
  };
  return iconColumn;
}

export function createInputColumn<T extends InputData>(name: string): InputColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.INPUT,
    filterOn: true,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.TEXT_INPUT,
    copyToClipboard: false,
    getDisplayValue: (element: any): any => {
      return element[name];
    },
    getTooltip: (element: any, column: Column<T>): string => {
      return column.getDisplayValue(element);
    },
    disableField: () => false,
    isReadOnly: () => false,
    requiredField: () => false,
  };
}

export function createInputLinkColumn<T extends InputData>(name: string): InputLinkColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.INPUT_LINK,
    filterOn: true,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.TEXT_INPUT,
    getDisplayValue: (element: any): any => {
      return element[name];
    },
    disableField: () => false,
    requiredField: () => false,
  };
}

export function createAutoInputColumn<T extends InputData>(name: string): AutoInputColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.AUTOINPUT,
    allowedValues: [],
    formControl: new UntypedFormControl(),
    filterOn: true,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.TEXT_INPUT,
    allowAnyValue: true,
    freezeWhenSet: false,
    getDisplayValue: (item: any): string => {
      return item;
    },
    isReadOnly: () => false,
    disableField: () => false,
    onInputValueChange: (inputValue: string, column: ChiplistColumn<T>) => {
      onDefaultInputValueChange(inputValue, column);
    },
    requiredField: () => false,
    isValidEntry: (value: string, element: T, column: AutoInputColumn<T>) => {
      if (column.isReadOnly(element)) {
        // If the column is readonly, then the value has already been set. Therefore, we do not need to validate it.
        return true;
      }
      if (column.allowAnyValue) {
        return true;
      }
      return !!column.allowedValues.map((item) => column.getDisplayValue(item)).includes(value);
    },
  };
}

export function createSelectColumn<T extends InputData>(name: string): SelectColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.SELECT,
    allowedValues: [],
    filterOn: true,
    isEditable: true,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    multiple: false,
    inputSize: InputSize.SELECT_INPUT,
    getDisplayValue: (element: any): any => {
      return element[name];
    },
    getOptionValue: (element: any): string => {
      return element;
    },
    getOptionDisplayValue: (element: any): string => {
      return element;
    },
    hideOptions: (): boolean => {
      return false;
    },
    disableOption: (): boolean => {
      return false;
    },
    getTooltip: (): string => {
      return '';
    },
    disableField: () => false,
    getAllowedValues: (): Array<any> => {
      return undefined;
    },
    requiredField: () => false,
  };
}

export function createChipListColumn<T extends InputData>(name: string): ChiplistColumn<T> {
  const chiplistInput = createChiplistInput(name);
  return {
    ...chiplistInput,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.CHIPLIST,
    showColumn: true,
  };
}

export function createActionsColumn<T extends InputData>(name: string): Column<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.ACTIONS,
    allowedValues: [],
    filterOn: false,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: true,
    showColumn: true,
    inputSize: InputSize.ACTIONS,
    disableField: () => false,
    requiredField: () => false,
    getTooltip: () => {
      return 'Click to view available actions';
    },
  };
}

export function createCheckBoxColumn<T extends TableElement>(name: string): CheckBoxColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.CHECK,
    filterOn: false,
    isEditable: true,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.SMALL,
    isChecked: (element: T): boolean => {
      if (element[name]) {
        return true;
      }
      return false;
    },
    isIndeterminate: (): boolean => {
      return false;
    },
    setElementFromCheckbox: (element: T, isBoxChecked: boolean): any => {
      element[name] = isBoxChecked;
    },
    disableField: () => false,
    getTooltip: (element: T, column: CheckBoxColumn<T>) => {
      if (element.isNew && column.requiredField) {
        return 'This is a required field';
      }
      return '';
    },
    requiredField: () => false,
  };
}

export function createFileColumn<T extends InputData>(name: string): FileColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.FILE,
    filterOn: true,
    isEditable: true,
    isUnique: false,
    isCaseSensitive: true,
    showColumn: true,
    inputSize: InputSize.LARGE,
    getDisplayValue: (element: any): any => {
      return element[name];
    },
    disableField: () => false,
    requiredField: () => false,
  };
}

export function createReadonlyColumn<T extends InputData>(name: string): ReadonlyColumn<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    type: ColumnTypes.READONLY,
    filterOn: true,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.STANDARD,
    getDisplayValue: (element: any): any => {
      return element[name];
    },
    getTooltip: (element: any, column: Column<T>): string => {
      return column.getDisplayValue(element);
    },
    disableField: () => false,
    requiredField: () => false,
    warnValue: (element: T): boolean => {
      return false;
    },
  };
}

export function createExpandColumn<T extends TableElement>(): Column<T> {
  return {
    name: 'expandRow',
    displayName: '',
    type: ColumnTypes.EXPAND,
    filterOn: false,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.STANDARD,
    disableField: (element: T) => {
      // We want to disable the expand until after the new row is created.
      return element.index === -1;
    },
    getTooltip: (element: any) => {
      if (element.isRowExpanded) {
        return 'Click to collapse the row';
      } else {
        return 'Click to expand the row';
      }
    },
    requiredField: () => false,
  };
}

/**
 * Acts as a "placeholder" for a row of adjacent hidden columns. It is a visual indicator that
 * there are columns hidden and allows the user to click on the column header to uncollapse them.
 */
export function createCollapsedColumn<T extends InputData>(name: string): Column<T> {
  return {
    name: getNameOfCollapsedColumn(name),
    displayName: '',
    type: ColumnTypes.COLLAPSED,
    filterOn: false,
    isEditable: false,
    isUnique: false,
    isCaseSensitive: false,
    showColumn: true,
    inputSize: InputSize.SMALL,
    getDisplayValue: (): any => {
      return '';
    },
    getTooltip: (): string => {
      return '';
    },
    disableField: () => false,
    requiredField: () => false,
  };
}

export function setColumnFormControlFilters<T extends InputData>(columnDefs: Map<string, Column<T>>): void {
  for (const column of Array.from(columnDefs.values())) {
    if (column.formControl !== undefined) {
      column.filteredValues = getFilteredValues(column.formControl, column);
    }
  }
}

export function setColumnDefs<T extends InputData>(columns: Array<Column<T>>, columnDefs: Map<string, Column<T>>): void {
  columnDefs.clear();
  addCollapsedColumnsToArray(columns);
  for (const column of columns) {
    columnDefs.set(column.name, column);
  }
}

/**
 * Adds placeholder "collapsed" columns for a row of adjacent hidden columns. It is a visual indicator that
 * there are columns hidden and allows the user to click on the column header to uncollapse them.
 */
export function addCollapsedColumnsToArray<T extends InputData>(columnsArray: Array<Column<T>>): void {
  for (let i = 0; i < columnsArray.length; i++) {
    const currentColumn = columnsArray[i];
    const previousColumn = columnsArray[i - 1];
    if (currentColumn.showColumn) {
      continue;
    }
    const isPreviousColumnCollapsedOrHidden =
      !!previousColumn && (previousColumn.type === ColumnTypes.COLLAPSED || previousColumn.showColumn === false);
    if (isPreviousColumnCollapsedOrHidden) {
      continue;
    }
    // Insert collapsed placeholder column:
    columnsArray.splice(i, 0, createCollapsedColumn(currentColumn.name));
    i = i + 1;
  }
}

export function getNameOfCollapsedColumn(name: string): string {
  return `${name}_collapsed`;
}

/**
 * Will hide a selected column and either replace it with a placeholder "collapsed" column
 * or just hide it if the column being hidden is adjacent to an already hidden column.
 */
export function handleCollapseColumn<T extends TableElement>(columnToHide: Column<T>, columnDefs: Map<string, Column<T>>): void {
  const columnDefsAsArray = Array.from(columnDefs.values());
  const indexOfColumnToHide = columnDefsAsArray.findIndex((col) => col.name === columnToHide.name);
  const nextColumn = columnDefsAsArray[indexOfColumnToHide + 1];
  const previousColumn = columnDefsAsArray[indexOfColumnToHide - 1];
  // Check if the column to the immediate right of the columnToHide is already collapsed:
  if (nextColumn?.type === ColumnTypes.COLLAPSED) {
    // Remove existing collapsed placeholder column to the right of the columnToHide:
    columnDefsAsArray.splice(indexOfColumnToHide + 1, 1);
  }
  const isPreviousColumnCollapsedOrHidden =
    !!previousColumn && (previousColumn.type === ColumnTypes.COLLAPSED || previousColumn.showColumn === false);
  if (!isPreviousColumnCollapsedOrHidden) {
    // Insert new collapsed placeholder column:
    columnDefsAsArray.splice(indexOfColumnToHide, 0, createCollapsedColumn(columnToHide.name));
  }
  // Hide the target column:
  columnToHide.showColumn = false;
  // Reset the columnDefs map to trigger the change detection:
  columnDefs.clear();
  setColumnDefs(columnDefsAsArray, columnDefs);
}

/**
 * Will expand any hidden columns that are currently adjacent to the placeholder "collapsed" column
 * that was selected by the user and also removes that placeholder "collapsed" column.
 */
export function handleExpandColumn<T extends TableElement>(collapsedColumn: Column<T>, columnDefs: Map<string, Column<T>>): void {
  const columnDefsAsArray = Array.from(columnDefs.values());
  const indexOfCollapsedPlaceholderColumn = columnDefsAsArray.findIndex((col) => col.name === collapsedColumn.name);
  // Since we insert the collapsed placeholder column to the left
  // of the first hidden "normal" column in a sequence, we only
  // need to expand all "normal" columns immediately to the right
  // of the collapsed placeholder column, hence the "+ 1" below.
  for (let i = indexOfCollapsedPlaceholderColumn + 1; i < columnDefsAsArray.length; i++) {
    const currentColumn = columnDefsAsArray[i];
    if (currentColumn.showColumn === false) {
      // This column was hidden, therefore, we now want to show it:
      currentColumn.showColumn = true;
      continue;
    } else {
      // If we hit a column that is already being shown, then
      // we have reached the end of the hidden columns sequence
      // and therefore will stop needing to open more columns.
      break;
    }
  }
  // Remove the existing collapsed placeholder column:
  columnDefsAsArray.splice(indexOfCollapsedPlaceholderColumn, 1);
  // Reset the columnDefs map to trigger the change detection:
  columnDefs.clear();
  setColumnDefs(columnDefsAsArray, columnDefs);
}
