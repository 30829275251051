import {
  AuthenticationDocument,
  BulkTokenRevokeResponse,
  GetServiceAccountRequestParams,
  Organisation,
  patch_via_put,
  ReplaceServiceAccountRequestParams,
  ServiceAccount,
  ServiceAccountSpec,
  TokensService,
  UsersService,
} from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import {
  selectCurrentOrganisation,
  selectOrgIdToOrgNameMap,
  selectOrgNameToOrgIdMap,
  selectSubOrganisations,
} from '@app/core/organisations/organisations.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { getServiceAccounts } from '@app/core/user/user.utils';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { downloadJSON } from '../file-utils';
import { FilterManager } from '../filter/filter-manager';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { OptionalServiceAccountElement } from '../optional-types';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import {
  ActionMenuOptions,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createChipListColumn,
  createInputColumn,
  createSelectRowColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { copyTextToClipboard, updateTableElements } from '../utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { Description } from '../user-admin/user-admin.component';
import { bulkRevokeUserSessionAndTokens$, createAuthenticationDocument, getRevokeSessionsButton } from '@app/core/api/token-api-utils';
import { ButtonType } from '../button-type.enum';
import { TableButton } from '../buttons/table-button/table-button.component';
import { MatDialog } from '@angular/material/dialog';
import { createDialogData } from '../dialog-utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

export interface ServiceAccountElement extends TableElement, ServiceAccountSpec, Description {
  backingServiceAccount: ServiceAccount;
}

export interface CombinedPermissionsAndServiceAccountData {
  permission: OrgQualifiedPermission;
  serviceAccounts: Array<ServiceAccount>;
  subOrgsList: Array<Organisation>;
  orgNameToOrgIdMap: Map<string, string>;
  orgIdToOrgNameMap: Map<string, string>;
  currentOrganisation: Organisation;
}

export interface StoreData {
  permission: OrgQualifiedPermission;
  subOrgsList: Array<Organisation>;
  orgNameToOrgIdMap: Map<string, string>;
  orgIdToOrgNameMap: Map<string, string>;
  currentOrganisation: Organisation;
}

@Component({
  selector: 'portal-service-account-admin',
  templateUrl: './service-account-admin.component.html',
  styleUrls: ['./service-account-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceAccountAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasUsersPermissions: boolean;
  private orgId: string;
  public tableData: Array<ServiceAccountElement> = [];
  public columnDefs: Map<string, Column<ServiceAccountElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'SERVICE ACCOUNT';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public pageDescriptiveText = `Service accounts represent non-human users who need to interact with the Agilicus API or your resources. 
  You would typically create a service account for a task to be performed without a user present. 
  For example, a service running in a virtual machine or container could use a service account to represent the service. 
  Or, a batch job which runs periodically could use a service account to access a resource.

  Here you can create new service accounts or view, modify and delete existing ones.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/service-accounts/`;
  public subOrgNameList: Array<string> = [];
  public orgNameToOrgIdMap: Map<string, string> = new Map();
  public orgIdToOrgNameMap: Map<string, string> = new Map();
  private storeData$: Observable<StoreData>;
  private currentOrganisation: Organisation;
  public buttonsToShow: Array<string> = [ButtonType.ADD, ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    getRevokeSessionsButton<ServiceAccountElement>('service account', this.revokeSelected.bind(this)),
  ];
  private dialogOpen = false;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private usersService: UsersService,
    private appErrorHandler: AppErrorHandler,
    private tokensService: TokensService,
    public renderer: Renderer2,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.storeData$ = this.store.pipe(select(selectCanAdminUsers)).pipe(
      withLatestFrom(
        this.store.pipe(select(selectSubOrganisations)),
        this.store.pipe(select(selectOrgNameToOrgIdMap)),
        this.store.pipe(select(selectOrgIdToOrgNameMap)),
        this.store.pipe(select(selectCurrentOrganisation))
      ),
      map(
        ([hasUsersPermissionsResp, subOrgsListResp, orgNameToOrgIdMapResp, orgIdToOrgNameMapResp, currentOrgRersp]: [
          OrgQualifiedPermission,
          Array<Organisation>,
          Map<string, string>,
          Map<string, string>,
          Organisation
        ]) => {
          const storeData: StoreData = {
            permission: hasUsersPermissionsResp,
            subOrgsList: subOrgsListResp,
            orgNameToOrgIdMap: orgNameToOrgIdMapResp,
            orgIdToOrgNameMap: orgIdToOrgNameMapResp,
            currentOrganisation: currentOrgRersp,
          };
          return storeData;
        }
      )
    );
    this.getPermissionsAndServiceAccounts();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showNoPermissionsText(): boolean {
    return this.hasUsersPermissions !== undefined && !this.hasUsersPermissions;
  }

  private getPermissionsAndServiceAccounts(): void {
    const combinedUsersPermissionsAndServiceAccounts$ = this.storeData$.pipe(
      concatMap((storeDataResp: StoreData) => {
        this.orgId = storeDataResp.permission?.orgId;
        let serviceAccounts$: Observable<Array<ServiceAccount>> = of(undefined);
        if (!!storeDataResp?.permission?.hasPermission) {
          serviceAccounts$ = getServiceAccounts(this.usersService, this.orgId);
        }
        return combineLatest([serviceAccounts$, of(storeDataResp)]);
      }),
      map(([serviceAccountsResp, storeDataResp]: [Array<ServiceAccount>, StoreData]) => {
        const combinedUsersPermissionsAndServiceAccounts: CombinedPermissionsAndServiceAccountData = {
          permission: storeDataResp.permission,
          serviceAccounts: serviceAccountsResp,
          subOrgsList: storeDataResp.subOrgsList,
          orgNameToOrgIdMap: storeDataResp.orgNameToOrgIdMap,
          orgIdToOrgNameMap: storeDataResp.orgIdToOrgNameMap,
          currentOrganisation: storeDataResp.currentOrganisation,
        };
        return combinedUsersPermissionsAndServiceAccounts;
      })
    );
    combinedUsersPermissionsAndServiceAccounts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((combinedUsersPermissionsAndServiceAccountsResp) => {
        this.hasUsersPermissions = combinedUsersPermissionsAndServiceAccountsResp.permission.hasPermission;
        if (!this.hasUsersPermissions) {
          // Need this in order for the "No Permissions" text to be displayed when the page first loads.
          this.changeDetector.detectChanges();
          return;
        }
        this.subOrgNameList = combinedUsersPermissionsAndServiceAccountsResp.subOrgsList.map((org) => org.id);
        this.orgNameToOrgIdMap = combinedUsersPermissionsAndServiceAccountsResp.orgNameToOrgIdMap;
        this.orgIdToOrgNameMap = combinedUsersPermissionsAndServiceAccountsResp.orgIdToOrgNameMap;
        this.currentOrganisation = combinedUsersPermissionsAndServiceAccountsResp.currentOrganisation;
        if (!!combinedUsersPermissionsAndServiceAccountsResp.serviceAccounts) {
          this.updateTable(combinedUsersPermissionsAndServiceAccountsResp.serviceAccounts);
        }
      });
  }

  private setColumnsAllowedValues(): void {
    const allowedSubOrgsColumn = this.columnDefs.get('allowed_sub_orgs');
    allowedSubOrgsColumn.allowedValues = this.subOrgNameList;
  }

  private updateTable(serviceAccounts: Array<ServiceAccount>): void {
    this.setColumnsAllowedValues();
    this.buildData(serviceAccounts);
    this.replaceTableWithCopy();
  }

  private buildData(serviceAccounts: Array<ServiceAccount>): void {
    const data: Array<ServiceAccountElement> = [];
    for (let i = 0; i < serviceAccounts.length; i++) {
      const serviceAccount = serviceAccounts[i];
      data.push(this.createServiceAccountElement(serviceAccount, i));
    }
    updateTableElements(this.tableData, data);
  }

  private createServiceAccountElement(serviceAccount: ServiceAccount, index: number): ServiceAccountElement {
    const data: ServiceAccountElement = {
      name: serviceAccount.spec.name,
      enabled: serviceAccount.spec.enabled,
      allowed_sub_orgs: serviceAccount.spec.allowed_sub_orgs,
      backingServiceAccount: serviceAccount,
      ...getDefaultTableProperties(index),
      ...serviceAccount.spec,
    };
    return data;
  }

  private getNameColumn(): Column<ServiceAccountElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.isEditable = true;
    nameColumn.requiredField = () => true;
    nameColumn.isUnique = true;
    nameColumn.getHeaderTooltip = () => {
      return `The name of the service account. 
      This will be used as part of the generated email for the service account. 
      Note the service account's email will not be updated after it has been created.`;
    };
    nameColumn.inputSize = InputSize.TEXT_INPUT_LARGE;
    return nameColumn;
  }

  private getEnabledColumn(): Column<ServiceAccountElement> {
    const enabledColumn = createCheckBoxColumn('enabled');
    enabledColumn.isEditable = true;
    enabledColumn.isChecked = (element: OptionalServiceAccountElement) => {
      return element.enabled;
    };
    enabledColumn.setElementFromCheckbox = (element: OptionalServiceAccountElement, isBoxChecked: boolean): any => {
      element.enabled = isBoxChecked;
    };
    return enabledColumn;
  }

  private getAllowedSubOrgsColumn(): Column<ServiceAccountElement> {
    const allowedSubOrgsColumn = createChipListColumn('allowed_sub_orgs');
    allowedSubOrgsColumn.displayName = 'Allowed sub-organisations';
    allowedSubOrgsColumn.isEditable = true;
    (allowedSubOrgsColumn.inputSize = InputSize.STANDARD),
      (allowedSubOrgsColumn.getDisplayValue = (subOrgId: any) => {
        return this.orgIdToOrgNameMap.get(subOrgId);
      });
    allowedSubOrgsColumn.getElementFromValue = (subOrgName: string) => {
      return this.orgNameToOrgIdMap.get(subOrgName);
    };
    allowedSubOrgsColumn.getHeaderTooltip = () => {
      return `The list of sub-organisations that this service account can be used in. 
      To enable this service account in a sub-organisation, activate this service account's user in the organisation. 
      To do this you will need the service account user's email, which you can retrieve using the "Copy Service Account Email" action in the table.`;
    };
    return allowedSubOrgsColumn;
  }

  private getDescriptionColumn(): Column<ServiceAccountElement> {
    const descriptionColumn = createInputColumn('description');
    descriptionColumn.displayName = 'Description';
    descriptionColumn.isEditable = true;
    descriptionColumn.isCaseSensitive = true;
    descriptionColumn.getDisplayValue = (element: OptionalServiceAccountElement) => {
      if (element.inheritable_config?.description) {
        return element.inheritable_config.description;
      }
      return '';
    };
    return descriptionColumn;
  }

  public downloadAuthenticationDocumentAsJSON(authDoc: AuthenticationDocument, fileName: string): void {
    delete authDoc._builtin_original;
    downloadJSON(authDoc, this.renderer, fileName);
  }

  private getActionsColumn(): Column<ServiceAccountElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<ServiceAccountElement>> = [
      {
        displayName: 'Download Authentication Document',
        icon: 'cloud_download',
        tooltip: 'Click to download the authentication document for this service account',
        onClick: (element: OptionalServiceAccountElement) => {
          const fileName = `authentication_document_${element.name}`;
          createAuthenticationDocument(this.tokensService, element.backingServiceAccount.status.user.id, this.currentOrganisation)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((authenticationDocumentResp) => {
              // downloadJSON(authenticationDocumentResp, this.renderer, fileName);
              this.downloadAuthenticationDocumentAsJSON(authenticationDocumentResp, fileName);
            });
        },
      },
      {
        displayName: 'Copy Service Account Email',
        icon: 'content_copy',
        tooltip: 'Click to copy the service account email to clipboard',
        onClick: (element: OptionalServiceAccountElement) => {
          const serviceAccountEmail = element.backingServiceAccount.status.user.email;
          copyTextToClipboard(serviceAccountEmail);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getNameColumn(),
        this.getAllowedSubOrgsColumn(),
        this.getDescriptionColumn(),
        this.getEnabledColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  public makeEmptyTableElement(): ServiceAccountElement {
    return {
      name: '',
      enabled: true,
      allowed_sub_orgs: [],
      org_id: this.orgId,
      description: '',
      inheritable_config: {
        description: '',
      },
      backingServiceAccount: {
        spec: {
          name: '',
          enabled: false,
          allowed_sub_orgs: [],
          org_id: this.orgId,
          inheritable_config: {
            description: '',
          },
        },
      },
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Receives an ServiceAccountElement from the table then updates and saves
   * the service account.
   */
  public updateEvent(updatedServiceAccountElement: ServiceAccountElement): void {
    this.saveServiceAccount(updatedServiceAccountElement);
  }

  private canNotDeleteServiceAccount(serviceAccount: ServiceAccountElement): boolean {
    return !!serviceAccount.protected_by_id || !!serviceAccount.protected_by_type;
  }

  public deleteSelected(serviceAccountElementToDelete: Array<ServiceAccountElement>): void {
    for (const serviceAccount of serviceAccountElementToDelete) {
      if (this.canNotDeleteServiceAccount(serviceAccount)) {
        this.openCannotDeleteServiceAccountDialog();
        return;
      }
    }
    const observablesArray: Array<Observable<object>> = [];
    for (const serviceAccount of serviceAccountElementToDelete) {
      if (serviceAccount.index === -1) {
        continue;
      }
      observablesArray.push(
        this.usersService.deleteServiceAccount({
          service_account_id: serviceAccount.backingServiceAccount.metadata.id,
          org_id: this.orgId,
        })
      );
    }
    if (observablesArray.length === 0) {
      this.notificationService.success('Unsaved service accounts were removed');
      this.getPermissionsAndServiceAccounts();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Service accounts were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected service accounts');
        },
        () => {
          this.getPermissionsAndServiceAccounts();
        }
      );
  }

  private postServiceAccount(serviceAccountToCreate: ServiceAccount): Observable<ServiceAccount> {
    return this.usersService.createServiceAccount({
      ServiceAccount: serviceAccountToCreate,
    });
  }

  private createNewServiceAccount(newServiceAccountElement: ServiceAccountElement): void {
    const newServiceAccount = this.getServiceAccountFromServiceAccountElement(newServiceAccountElement);
    this.postServiceAccount(newServiceAccount)
      .pipe(take(1))
      .subscribe(
        (postServiceAccountResp) => {
          this.notificationService.success(`Service account "${postServiceAccountResp.spec.name}" was successfully created`);
        },
        (errorResp) => {
          const baseMessage = `Failed to create service account "${newServiceAccountElement.backingServiceAccount.spec.name}"`;
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        },
        () => {
          this.getPermissionsAndServiceAccounts();
        }
      );
  }

  private putServiceAccount(serviceAccountToUpdate: ServiceAccount): Observable<ServiceAccount> {
    const getter = (serviceAccount: ServiceAccount) => {
      const getServiceAccountRequestParams: GetServiceAccountRequestParams = {
        service_account_id: serviceAccount.metadata.id,
        org_id: this.orgId,
      };
      return this.usersService.getServiceAccount(getServiceAccountRequestParams);
    };
    const putter = (serviceAccount: ServiceAccount) => {
      const replaceServiceAccountRequestParams: ReplaceServiceAccountRequestParams = {
        service_account_id: serviceAccount.metadata.id,
        ServiceAccount: serviceAccount,
      };
      return this.usersService.replaceServiceAccount(replaceServiceAccountRequestParams);
    };
    return patch_via_put(serviceAccountToUpdate, getter, putter);
  }

  private updateExistingServiceAccount(updatedServiceAccountElement: ServiceAccountElement): void {
    const updatedServiceAccount = this.getServiceAccountFromServiceAccountElement(updatedServiceAccountElement);
    this.putServiceAccount(updatedServiceAccount)
      .pipe(take(1))
      .subscribe(
        (putServiceAccountResp) => {
          this.notificationService.success(`Service account "${putServiceAccountResp.spec.name}" was successfully updated`);
        },
        (errorResp) => {
          const baseMessage = `Failed to update service account "${updatedServiceAccountElement.backingServiceAccount.spec.name}"`;
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        },
        () => {
          this.getPermissionsAndServiceAccounts();
        }
      );
  }

  private getServiceAccountFromServiceAccountElement(serviceAccountElement: ServiceAccountElement): ServiceAccount {
    const result: ServiceAccount = serviceAccountElement.backingServiceAccount;
    result.spec.name = serviceAccountElement.name;
    result.spec.enabled = serviceAccountElement.enabled;
    result.spec.allowed_sub_orgs = serviceAccountElement.allowed_sub_orgs;
    if (serviceAccountElement.inheritable_config?.description) {
      serviceAccountElement.inheritable_config.description = serviceAccountElement.description;
    } else {
      serviceAccountElement.inheritable_config = {
        description: serviceAccountElement.description,
      };
    }
    result.spec.inheritable_config = serviceAccountElement.inheritable_config;
    return result;
  }

  private saveServiceAccount(updatedServiceAccountElement: ServiceAccountElement): void {
    if (updatedServiceAccountElement.index === -1) {
      this.createNewServiceAccount(updatedServiceAccountElement);
    } else {
      this.updateExistingServiceAccount(updatedServiceAccountElement);
    }
  }

  public openCannotDeleteServiceAccountDialog(): void {
    this.dialogOpen = true;
    const messagePrefix = `Deletion Not Permitted`;
    let message = `Deletion of one or more of the selected service accounts is not permitted`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: '', cancel: 'Cancel' } },
    });
  }

  private revokeSelected(serviceAccountsToRevoke: Array<ServiceAccountElement>): void {
    const observablesArray: Array<Observable<BulkTokenRevokeResponse>> = [];
    for (const serviceAccount of serviceAccountsToRevoke) {
      observablesArray.push(
        bulkRevokeUserSessionAndTokens$(this.tokensService, serviceAccount.backingServiceAccount.status.user.id, this.orgId)
      );
    }
    if (observablesArray.length === 0) {
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('All sessions have been successfully revoked for the selected service accounts');
        },
        (err) => {
          this.notificationService.error('Failed to revoke all sessions for the selected service accounts');
        },
        () => {
          this.getPermissionsAndServiceAccounts();
        }
      );
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
