import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppState } from '@app/core';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { select, Store } from '@ngrx/store';
import {
  Connector,
  ConnectorSpec,
  FileShareClientConfigWindowsConfig,
  FileShareService,
  LabelledObject,
  NetworkMountRuleConfig,
  Resource,
} from '@agilicus/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from '../button-type.enum';
import { FilterManager } from '../filter/filter-manager';
import {
  ActionMenuOptions,
  ChiplistColumn,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createChipListColumn,
  createExpandColumn,
  createInputColumn,
  createResourceIconColumn,
  createSelectColumn,
  createSelectRowColumn,
  IconColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { doesStringStartWithSlash, isValidPath, isValidResourceName } from '../validation-utils';
import {
  isConnectorBeingRemovedFromResource,
  openRemoveConnectorFromResourceWarningDialog,
  setConnectorColumnAllowedValues,
} from '../connector-utils';
import { getDefaultNestedDataProperties, getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { OptionalFileShareServiceElement, OptionalShareClientConfigElement } from '../optional-types';
import { convertEmptyStringToUndefined, getIconURIFromResource, updateTableElements } from '../utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import {
  deletingFileShareServices,
  initFileShareServices,
  resetIconStatusFileShareServices,
  savingFileShareService,
  savingIconFileShareServices,
} from '@app/core/file-share-state/file-share-service.actions';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import {
  selectFileShareServiceEntity,
  selectFileShareServiceIconStatus,
  selectFileShareServiceList,
  selectFileShareServiceRefreshDataValue,
} from '@app/core/file-share-state/file-share-service.selectors';
import { selectConnectorList, selectConnectorRefreshDataValue } from '@app/core/connector-state/connector.selectors';
import { cloneDeep } from 'lodash-es';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InputData } from '../custom-chiplist-input/input-data';
import { ResourceLogoDialogComponent, ResourceLogoDialogData } from '../resource-logo-dialog/resource-logo-dialog.component';
import { ResourceType } from '../resource-type.enum';
import { getDefaultLogoDialogConfig } from '../dialog-utils';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  PolicyTemplateInstanceWithLabels,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { getTrimmedResourceName } from '../resource-utils';

export interface FileShareServiceElement extends ResourceTableElement {
  share_name: string;
  name: string;
  local_path: string;
  connector_name: string;
  prevous_connector_name: string;
  file_level_access_permissions: boolean;
  sub_path: string | undefined;
  backingObject: FileShareService;
}

export interface ShareClientConfigElement extends TableElement {
  windows_drive_name?: string;
  windows_drive_type?: FileShareClientConfigWindowsConfig.TypeEnum;
  linux_path?: string;
  mac_path?: string;
  tags?: Array<string>;
  parentId: string | number;
}

@Component({
  selector: 'portal-file-shares-overview',
  templateUrl: './file-shares-overview.component.html',
  styleUrls: ['./file-shares-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSharesOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasAppsPermissions: boolean;
  private orgId: string;
  public tableData: Array<FileShareServiceElement> = [];
  public columnDefs: Map<string, Column<FileShareServiceElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'SHARE';
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      `ADD ${this.rowObjectName}`,
      ButtonColor.PRIMARY,
      `Add a new ${this.rowObjectName.toLowerCase()}`,
      `Button that adds a new ${this.rowObjectName.toLowerCase()}`,
      () => {
        this.router.navigate(['/shares-new'], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
  ];
  public warnOnNOperations = 1;
  private connectorIdToConnectorMap: Map<string, Connector> = new Map();
  private connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public pageDescriptiveText = `Shares are a means of taking a directory on a local server and making the contents available to any user, without a VPN and without a client.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-shares/`;
  private localShareRefreshDataValue = 0;
  private localConnectorsRefreshDataValue = 0;
  private fileShareServices: Array<FileShareService>;
  // For policy column:
  private policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels> = [];
  // For policy column:
  private resources: Array<Resource> = [];
  // For policy column:
  private labelledObjects: Array<LabelledObject> = [];
  // For policy column:
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstanceWithLabels> = new Map();
  // For policy column:
  private localPoliciesRefreshDataValue = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog,
    private routerHelperService: RouterHelperService,
    private policyResourceLinkService: PolicyResourceLinkService<FileShareServiceElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initFileShareServices({ force: true, blankSlate: false }));
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    // For policy column:
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const shareListState$ = this.store.pipe(select(selectFileShareServiceList));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const refreshShareDataState$ = this.store.pipe(select(selectFileShareServiceRefreshDataValue));
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    combineLatest([
      hasAppsPermissions$,
      this.policyResourceLinkService.getPolicyAndResourceAndLabelDataWithPermissions$(),
      shareListState$,
      connectorListState$,
      refreshShareDataState$,
      refreshConnectorDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasAppsPermissionsResp,
          getPermissionsAndResourceAndLabelDataResp,
          shareListStateResp,
          connectorListStateResp,
          refreshShareDataStateResp,
          refreshConnectorDataStateResp,
        ]) => {
          this.orgId = hasAppsPermissionsResp.orgId;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          if (!this.hasAppsPermissions || !connectorListStateResp || !shareListStateResp || shareListStateResp.length === 0) {
            this.resetEmptyTable();
            return;
          }
          this.setConnectorIdToNameMap(connectorListStateResp);
          // For policy column:
          this.policyTemplateInstanceList = getPermissionsAndResourceAndLabelDataResp.policyTemplateInstanceList;
          // For policy column:
          this.resources = getPermissionsAndResourceAndLabelDataResp.resourcesList;
          // For policy column:
          this.labelledObjects = getPermissionsAndResourceAndLabelDataResp.labelledObjects;
          const filteredConnectorList: Array<Connector> = connectorListStateResp.filter(
            (connector) => connector.status.instances.length <= 1
          );
          setConnectorColumnAllowedValues(
            this.columnDefs.get('connector_name'),
            filteredConnectorList,
            this.orgId,
            ConnectorSpec.ConnectorTypeEnum.agent,
            [ConnectorSpec.ConnectorTypeEnum.agent]
          );
          // For policy column:
          this.policyResourceLinkService.setPolicyNameAndTypeStringMap(
            this.policyNameAndTypeStringToPolicyMap,
            this.policyTemplateInstanceList
          );
          // For policy column:
          this.policyResourceLinkService.setResourceTablePoliciesColumnAllowedValuesList(this.columnDefs, this.policyTemplateInstanceList);
          if (
            this.tableData.length === 0 ||
            this.localShareRefreshDataValue !== refreshShareDataStateResp ||
            this.localConnectorsRefreshDataValue !== refreshConnectorDataStateResp ||
            this.localPoliciesRefreshDataValue !== getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue
          ) {
            this.localShareRefreshDataValue = refreshShareDataStateResp;
            this.localConnectorsRefreshDataValue = refreshConnectorDataStateResp;
            this.localPoliciesRefreshDataValue = getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue;
            this.fileShareServices = cloneDeep(shareListStateResp);
            if (
              this.localShareRefreshDataValue !== 0 &&
              this.localConnectorsRefreshDataValue !== 0 &&
              this.localPoliciesRefreshDataValue !== 0
            ) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.updateTable();
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setConnectorIdToNameMap(connectors: Array<Connector>): void {
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  /**
   * Parent table column
   */
  private getIconColumn(): IconColumn<FileShareServiceElement> {
    return createResourceIconColumn<InputData, FileShareServiceElement>(
      this.getIconURIFromElement.bind(this),
      this.openIconDialog.bind(this)
    );
  }

  private getIconURIFromElement(element: FileShareServiceElement): string {
    return getIconURIFromResource(element.backingObject);
  }

  public openIconDialog(element: FileShareServiceElement): void {
    const dialogData: ResourceLogoDialogData<FileShareService> = {
      resourceType: ResourceType.fileshare,
      saveAction: savingIconFileShareServices,
      resetStatusAction: resetIconStatusFileShareServices,
      getResourceStateSelector: selectFileShareServiceEntity({ id: element.backingObject.metadata.id }),
      getIconStatusStateSelector: selectFileShareServiceIconStatus,
    };
    const dialogRef = this.dialog.open(
      ResourceLogoDialogComponent,
      getDefaultLogoDialogConfig({
        data: dialogData,
      })
    );
  }

  /**
   * Parent table column
   */
  private getNameColumn(): Column<FileShareServiceElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.isEditable = true;
    nameColumn.isValidEntry = (name: string): boolean => {
      return isValidResourceName(name);
    };
    nameColumn.getFormattedValue = (name: string, element: FileShareServiceElement): string => {
      return getTrimmedResourceName(name);
    };
    return nameColumn;
  }

  /**
   * Parent table column
   */
  private getLocalPathColumn(): Column<FileShareServiceElement> {
    const localPathColumn = createInputColumn('local_path');
    localPathColumn.displayName = 'Local directory';
    localPathColumn.isEditable = true;
    return localPathColumn;
  }

  /**
   * Parent table column
   */
  private getFileLevelAccessPermissionsColumn(): Column<FileShareServiceElement> {
    const fileLevelAccessPermissionsColumn = createCheckBoxColumn('file_level_access_permissions');
    fileLevelAccessPermissionsColumn.displayName = 'File Level Perm';
    fileLevelAccessPermissionsColumn.isEditable = true;
    fileLevelAccessPermissionsColumn.getHeaderTooltip = () => {
      return `If enabled, user must both have Share and (local) File access. File disabled, Share access only`;
    };
    return fileLevelAccessPermissionsColumn;
  }

  /**
   * Parent table column
   */
  private getConnectorNameColumn(): Column<FileShareServiceElement> {
    const connectorNameColumn = createSelectColumn('connector_name');
    connectorNameColumn.displayName = 'Connector';
    connectorNameColumn.isEditable = true;
    return connectorNameColumn;
  }

  /**
   * Parent Table Column
   */
  private getPoliciesColumn(): ChiplistColumn<FileShareServiceElement> {
    return this.policyResourceLinkService.getResourceTablePoliciesColumn(this.policyNameAndTypeStringToPolicyMap);
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<FileShareServiceElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<FileShareServiceElement>> = [
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: OptionalFileShareServiceElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.backingObject.spec.org_id,
            target_resource_id: element.backingObject.metadata.id,
            target_resource_name: element.name,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  /**
   * Nested Form Column
   */
  private getSubPathColumn(): Column<FileShareServiceElement> {
    const column = createInputColumn('sub_path');
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return `Must start with "/", for example: "/{AGILICUS_USER_EMAIL_DOMAIN}". A sub path of the share to which users will be restricted. 
      By using variable expansion, you can give different users access to different parts of the share without needing to create multiple shares.`;
    };
    column.isValidEntry = (value: string): boolean => {
      if (!value) {
        return true;
      }
      return doesStringStartWithSlash(value);
    };
    return column;
  }

  private initializeColumnDefs(): void {
    if (this.shouldGateAccess()) {
      // Do not include policies column:
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getIconColumn(),
          this.getNameColumn(),
          this.getLocalPathColumn(),
          this.getFileLevelAccessPermissionsColumn(),
          this.getConnectorNameColumn(),
          this.getActionsColumn(),
          createExpandColumn(),
        ],
        this.columnDefs
      );
    } else {
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getIconColumn(),
          this.getNameColumn(),
          this.getLocalPathColumn(),
          this.getFileLevelAccessPermissionsColumn(),
          this.getConnectorNameColumn(),
          this.getPoliciesColumn(),
          this.getActionsColumn(),
          createExpandColumn(),
        ],
        this.columnDefs
      );
    }
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<FileShareServiceElement> = [];
    for (let i = 0; i < this.fileShareServices.length; i++) {
      const fileShareService = this.fileShareServices[i];
      data.push(this.createTableElement(fileShareService, i));
    }
    this.setNestedTableData(data);
    updateTableElements(this.tableData, data);
  }

  private createTableElement(item: FileShareService, index: number): FileShareServiceElement {
    const connectorName = this.connectorIdToConnectorMap.get(item.spec.connector_id)?.spec.name;
    const data: FileShareServiceElement = {
      share_name: item.spec.share_name,
      name: item.spec.name,
      local_path: item.spec.local_path,
      file_level_access_permissions: item.spec.file_level_access_permissions,
      connector_name: connectorName ? connectorName : '',
      prevous_connector_name: connectorName ? connectorName : '',
      sub_path: item.spec.sub_path,
      nestedFormColumnDefs: new Map(),
      backingObject: item,
      ...getDefaultTableProperties(index),
      ...this.policyResourceLinkService.getResourceElementWithPolicies(
        item,
        this.resources,
        this.labelledObjects,
        this.policyTemplateInstanceList
      ),
    };
    setColumnDefs([this.getSubPathColumn()], data.nestedFormColumnDefs);
    return data;
  }

  private deleteFromNestedTable(shareClientConfigElement: ShareClientConfigElement): void {
    const parentElement = this.getParentElementFromParentId(shareClientConfigElement);
    parentElement.expandedData.nestedTableData = parentElement.expandedData.nestedTableData.filter((element) => !element.isChecked);
    parentElement.backingObject.spec.client_config = this.getClientConfigsFromNestedTableData(parentElement.expandedData.nestedTableData);
    this.saveItem(parentElement);
  }

  private deleteFromParentTable(fileShareElementsToDelete: Array<FileShareServiceElement>): void {
    const fileSharesToDelete: Array<FileShareService> = fileShareElementsToDelete.map((element) => this.getItemFromTableElement(element));
    // Need to make a copy of the object or it will be converted to readonly.
    this.store.dispatch(
      deletingFileShareServices({ objs: cloneDeep(fileSharesToDelete), trigger_update_side_effects: false, notifyUser: true })
    );
  }

  public deleteSelected(elementsToDelete: Array<FileShareServiceElement | ShareClientConfigElement>): void {
    const elementAsClientConfig = elementsToDelete[0] as ShareClientConfigElement;
    if (elementAsClientConfig.parentId !== undefined && elementAsClientConfig.parentId !== null) {
      this.deleteFromNestedTable(elementAsClientConfig);
      return;
    }
    const elementsAsFileShareServices = elementsToDelete as Array<FileShareServiceElement>;
    this.deleteFromParentTable(elementsAsFileShareServices);
  }

  public showNoPermissionsText(): boolean {
    return this.hasAppsPermissions !== undefined && !this.hasAppsPermissions;
  }

  private getClientConfigsFromNestedTableData(nestedTableData: Array<ShareClientConfigElement>): Array<NetworkMountRuleConfig> {
    const updatedClientConfigs: Array<NetworkMountRuleConfig> = [];
    for (const element of nestedTableData) {
      updatedClientConfigs.push(this.getClientConfigFromShareClientConfigElement(element));
    }
    return updatedClientConfigs;
  }

  private getClientConfigFromShareClientConfigElement(shareClientConfigElement: ShareClientConfigElement): NetworkMountRuleConfig {
    const result: NetworkMountRuleConfig = {
      rules: {},
      mount: {},
    };
    result.mount = {
      windows_config: {
        name: convertEmptyStringToUndefined(shareClientConfigElement.windows_drive_name),
        type: shareClientConfigElement.windows_drive_type,
      },
      linux_config: {
        path: convertEmptyStringToUndefined(shareClientConfigElement.linux_path),
      },
      mac_config: {
        path: convertEmptyStringToUndefined(shareClientConfigElement.mac_path),
      },
    };
    result.rules = {
      tags: shareClientConfigElement.tags,
    };
    return result;
  }

  private getParentElementFromParentId(shareClientConfigElement: ShareClientConfigElement): FileShareServiceElement | undefined {
    return this.tableData[shareClientConfigElement.parentId];
  }

  private getItemFromTableElement(tableElement: FileShareServiceElement): FileShareService {
    const result: FileShareService = tableElement.backingObject;
    result.spec.share_name = tableElement.share_name;
    result.spec.name = tableElement.name;
    result.spec.local_path = tableElement.local_path;
    result.spec.file_level_access_permissions = tableElement.file_level_access_permissions;
    result.spec.sub_path = tableElement.sub_path;
    return result;
  }

  /**
   * Receives an FileShareServiceElement from the table then updates and saves
   * the file share service.
   */
  public updateEvent(updatedShareElement: FileShareServiceElement | ShareClientConfigElement): void {
    const updatedElementAsClientConfig = updatedShareElement as ShareClientConfigElement;
    if (updatedElementAsClientConfig.parentId !== undefined && updatedElementAsClientConfig.parentId !== null) {
      const parentElement = this.getParentElementFromParentId(updatedElementAsClientConfig);
      const updatedClientConfigs = this.getClientConfigsFromNestedTableData(parentElement.expandedData.nestedTableData);
      parentElement.backingObject.spec.client_config = updatedClientConfigs;
      this.saveItem(parentElement);
      return;
    }
    const updatedShareElementAsFileShareServiceElement = updatedShareElement as FileShareServiceElement;
    if (isConnectorBeingRemovedFromResource(updatedShareElementAsFileShareServiceElement)) {
      openRemoveConnectorFromResourceWarningDialog(updatedShareElementAsFileShareServiceElement, this.dialog, this.saveItem.bind(this));
      return;
    }
    this.saveItem(updatedShareElementAsFileShareServiceElement);
  }

  private saveItem(updatedTableElement: FileShareServiceElement): void {
    const updatedItem = this.getItemFromTableElement(updatedTableElement);
    this.policyResourceLinkService
      .submitPoliciesResult$(updatedTableElement, this.orgId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(updatedTableElement, resourceAndLabelsAndPolicyResponse);
        // Need to make a copy of the object or it will be converted to readonly.
        this.store.dispatch(savingFileShareService({ obj: cloneDeep(updatedItem), trigger_update_side_effects: false, notifyUser: true }));
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<FileShareServiceElement>; element: FileShareServiceElement }): void {
    if (params.column.name !== 'connector_name') {
      return;
    }
    const elementCopy = cloneDeep(params.element);
    params.element.prevous_connector_name = elementCopy.connector_name;
    params.element.connector_name = params.value;
    const targetConnector = this.connectorNameToConnectorMap.get(params.value);
    if (!!targetConnector) {
      params.element.backingObject.spec.connector_id = targetConnector.metadata.id;
    } else {
      params.element.backingObject.spec.connector_id = '';
    }
  }

  private isValidWindowsDrive(entry: string, windowsDriveType: FileShareClientConfigWindowsConfig.TypeEnum): boolean {
    if (!entry) {
      return false;
    }
    if (windowsDriveType !== FileShareClientConfigWindowsConfig.TypeEnum.mapped_drive) {
      return true;
    }
    const regex = /^[a-zA-Z](\:?)$/;
    if (entry.length > 2 || entry.length < 1) {
      return false;
    }
    if (entry.match(regex) === null) {
      return false;
    }
    return true;
  }

  /**
   * Nested Table Column
   */
  private getWindowsDriveNameColumn(): Column<ShareClientConfigElement> {
    const windowsDriveNameColumn = createInputColumn('windows_drive_name');
    windowsDriveNameColumn.displayName = 'Windows drive';
    windowsDriveNameColumn.isEditable = true;
    windowsDriveNameColumn.isValidEntry = (entry: string, element: OptionalShareClientConfigElement) => {
      if (entry.trim() === '') {
        return true;
      }
      const isValidWindowsDrive = this.isValidWindowsDrive(entry.trim(), element.windows_drive_type);
      if (isValidWindowsDrive) {
        element.windows_drive_type = FileShareClientConfigWindowsConfig.TypeEnum.mapped_drive;
      }
      return isValidWindowsDrive;
    };
    windowsDriveNameColumn.getHeaderTooltip = () => {
      return 'The drive to attach this share to. This will be a single drive letter between A and Z, such as "C".';
    };
    windowsDriveNameColumn.requiredField = (element: OptionalShareClientConfigElement) => {
      if (element?.windows_drive_type === FileShareClientConfigWindowsConfig.TypeEnum.mapped_drive) {
        return true;
      }
      return false;
    };
    return windowsDriveNameColumn;
  }

  /**
   * Nested Table Column
   */
  private getLinuxPathColumn(): Column<ShareClientConfigElement> {
    const linuxPathColumn = createInputColumn('linux_path');
    linuxPathColumn.isEditable = true;
    linuxPathColumn.getHeaderTooltip = () => {
      return 'The absolute path this drive should be mounted to';
    };
    linuxPathColumn.isValidEntry = (path: string): boolean => {
      if (path === '') {
        return true;
      }
      return isValidPath(path);
    };
    return linuxPathColumn;
  }

  /**
   * Nested Table Column
   */
  private getMacPathColumn(): Column<ShareClientConfigElement> {
    const macPathColumn = createInputColumn('mac_path');
    macPathColumn.isEditable = true;
    macPathColumn.getHeaderTooltip = () => {
      return 'The absolute path this drive should be mounted to';
    };
    macPathColumn.isValidEntry = (path: string): boolean => {
      if (path === '') {
        return true;
      }
      return isValidPath(path);
    };
    return macPathColumn;
  }

  /**
   * Nested Table Column
   */
  private getTagColumn(): Column<ShareClientConfigElement> {
    const tagColumn = createChipListColumn('tags');
    tagColumn.getHeaderTooltip = () => {
      return 'A list of machine tags for which a rule applies';
    };
    tagColumn.isFreeform = true;
    return tagColumn;
  }

  private initializeNestedColumnDefs(nestedColumnDefs: Map<string, Column<ShareClientConfigElement>>): void {
    setColumnDefs(
      [createSelectRowColumn(), this.getWindowsDriveNameColumn(), this.getLinuxPathColumn(), this.getMacPathColumn(), this.getTagColumn()],
      nestedColumnDefs
    );
  }

  private createShareClientConfigElement(
    clientConfig: NetworkMountRuleConfig,
    index: number,
    element: FileShareServiceElement
  ): ShareClientConfigElement {
    const data: ShareClientConfigElement = {
      windows_drive_name: clientConfig.mount?.windows_config?.name,
      windows_drive_type: clientConfig.mount?.windows_config?.type,
      linux_path: clientConfig.mount?.linux_config?.path,
      mac_path: clientConfig.mount?.mac_config?.path,
      tags: clientConfig.rules.tags,
      parentId: element.index,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private setNestedTableData(data: Array<FileShareServiceElement>): void {
    for (const element of data) {
      element.expandedData = {
        ...getDefaultNestedDataProperties(element),
        nestedRowObjectName: 'CLIENT CONFIG',
        nestedButtonsToShow: [ButtonType.ADD, ButtonType.DELETE],
        makeEmptyNestedTableElement: (): ShareClientConfigElement => {
          return {
            windows_drive_name: '',
            windows_drive_type: FileShareClientConfigWindowsConfig.TypeEnum.network_location,
            linux_path: '',
            mac_path: '',
            tags: [],
            parentId: element.index,
            ...getDefaultNewRowProperties(),
          };
        },
      };
      this.initializeNestedColumnDefs(element.expandedData.nestedColumnDefs);
      if (!element.backingObject?.spec?.client_config) {
        continue;
      }
      for (let i = 0; i < element.backingObject.spec.client_config.length; i++) {
        const clientConfig = element.backingObject.spec.client_config[i];
        const nestedElement = this.createShareClientConfigElement(clientConfig, i, element);
        element.expandedData.nestedTableData.push(nestedElement);
      }
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
