import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '@app/core';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { InputData } from '../custom-chiplist-input/input-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { MatTableDataSource } from '@angular/material/table';
import { FilterManager } from '../filter/filter-manager';
import { FilterMenuOption } from '../table-filter/table-filter.component';
import {
  Column,
  createReadonlyColumn,
  createSelectColumn,
  createSelectRowColumn,
  ReadonlyColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { getValuesFromInputEventValue, updateTableElements } from '../utils';
import { TableElement } from '../table-layout/table-element';
import { getDefaultTableProperties } from '../table-layout-utils';
import { ButtonType } from '../button-type.enum';
import { TableButton } from '../buttons/table-button/table-button.component';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { cloneDeep } from 'lodash-es';
import {
  addChipOnAutoSelectEvent,
  areDuplicateInputValuesEntered,
  areExistingChipValuesEntered,
  areInvalidValuesEntered,
  areNonexistantOptionValuesEntered,
  getChiplistDialogMultiAutocompleteColumnName,
  getMultiAutocompleteInputValues,
  onNewChipAdded,
  updateElementFromChiplistValuesAndDetechChanges,
} from '../custom-chiplist-input/custom-chiplist-input.utils';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { PaginatorActions, PaginatorConfig } from '../table-paginator/table-paginator.component';
import { ParamSpecificFilterManager } from '../param-specific-filter-manager/param-specific-filter-manager';
import { FilterType } from '../filter-type.enum';

export interface ChiplistExpandTableElement extends TableElement {
  value: any;
  secondary_option?: any;
}

export interface ChiplistExpandDialogData<T extends InputData> {
  chiplistInput: ChiplistInput<T>;
  element: T;
  isChipRemovable: boolean;
  removeFromAllowedValues: (element: T, chiplistInput: ChiplistInput<T>, optionValue: string) => boolean;
}

@Component({
  selector: 'portal-chiplist-expand-dialog',
  templateUrl: './chiplist-expand-dialog.component.html',
  styleUrls: ['./chiplist-expand-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChiplistExpandDialogComponent<T extends TableElement> implements OnInit, OnDestroy {
  public chiplistInput: ChiplistInput<T>;
  public element: T;
  public removeFromAllowedValues: (element: T, chiplistInput: ChiplistInput<T>, optionValue: string) => boolean;
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public showAllChips = true;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public filterManager: FilterManager = new FilterManager();
  public leftTableParamSpecificFilterManager: ParamSpecificFilterManager = new ParamSpecificFilterManager();
  public leftTableFilterManager: FilterManager = new FilterManager();
  public rightTableFilterManager: FilterManager = new FilterManager();
  public leftTableFilterMenuOptions: Map<string, FilterMenuOption> = new Map();
  public chiplistInputAsColumnDefs: Map<string, ChiplistInput<T>> = new Map();
  public searchFilterPlaceholder = 'Search the list';
  public leftTableData: Array<ChiplistExpandTableElement> = [];
  public rightTableData: Array<ChiplistExpandTableElement> = [];
  public leftTableColumnDefs: Map<string, Column<T>> = new Map();
  public rightTableColumnDefs: Map<string, Column<T>> = new Map();
  public buttonsToShow: Array<ButtonType> = [];
  public customButtons: Array<TableButton> = [];
  public largeHeaders = true;
  public freeFormChiplistForm: UntypedFormGroup;
  private chipListError = false;
  public showTableControls = false;
  public leftTablePaginatorConfig = new PaginatorConfig<ChiplistExpandTableElement>(
    true,
    false,
    10,
    5,
    new PaginatorActions<ChiplistExpandTableElement>()
  );
  public rightTablePaginatorConfig = new PaginatorConfig<ChiplistExpandTableElement>(
    true,
    false,
    10,
    5,
    new PaginatorActions<ChiplistExpandTableElement>()
  );
  public originalElementCopy: T;
  public originalChiplistInputAllowedValues: Array<any>;

  @ViewChild('leftTableLayoutComp') private leftTableLayoutComp: TableLayoutComponent<ChiplistExpandTableElement>;
  @ViewChild('rightTableLayoutComp') private rightTableLayoutComp: TableLayoutComponent<ChiplistExpandTableElement>;

  constructor(
    public dialogRef: MatDialogRef<ChiplistExpandDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: ChiplistExpandDialogData<T>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.chiplistInput = data.chiplistInput;
    this.element = data.element;
    this.originalElementCopy = cloneDeep(data.element);
    this.originalChiplistInputAllowedValues = cloneDeep(this.chiplistInput.allowedValues);
    this.removeFromAllowedValues = data.removeFromAllowedValues;
    if (!!this.chiplistInput.filterManager) {
      this.leftTableFilterManager = this.chiplistInput.filterManager;
    }
    if (!!this.chiplistInput.filterMenuOptions) {
      this.leftTableFilterMenuOptions = this.chiplistInput.filterMenuOptions;
    }
    this.setCustomMultiAutocompleteFilterData();
    this.searchFilterPlaceholder = `Search the list of ${this.chiplistInput.displayName}`;
  }

  private setCustomMultiAutocompleteFilterData(): void {
    if (!!this.chiplistInput.hasMultiAutocomplete && !!this.chiplistInput.filterMenuOptions) {
      const paramSpecificFilterManager = new ParamSpecificFilterManager();
      paramSpecificFilterManager.addParamSpecificSearchFilterOption({
        name: getChiplistDialogMultiAutocompleteColumnName(),
        displayName: '',
        label: this.leftTableFilterMenuOptions.get(getChiplistDialogMultiAutocompleteColumnName()).displayName,
        type: FilterType.PARAM_SPECIFIC_SEARCH,
        doParamSpecificSearchFilter: this.filterOnSecondaryOption.bind(this),
      });
      this.leftTableParamSpecificFilterManager = paramSpecificFilterManager;
    }
  }

  private filterOnSecondaryOption(value: any): void {
    this.updateTables(value);
  }

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.initializeLeftColumnDefs();
    this.initializeRightColumnDefs();
    this.setEditableColumnDefs();
    this.updateTables();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private initializeFormGroup(): void {
    if (!this.chiplistInput.isFreeform) {
      return;
    }
    this.freeFormChiplistForm = this.formBuilder.group({
      option_value: '',
    });
  }

  private updateTables(multiAutocompleteFilterValue?: string): void {
    this.buildAllTableData(multiAutocompleteFilterValue);
    this.replaceTablesWithCopy();
  }

  private buildAllTableData(multiAutocompleteFilterValue?: string): void {
    this.buildLeftTableData(multiAutocompleteFilterValue);
    this.buildRightTableData();
  }

  public getChiplistValuesList(): Array<any> {
    return this.chiplistInput.getChiplistValues(this.element, this.chiplistInput);
  }

  public getFilteredChiplistDisplayValues(): Array<any> {
    return this.dataSource.filteredData;
  }

  public removeChip(chipValue: any, element: T): void {
    element[this.chiplistInput.name] = element[this.chiplistInput.name].filter(
      (value) => this.chiplistInput.getDisplayValue(value) !== this.chiplistInput.getDisplayValue(chipValue)
    );
    this.changeDetector.detectChanges();
  }

  public getElementIdentifier(): string | undefined {
    return this.element[this.element.elementIdentifyingProperty];
  }

  public getRowIdentifierText(): string {
    const elementIdentifier = this.getElementIdentifier();
    return !!elementIdentifier ? elementIdentifier : '';
  }

  /**
   * Left table column
   */
  private getAvailableOptionColumn(): ReadonlyColumn<T> {
    const column = createReadonlyColumn(this.chiplistInput?.name);
    column.displayName = `Available ${this.chiplistInput?.displayName}`;
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return `Select options from here to add to the list on the right`;
    };
    if (this.chiplistInput.hasMultiAutocomplete) {
      column.inputSize = InputSize.SMALL;
    }
    return column;
  }

  /**
   * Will return a list of the secondary options that have not already been added to the table on the right
   */
  private getMultiAutocompleteOptionAllowedValues(element: any): Array<any> {
    const currentOptionValuesList = this.element[this.chiplistInput.name].map((value) => this.chiplistInput.getDisplayValue(value));
    const secondaryAllowedValues = this.chiplistInput.getSecondaryAllowedValues(element[this.chiplistInput?.name]);
    const secondaryAllowedValuesAsMultiAutocompleteInputValues = secondaryAllowedValues.map((value) => {
      return getMultiAutocompleteInputValues(value, this.chiplistInput);
    });
    const filteredSecondaryAllowedValuesAsMultiAutocompleteInputValues = [];
    for (const allowedValue of secondaryAllowedValuesAsMultiAutocompleteInputValues) {
      let add = true;
      for (const option of currentOptionValuesList) {
        const optionAsMultiAutocompleteInputValues = getMultiAutocompleteInputValues(option, this.chiplistInput);
        if (
          allowedValue.firstInputValue === optionAsMultiAutocompleteInputValues.firstInputValue &&
          allowedValue.secondInputValue === optionAsMultiAutocompleteInputValues.secondInputValue
        ) {
          add = false;
        }
      }
      if (add) {
        filteredSecondaryAllowedValuesAsMultiAutocompleteInputValues.push(allowedValue);
      }
    }
    return filteredSecondaryAllowedValuesAsMultiAutocompleteInputValues.map((value) => value.secondInputValue);
  }

  /**
   * Left table column. Only applicable when chiplist hasMultiAutocomplete is true.
   */
  private getMultiAutocompleteOptionColumn(): ReadonlyColumn<T> {
    const column = createSelectColumn(getChiplistDialogMultiAutocompleteColumnName());
    column.displayName = `Select Option From Dropdown`;
    column.getAllowedValues = (element: T) => {
      return this.getMultiAutocompleteOptionAllowedValues(element);
    };
    return column;
  }

  /**
   * Right table column
   */
  private getSelectedOptionsColumn(): ReadonlyColumn<T> {
    const column = createReadonlyColumn(this.chiplistInput?.name);
    column.displayName = `Current ${this.chiplistInput?.displayName}`;
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return `The list of currently selected ${this.chiplistInput?.displayName}`;
    };
    return column;
  }

  private getFilteredAllowedValuesList(multiAutocompleteFilterValue?: string): Array<any> {
    const allowedValuesList = this.chiplistInput.allowedValues;
    const filteredAllowedValuesList = [];
    if (!!this.chiplistInput.hasMultiAutocomplete) {
      for (const value of allowedValuesList) {
        const tempValueAsElement = this.createLeftTableElement(value, 0);
        // Check if all secondary options of the value already exist in the right table
        const multiAutocompleteOptionAllowedValues = this.getMultiAutocompleteOptionAllowedValues(tempValueAsElement);
        if (
          multiAutocompleteOptionAllowedValues.length === 0 ||
          (!!multiAutocompleteFilterValue && !multiAutocompleteOptionAllowedValues.includes(multiAutocompleteFilterValue))
        ) {
          continue;
        }
        filteredAllowedValuesList.push(value);
      }
    } else {
      const existingChipValuesListAsDisplayValues: Array<string> = this.element[this.chiplistInput.name].map((value) =>
        this.chiplistInput.getDisplayValue(value)
      );
      for (const value of allowedValuesList) {
        if (!existingChipValuesListAsDisplayValues.includes(this.chiplistInput.getDisplayValue(value))) {
          filteredAllowedValuesList.push(value);
        }
      }
    }
    return filteredAllowedValuesList;
  }

  private buildLeftTableData(multiAutocompleteFilterValue?: string): void {
    const dataForTable: Array<ChiplistExpandTableElement> = [];
    const filteredAllowedValuesList = this.getFilteredAllowedValuesList(multiAutocompleteFilterValue);
    for (let i = 0; i < filteredAllowedValuesList.length; i++) {
      const item = filteredAllowedValuesList[i];
      dataForTable.push(this.createLeftTableElement(item, i, multiAutocompleteFilterValue));
    }
    updateTableElements(this.leftTableData, dataForTable);
  }

  private createLeftTableElement(item: any, index: number, multiAutocompleteFilterValue?: string): ChiplistExpandTableElement {
    const data: ChiplistExpandTableElement = {
      [this.chiplistInput.name]: this.chiplistInput.getDisplayValue(item),
      value: item,
      secondary_option: '',
      ...getDefaultTableProperties(index),
    };
    if (!!multiAutocompleteFilterValue) {
      data.secondary_option = multiAutocompleteFilterValue;
    }
    return data;
  }

  private initializeLeftColumnDefs(): void {
    const columnList = [createSelectRowColumn(), this.getAvailableOptionColumn()];
    if (!!this.chiplistInput.hasMultiAutocomplete) {
      columnList.push(this.getMultiAutocompleteOptionColumn());
    }
    setColumnDefs(columnList, this.leftTableColumnDefs);
  }

  private buildRightTableData(): void {
    const dataForTable: Array<ChiplistExpandTableElement> = [];
    const list = this.element[this.chiplistInput.name];
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      dataForTable.push(this.createRightTableElement(item, i));
    }
    updateTableElements(this.rightTableData, dataForTable);
  }

  private createRightTableElement(item: any, index: number): ChiplistExpandTableElement {
    const data: ChiplistExpandTableElement = {
      [this.chiplistInput.name]: this.chiplistInput.getDisplayValue(item),
      value: item,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private initializeRightColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getSelectedOptionsColumn()], this.rightTableColumnDefs);
  }

  private getTableCheckedElements(tableLayoutComp: TableLayoutComponent<ChiplistExpandTableElement>): Array<ChiplistExpandTableElement> {
    return !!tableLayoutComp?.getFilteredCheckedElements() ? tableLayoutComp.getFilteredCheckedElements() : [];
  }

  private getTableUncheckedElements(tableLayoutComp: TableLayoutComponent<ChiplistExpandTableElement>): Array<ChiplistExpandTableElement> {
    return !!tableLayoutComp?.getUncheckedElements() ? tableLayoutComp.getUncheckedElements() : [];
  }

  private getSecondaryOptionsSelectedErrorMessage(): string | undefined {
    if (!this.chiplistInput.hasMultiAutocomplete) {
      return undefined;
    }
    const leftTableCheckedOptions = this.getTableCheckedElements(this.leftTableLayoutComp);
    for (const row of leftTableCheckedOptions) {
      const firstOptionValue = row.value;
      const secondOptionValue = row.secondary_option;
      if (!secondOptionValue) {
        return `No secondary option selected for ${firstOptionValue}. Please select an option from the dropdown list in the left table under "Select Option From Dropdown" and try again.`;
      }
    }
    return undefined;
  }

  private addToSelectedOptionsList(): void {
    const leftTableCheckedOptions = this.getTableCheckedElements(this.leftTableLayoutComp);
    for (const row of leftTableCheckedOptions) {
      let chipValue = '';
      if (!!this.chiplistInput.hasMultiAutocomplete) {
        const firstOptionValue = row.value;
        const secondOptionValue = row.secondary_option;
        if (!secondOptionValue) {
          this.chipListError = true;
          this.notificationService.error(
            `No secondary option selected for ${firstOptionValue}. Please select an option from the dropdown list in the left table under "Select Option From Dropdown" and try again.`
          );
          return;
        }
        chipValue = `${firstOptionValue}${this.chiplistInput.getMultiAutocompleteChipValueSeparator()}${secondOptionValue}`;
      } else {
        chipValue = this.chiplistInput.getDisplayValue(row.value);
      }
      addChipOnAutoSelectEvent(chipValue, this.element, this.chiplistInput);
    }
  }

  private getAtLeastOneChipRequiredErrorMessage(): string | undefined {
    const rightTableUncheckedOptions = this.getTableUncheckedElements(this.rightTableLayoutComp);
    const leftTableCheckedOptions = this.getTableCheckedElements(this.leftTableLayoutComp);
    const isAtLeastOneChipRequired = this.chiplistInput.requiredField(this.element, this.chiplistInput);
    if (isAtLeastOneChipRequired && rightTableUncheckedOptions.length === 0 && leftTableCheckedOptions.length === 0) {
      return `At least one entry is required for "Current ${this.chiplistInput?.displayName}"`;
    }
    return undefined;
  }

  private updateSelectedOptionsList(): void {
    const rightTableUncheckedOptions = this.getTableUncheckedElements(this.rightTableLayoutComp);
    this.element[this.chiplistInput.name] = rightTableUncheckedOptions.map((element) => element.value);
    this.addToSelectedOptionsList();
  }

  public onSwapClick(): void {
    this.chipListError = false;
    this.element.dirty = true;
    this.updateSelectedOptionsList();
    if (!this.chipListError) {
      this.updateTables();
    }
  }

  public disableSwapButton(): boolean {
    if (this.isFixedTable()) {
      return true;
    }
    const secondaryOptionsSelectedErrorMessage = this.getSecondaryOptionsSelectedErrorMessage();
    if (!!secondaryOptionsSelectedErrorMessage) {
      return true;
    }
    const atLeastOneChipRequiredErrorMessage = this.getAtLeastOneChipRequiredErrorMessage();
    if (!!atLeastOneChipRequiredErrorMessage) {
      return true;
    }
    const leftTableCheckedOptions = this.getTableCheckedElements(this.leftTableLayoutComp);
    const rightTableCheckedOptions = this.getTableCheckedElements(this.rightTableLayoutComp);
    if (leftTableCheckedOptions.length === 0 && rightTableCheckedOptions.length === 0) {
      return true;
    }
    return false;
  }

  public getDisabledSwapButtonTootltipText(): string {
    const secondaryOptionsSelectedErrorMessage = this.getSecondaryOptionsSelectedErrorMessage();
    if (!!secondaryOptionsSelectedErrorMessage) {
      return secondaryOptionsSelectedErrorMessage;
    }
    const atLeastOneChipRequiredErrorMessage = this.getAtLeastOneChipRequiredErrorMessage();
    if (!!atLeastOneChipRequiredErrorMessage) {
      return atLeastOneChipRequiredErrorMessage;
    }
    return `Select options from either table to move them from one table to the other`;
  }

  private async addChipOnInputEvent(value: string): Promise<void> {
    const valuesArray = getValuesFromInputEventValue(value);
    if (valuesArray.length === 0) {
      return;
    }
    const areInvalidValuesEnteredResult = await areInvalidValuesEntered(
      this.element,
      this.chiplistInput,
      valuesArray,
      this.notificationService
    );
    if (
      areDuplicateInputValuesEntered(valuesArray, this.notificationService) ||
      areNonexistantOptionValuesEntered(this.element, this.chiplistInput, valuesArray, this.notificationService) ||
      areInvalidValuesEnteredResult ||
      areExistingChipValuesEntered(this.element, this.chiplistInput, valuesArray, this.notificationService)
    ) {
      this.chiplistInput.inError = true;
      return;
    }
    // We need to make a copy of the element here otherwise the component will
    // freeze any arrays within the element after it has been modified once,
    // therefore, causing an error on the second update.
    const copyOfElement = cloneDeep(this.element);
    updateElementFromChiplistValuesAndDetechChanges(valuesArray, copyOfElement, this.chiplistInput, this.changeDetector);
    this.element[this.chiplistInput.name] = copyOfElement[this.chiplistInput.name];
    // Resets the input value so that the next chip to be entered starts as empty
    const freeFormOptionFormControl = this.freeFormChiplistForm.get('option_value');
    freeFormOptionFormControl.setValue('');
    onNewChipAdded(this.chiplistInput);
    // If we get here, everything is good, so we update the element and chiplistInput.
    this.chiplistInput.inError = false;
    this.chiplistInput.isFormInputDirty = false;
    this.updateTables();
  }

  public async addFreeFormValueToTable(): Promise<void> {
    const freeFormOptionValue = this.freeFormChiplistForm.get('option_value').value;
    await this.addChipOnInputEvent(freeFormOptionValue);
  }

  public selectOnRowClick(): boolean {
    if (this.chiplistInput.hasMultiAutocomplete || this.isFixedTable()) {
      return false;
    }
    return true;
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<ChiplistExpandTableElement>; element: ChiplistExpandTableElement }): void {
    if (!!params.value) {
      params.element.isChecked = true;
    } else {
      params.element.isChecked = false;
    }
    params.element.secondary_option = params.value;
  }

  private setEditableColumnDefs(): void {
    if (this.leftTableColumnDefs.size !== 0) {
      const leftTableSelectRowColumn = this.leftTableColumnDefs.get('selectRow');
      leftTableSelectRowColumn.showColumn = !this.isFixedTable();

      const secondaryOptionColumn = this.leftTableColumnDefs.get(getChiplistDialogMultiAutocompleteColumnName());
      if (!!secondaryOptionColumn) {
        secondaryOptionColumn.isEditable = !this.isFixedTable();
      }
    }
    if (this.rightTableColumnDefs.size !== 0) {
      const rightTableSelectRowColumn = this.rightTableColumnDefs.get('selectRow');
      rightTableSelectRowColumn.showColumn = !this.isFixedTable();
    }
  }

  public isFixedTable(): boolean {
    return !this.chiplistInput.isEditable;
  }

  public onUndoClick(): void {
    for (const key of Object.keys(this.element)) {
      this.element[key] = cloneDeep(this.originalElementCopy[key]);
    }
    this.chiplistInput.allowedValues = [...cloneDeep(this.originalChiplistInputAllowedValues)];
    this.updateTables();
  }

  public disableUndoButton(): boolean {
    return !this.element?.dirty;
  }

  private replaceTablesWithCopy(): void {
    const leftTableDataCopy = [...this.leftTableData];
    this.leftTableData = leftTableDataCopy;
    const rightTableDataCopy = [...this.rightTableData];
    this.rightTableData = rightTableDataCopy;
    this.changeDetector.detectChanges();
  }
}
