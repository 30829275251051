import {
  ApplicationService,
  Connector,
  ConnectorInstance,
  ConnectorsService,
  PerInstanceUpstreamStats,
  ResourceTypeEnum,
} from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AgentConnectorDynamicStatsWithConnectorId,
  getConnectorElementDynamicStatsDisplayValue,
  getFullConnectorDynamicStatsList$,
  startFullDetailedConnectorStatsPublish$,
} from '@app/core/api/connectors/stats-utils';
import { Subject, takeUntil } from 'rxjs';
import { ConnectorElement } from '../connector-admin/connector-admin.component';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { FilterManager } from '../filter/filter-manager';
import { FormLayoutComponent } from '../form-layout/form-layout.component';
import { convertResourceTypeToReadableString } from '../resource-utils';
import { getDefaultExpandableTableProperties } from '../table-layout-utils';
import { Column, createReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { capitalizeFirstLetter, replaceCharacterWithSpace, updateTableElements } from '../utils';
import { EventsService } from '@app/core/services/events.service';

export interface ConnectorStatsDialogData {
  connector: ConnectorElement;
  applicationServices: Array<ApplicationService>;
  orgId: string;
}

export interface ConnectorDetailedStatsElement extends TableElement, PerInstanceUpstreamStats {}

@Component({
  selector: 'portal-connector-stats-dialog',
  templateUrl: './connector-stats-dialog.component.html',
  styleUrls: ['./connector-stats-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorStatsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public connector: ConnectorElement;
  private orgId: string;
  private applicationServices: Array<ApplicationService>;
  private collectedSinceDate: string;
  private connectorIdToStatsMap: Map<string, AgentConnectorDynamicStatsWithConnectorId> = new Map();
  private connectorInstanceIdToConnectorInstanceMap: Map<string, ConnectorInstance> = new Map();
  private networkIdToNetworkMap: Map<string, ApplicationService> = new Map();
  public connectionOverviewFormColumnDefs: Map<string, Column<ConnectorElement | ConnectorDetailedStatsElement>> = new Map();
  public tlsOverviewFormColumnDefs: Map<string, Column<ConnectorElement | ConnectorDetailedStatsElement>> = new Map();
  public shareOverviewFormColumnDefs: Map<string, Column<ConnectorElement | ConnectorDetailedStatsElement>> = new Map();
  public httpOverviewFormColumnDefs: Map<string, Column<ConnectorElement | ConnectorDetailedStatsElement>> = new Map();
  public tableData: Array<ConnectorDetailedStatsElement> = [];
  public connectionStatsTableColumnDefs: Map<string, Column<ConnectorDetailedStatsElement>> = new Map();
  public tlsStatsTableColumnDefs: Map<string, Column<ConnectorDetailedStatsElement>> = new Map();
  public shareStatsTableColumnDefs: Map<string, Column<ConnectorDetailedStatsElement>> = new Map();
  public httpStatsTableColumnDefs: Map<string, Column<ConnectorDetailedStatsElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  private fetchCount = 0;

  @ViewChild('connectionOverviewFormLayout') private connectionOverviewFormLayout: FormLayoutComponent<ConnectorElement>;
  @ViewChild('tlsOverviewFormLayout') private tlsOverviewFormLayout: FormLayoutComponent<ConnectorElement>;
  @ViewChild('shareOverviewFormLayout') private shareOverviewFormLayout: FormLayoutComponent<ConnectorElement>;
  @ViewChild('httpOverviewFormLayout') private httpOverviewFormLayout: FormLayoutComponent<ConnectorElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConnectorStatsDialogData,
    private dialogRef: MatDialogRef<ConnectorStatsDialogComponent>,
    private connectorsService: ConnectorsService,
    private changeDetector: ChangeDetectorRef,
    private eventsService: EventsService
  ) {
    this.connector = data.connector;
    this.orgId = data.orgId;
    this.applicationServices = data.applicationServices;
  }

  public ngOnInit(): void {
    this.initializeFormColumnDefs();
    this.initializeTableColumnDefs();
    this.setConnectorInstanceMap();
    this.setApplicationServiceMap();
    this.startPublishingStats();
    this.getDynamicStatsForOverview();
    this.eventsService.SendEvent({
      event_id: 'connector-stats-dialog',
      event_type: 'click',
      category: 'navigation',
      sub_category: 'connector',
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private startPublishingStats(): void {
    this.collectedSinceDate = new Date().toISOString();
    const fullConnectorStatsPublish$ = startFullDetailedConnectorStatsPublish$(this.connectorsService, this.orgId, [
      this.connector.backingObject as Connector,
    ]);
    fullConnectorStatsPublish$.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {});
  }

  private setConnectorInstanceMap(): void {
    for (const instance of this.connector.status.instances) {
      this.connectorInstanceIdToConnectorInstanceMap.set(instance.metadata.id, instance);
    }
  }

  private setApplicationServiceMap(): void {
    for (const appService of this.applicationServices) {
      this.networkIdToNetworkMap.set(appService.id, appService);
    }
  }

  private getDynamicStatsForOverview(): void {
    const fullConnectorDynamicStatsList$ = getFullConnectorDynamicStatsList$(
      this.connectorsService,
      this.orgId,
      [this.connector.backingObject as Connector],
      this.collectedSinceDate
    );
    fullConnectorDynamicStatsList$.pipe(takeUntil(this.unsubscribe$)).subscribe((connectorStatsDataResp) => {
      this.fetchCount++;
      this.setConnectorStatsAndUpdateData(connectorStatsDataResp);
      this.updateTable(connectorStatsDataResp[0]?.stats.upstream_breakdown);
    });
  }

  private updateTable(perInstanceUpstreamStats: Array<PerInstanceUpstreamStats>): void {
    this.buildData(perInstanceUpstreamStats);
    this.replaceTableWithCopy();
  }

  private buildData(perInstanceUpstreamStats: Array<PerInstanceUpstreamStats>): void {
    if (!perInstanceUpstreamStats) {
      this.resetEmptyTable();
      return;
    }
    const data: Array<ConnectorDetailedStatsElement> = [];
    for (let i = 0; i < perInstanceUpstreamStats.length; i++) {
      const stats = perInstanceUpstreamStats[i];
      data.push(this.createConnectorDetailedStatsElement(stats, i));
    }
    updateTableElements(this.tableData, data);
  }

  private createConnectorDetailedStatsElement(stat: PerInstanceUpstreamStats, index: number): ConnectorDetailedStatsElement {
    const data: ConnectorDetailedStatsElement = {
      ...stat,
      ...getDefaultExpandableTableProperties(index, this.tableData),
    };
    return data;
  }

  private setConnectorStatsMap(connectorStatsDataResp: Array<AgentConnectorDynamicStatsWithConnectorId>): void {
    this.connectorIdToStatsMap.clear();
    for (const connectorStatsData of connectorStatsDataResp) {
      this.connectorIdToStatsMap.set(connectorStatsData.connector_id, connectorStatsData);
    }
  }

  private setConnectorStatsAndUpdateData(connectorStatsDataResp: Array<AgentConnectorDynamicStatsWithConnectorId>): void {
    this.setConnectorStatsMap(connectorStatsDataResp);
    // Trigger the forms to show the newly received stats:
    this.connectionOverviewFormLayout.triggerFormResetFromParentComponent();
    this.tlsOverviewFormLayout.triggerFormResetFromParentComponent();
    this.shareOverviewFormLayout.triggerFormResetFromParentComponent();
    this.httpOverviewFormLayout.triggerFormResetFromParentComponent();
  }

  /**
   * Form Column / Nested Table Column
   */
  private getConnectorStatsColumn(
    key: string,
    parentKey: string,
    removeConnectionsText = false
  ): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = createReadonlyColumn(key);
    column.displayName = this.getConnectorStatsColumnDisplayName(key, removeConnectionsText);
    column.getDisplayValue = (element: ConnectorElement | ConnectorDetailedStatsElement) => {
      const elementAsConnectorDetailedStatsElement = element as ConnectorDetailedStatsElement;
      if (!!elementAsConnectorDetailedStatsElement.upstream_stats) {
        const parentValue = elementAsConnectorDetailedStatsElement.upstream_stats[parentKey];
        const value: number | undefined = !!parentValue ? parentValue[key] : undefined;
        return value !== undefined ? value.toString() : 'Not Available';
      }
      const elementAsConnectorElement = element as ConnectorElement;
      const statsData = this.connectorIdToStatsMap.get(elementAsConnectorElement.id);
      const upsteamTotals = statsData?.stats?.upstream_totals;
      const parentValue = !!upsteamTotals ? upsteamTotals[parentKey] : undefined;
      const value: number | undefined = !!parentValue ? parentValue[key] : undefined;
      return getConnectorElementDynamicStatsDisplayValue(statsData, value, this.fetchCount);
    };
    column.inputSize = InputSize.XSMALL;
    return column;
  }

  private getConnectorStatsColumnDisplayName(name: string, removeConnectionsText = false): string {
    const formattedValue = removeConnectionsText ? name.replace('connections_', '') : name;
    return capitalizeFirstLetter(replaceCharacterWithSpace(formattedValue, '_')).replace('Tls ', 'TLS ');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsSuccessfulColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_successful', 'network_summary_stats');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsFailedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_failed', 'network_summary_stats');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsCurrentColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_current', 'network_detailed_stats');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsTimedoutColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_timedout', 'network_detailed_stats');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsResetColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_reset', 'network_detailed_stats');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsHostNotFoundColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_host_not_found', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Parent Table Column
   */
  private getConnectionsOtherFailureColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_other_failure', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Parent Table Column
   */
  private getBytesSentColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('bytes_sent', 'network_summary_stats');
  }

  /**
   * Form Column / Parent Table Column
   */
  private getBytesReceivedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('bytes_received', 'network_summary_stats');
  }

  /**
   * Form Column / Nested Table Column
   */
  private getConnectionsTlsValidationFailedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_tls_validation_failed', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Nested Table Column
   */
  private getConnectionsTlsProtocolFailureColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_tls_protocol_failure', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Nested Table Column
   */
  private getConnectionsTlsUnsupportedVersionColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_tls_unsupported_version', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Nested Table Column
   */
  private getConnectionsTlsRemoteErrorColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_tls_remote_error', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Nested Table Column
   */
  private getConnectionsTlsOtherErrorColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    return this.getConnectorStatsColumn('connections_tls_other_error', 'network_detailed_stats', true);
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsSuccessfulColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_successful', 'share_summary_stats');
    column.displayName = 'Share requests successful';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsFailedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_failed', 'share_summary_stats');
    column.displayName = 'Share requests failed';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsReadColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_read', 'share_detailed_stats');
    column.displayName = 'Share requests read';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsCreatedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_create', 'share_detailed_stats');
    column.displayName = 'Share requests created';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsModifiedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_modify', 'share_detailed_stats');
    column.displayName = 'Share requests modified';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsDeletedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_delete', 'share_detailed_stats');
    column.displayName = 'Share requests deleted';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsLockedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_lock', 'share_detailed_stats');
    column.displayName = 'Share requests locked';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareRequestsOtherColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_other', 'share_detailed_stats');
    column.displayName = 'Share requests other';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareFailureNotFoundColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('failure_not_found', 'share_detailed_stats');
    column.displayName = 'Share failure not found';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareFailurePermissionColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('failure_permission', 'share_detailed_stats');
    column.displayName = 'Share failure permission';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getShareFailureOtherColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('failure_other', 'share_detailed_stats');
    column.displayName = 'Share failure other';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpBytesReceivedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('http_bytes_received', 'http_summary_stats');
    column.displayName = 'HTTP bytes received';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpBytesSentColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('http_bytes_sent', 'http_summary_stats');
    column.displayName = 'HTTP bytes sent';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStartedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_started', 'http_summary_stats');
    column.displayName = 'HTTP requests started';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsFinishedColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_finished', 'http_summary_stats');
    column.displayName = 'HTTP requests finished';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStatus1xxColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_status_1xx', 'http_summary_stats');
    column.displayName = 'HTTP requests status 1xx';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStatus2xxColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_status_2xx', 'http_summary_stats');
    column.displayName = 'HTTP requests status 2xx';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStatus3xxColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_status_3xx', 'http_summary_stats');
    column.displayName = 'HTTP requests status 3xx';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStatus4xxColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_status_4xx', 'http_summary_stats');
    column.displayName = 'HTTP requests status 4xx';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStatus5xxColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_status_5xx', 'http_summary_stats');
    column.displayName = 'HTTP requests status 5xx';
    return column;
  }

  /**
   * Form Column / Nested Table Column
   */
  private getHttpRequestsStatusUnknownColumn(): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const column = this.getConnectorStatsColumn('requests_status_unknown', 'http_summary_stats');
    column.displayName = 'HTTP requests status unknown';
    return column;
  }

  private removeTextFromColumnDisplayName(
    textToRemove: string,
    column: Column<ConnectorElement | ConnectorDetailedStatsElement>
  ): Column<ConnectorElement | ConnectorDetailedStatsElement> {
    const formattedValue = textToRemove ? column.displayName.replace(textToRemove, '') : column.displayName;
    column.displayName = capitalizeFirstLetter(replaceCharacterWithSpace(formattedValue, '_'));
    return column;
  }

  private initializeConnectionOverviewFormColumnDefs(): void {
    setColumnDefs(
      [
        this.getConnectionsSuccessfulColumn(),
        this.getConnectionsFailedColumn(),
        this.getConnectionsCurrentColumn(),
        this.getConnectionsTimedoutColumn(),
        this.getConnectionsResetColumn(),
        this.getConnectionsHostNotFoundColumn(),
        this.getConnectionsOtherFailureColumn(),
        this.getBytesSentColumn(),
        this.getBytesReceivedColumn(),
      ],
      this.connectionOverviewFormColumnDefs
    );
  }

  private initializeTlsOverviewFormColumnDefs(): void {
    setColumnDefs(
      [
        this.getConnectionsTlsValidationFailedColumn(),
        this.getConnectionsTlsProtocolFailureColumn(),
        this.getConnectionsTlsUnsupportedVersionColumn(),
        this.getConnectionsTlsRemoteErrorColumn(),
        this.getConnectionsTlsOtherErrorColumn(),
      ],
      this.tlsOverviewFormColumnDefs
    );
  }

  private initializeShareOverviewFormColumnDefs(): void {
    setColumnDefs(
      [
        this.getShareRequestsSuccessfulColumn(),
        this.getShareRequestsFailedColumn(),
        this.getShareRequestsReadColumn(),
        this.getShareRequestsCreatedColumn(),
        this.getShareRequestsModifiedColumn(),
        this.getShareRequestsDeletedColumn(),
        this.getShareRequestsLockedColumn(),
        this.getShareRequestsOtherColumn(),
        this.getShareFailureNotFoundColumn(),
        this.getShareFailurePermissionColumn(),
        this.getShareFailureOtherColumn(),
      ],
      this.shareOverviewFormColumnDefs
    );
  }

  private initializeHttpOverviewFormColumnDefs(): void {
    setColumnDefs(
      [
        this.getHttpBytesReceivedColumn(),
        this.getHttpBytesSentColumn(),
        this.getHttpRequestsStartedColumn(),
        this.getHttpRequestsFinishedColumn(),
        this.getHttpRequestsStatus1xxColumn(),
        this.getHttpRequestsStatus2xxColumn(),
        this.getHttpRequestsStatus3xxColumn(),
        this.getHttpRequestsStatus4xxColumn(),
        this.getHttpRequestsStatus5xxColumn(),
        this.getHttpRequestsStatusUnknownColumn(),
      ],
      this.httpOverviewFormColumnDefs
    );
  }

  private initializeFormColumnDefs(): void {
    this.initializeConnectionOverviewFormColumnDefs();
    this.initializeTlsOverviewFormColumnDefs();
    this.initializeShareOverviewFormColumnDefs();
    this.initializeHttpOverviewFormColumnDefs();
  }

  /**
   * Parent Table Column
   */
  private getApplicationServiceColumn(): Column<ConnectorDetailedStatsElement> {
    const applicationServiceColumn = createReadonlyColumn('application_service_id');
    applicationServiceColumn.displayName = 'Resource (type)';
    applicationServiceColumn.getDisplayValue = (element: ConnectorDetailedStatsElement) => {
      const targetAppService = this.networkIdToNetworkMap.get(element.application_service_id);
      return !!targetAppService
        ? `${targetAppService.name} (${convertResourceTypeToReadableString(element.application_service_type as ResourceTypeEnum)})`
        : element.application_service_id;
    };
    applicationServiceColumn.inputSize = InputSize.MEDIUM;
    return applicationServiceColumn;
  }

  /**
   * Parent Table Column
   */
  private getInstanceColumn(): Column<ConnectorDetailedStatsElement> {
    const instanceColumn = createReadonlyColumn('connector_instance_id');
    instanceColumn.displayName = 'Instance';
    instanceColumn.getDisplayValue = (element: ConnectorDetailedStatsElement) => {
      return this.getInstanceDisplayName(element);
    };
    instanceColumn.inputSize = InputSize.SMALL;
    return instanceColumn;
  }

  private getInstanceDisplayName(element: ConnectorDetailedStatsElement): string {
    const targetInstance = this.connectorInstanceIdToConnectorInstanceMap.get(element.connector_instance_id);
    if (!targetInstance) {
      return element.connector_instance_id;
    }
    if (!!targetInstance.status?.stats?.system?.hostname) {
      return targetInstance.status.stats.system.hostname;
    }
    if (targetInstance.status?.instance_number !== undefined) {
      return targetInstance.status.instance_number.toString();
    }
    return element.connector_instance_id;
  }

  private initializeConnectionStatsTableColumnDefs(): void {
    setColumnDefs(
      [
        this.getApplicationServiceColumn(),
        this.getInstanceColumn(),
        this.getConnectionsSuccessfulColumn(),
        this.getConnectionsFailedColumn(),
        this.getConnectionsCurrentColumn(),
        this.getConnectionsTimedoutColumn(),
        this.getConnectionsResetColumn(),
        this.getConnectionsHostNotFoundColumn(),
        this.getConnectionsOtherFailureColumn(),
        this.getBytesSentColumn(),
        this.getBytesReceivedColumn(),
      ],
      this.connectionStatsTableColumnDefs
    );
  }

  private initializeTlsStatsTableColumnDefs(): void {
    setColumnDefs(
      [
        this.getApplicationServiceColumn(),
        this.getInstanceColumn(),
        this.removeTextFromColumnDisplayName('TLS ', this.getConnectionsTlsValidationFailedColumn()),
        this.removeTextFromColumnDisplayName('TLS ', this.getConnectionsTlsProtocolFailureColumn()),
        this.removeTextFromColumnDisplayName('TLS ', this.getConnectionsTlsUnsupportedVersionColumn()),
        this.removeTextFromColumnDisplayName('TLS ', this.getConnectionsTlsRemoteErrorColumn()),
        this.removeTextFromColumnDisplayName('TLS ', this.getConnectionsTlsOtherErrorColumn()),
      ],
      this.tlsStatsTableColumnDefs
    );
  }

  private initializeShareStatsTableColumnDefs(): void {
    setColumnDefs(
      [
        this.getApplicationServiceColumn(),
        this.getInstanceColumn(),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsSuccessfulColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsFailedColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsReadColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsCreatedColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsModifiedColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsDeletedColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsLockedColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareRequestsOtherColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareFailureNotFoundColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareFailurePermissionColumn()),
        this.removeTextFromColumnDisplayName('Share ', this.getShareFailureOtherColumn()),
      ],
      this.shareStatsTableColumnDefs
    );
  }

  private initializeHttpStatsTableColumnDefs(): void {
    setColumnDefs(
      [
        this.getApplicationServiceColumn(),
        this.getInstanceColumn(),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpBytesReceivedColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpBytesSentColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStartedColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsFinishedColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStatus1xxColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStatus2xxColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStatus3xxColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStatus4xxColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStatus5xxColumn()),
        this.removeTextFromColumnDisplayName('HTTP ', this.getHttpRequestsStatusUnknownColumn()),
      ],
      this.httpStatsTableColumnDefs
    );
  }

  private initializeTableColumnDefs(): void {
    this.initializeConnectionStatsTableColumnDefs();
    this.initializeTlsStatsTableColumnDefs();
    this.initializeShareStatsTableColumnDefs();
    this.initializeHttpStatsTableColumnDefs();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  public getShareStatsTableData(): Array<ConnectorDetailedStatsElement> {
    return this.tableData.filter(
      (element) => element.application_service_type === PerInstanceUpstreamStats.ApplicationServiceTypeEnum.fileshare
    );
  }

  public getNonShareStatsTableData(): Array<ConnectorDetailedStatsElement> {
    return this.tableData.filter(
      (element) => element.application_service_type !== PerInstanceUpstreamStats.ApplicationServiceTypeEnum.fileshare
    );
  }

  public getHttpStatsTableData(): Array<ConnectorDetailedStatsElement> {
    return this.tableData.filter((element) => {
      const assignmentsList = this.networkIdToNetworkMap.get(element.application_service_id)?.assignments;
      return (
        element.application_service_type === PerInstanceUpstreamStats.ApplicationServiceTypeEnum.ip &&
        !!assignmentsList &&
        assignmentsList.length !== 0
      );
    });
  }
}
