import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState, NotificationService } from '@app/core';
import { ResourceObject } from '@app/core/api/state-driven-crud/state-driven-crud';
import { IconStatus } from '@app/core/basic-collection-state';
import { ActionCreator, DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { Action as TypedAction } from '@ngrx/store';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceType } from '../resource-type.enum';
import { getFormattedResourceType } from '../resource-utils';
import { capitalizeFirstLetter, getIconURIFromResource, replaceCharacterWithSpace } from '../utils';

export interface ResourceLogoDialogData<DataType extends ResourceObject<string>> {
  resourceType: ResourceType;
  saveAction: ActionCreator<
    string,
    (props: { file: File; obj: DataType }) => {
      file: File;
      obj: DataType;
    } & TypedAction<string>
  >;
  resetStatusAction: ActionCreator<string, () => TypedAction<string>>;
  getResourceStateSelector: MemoizedSelector<object, DataType, DefaultProjectorFn<DataType>>;
  getIconStatusStateSelector: MemoizedSelector<object, IconStatus, DefaultProjectorFn<IconStatus>>;
}

@Component({
  selector: 'portal-resource-logo-dialog',
  templateUrl: './resource-logo-dialog.component.html',
  styleUrls: ['./resource-logo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceLogoDialogComponent<DataType extends ResourceObject<string>> implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public resource: DataType;
  public isUploading = false;
  public progressBarController: ProgressBarController = new ProgressBarController();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public replaceCharacterWithSpace = replaceCharacterWithSpace;
  public getFormattedResourceType = getFormattedResourceType;

  constructor(
    public dialogRef: MatDialogRef<ResourceLogoDialogComponent<DataType>>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceLogoDialogData<DataType>,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    const resourceState$ = this.store.pipe(select(this.data.getResourceStateSelector));
    const iconStatusState$ = this.store.pipe(select(this.data.getIconStatusStateSelector));
    combineLatest([resourceState$, iconStatusState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([resourceStateResp, iconStatusStateResp]) => {
        this.resource = resourceStateResp;
        this.handleProgressBarState(iconStatusStateResp);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getResourceIconUrl(): string | undefined {
    return !!getIconURIFromResource(this.resource) ? getIconURIFromResource(this.resource) : '';
  }

  public getIconActionText(): string {
    const icon = this.getResourceIconUrl();
    return !!icon ? 'modify the' : 'add an';
  }

  public uploadFile(file: File): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.isUploading = true;
    this.store.dispatch(this.data.saveAction({ file: file, obj: this.resource }));
  }

  public handleFileDropError(): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.resetProgressBar();
    this.notificationService.error('Cannot upload multiple files. Please select a single file for upload.');
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }

  private handleProgressBarState(iconStatus: IconStatus): void {
    if (iconStatus.saving_icon_file) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.initializeProgressBar();
      this.isUploading = true;
    }
    if (!iconStatus.icon_file_save_success && !iconStatus.icon_file_save_fail) {
      return;
    }
    if (iconStatus.icon_file_save_success) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.updateProgressBarValue(1, 1);
      this.delayHideProgressBar();
    }
    if (iconStatus.icon_file_save_fail) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.onFailedUpload();
    }
    this.store.dispatch(this.data.resetStatusAction());
    this.isUploading = false;
  }
}
