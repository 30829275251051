import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { AccessOption } from '@app/shared/components/access-option.enum';
import { ApplicationModel, ApplicationModelHosting, UpstreamService } from '@app/core/models/application/application-model';
import {
  canConfigureRemapRules,
  canConfigureRuntime,
  getFqdnAliasManualOptionDisplayValue,
  getProxyLocationOptionData,
  getRuntimeOptionData,
  getRuntimeOptionValue,
} from '../application-template-utils';
import {
  getDefaultApplicationModel,
  isAccessedOnPrem,
  isAccessedViaAgent,
  isAccessedViaVpn,
  isTlsEnabled,
  isUsingAgilicusRuntime,
  isUsingOwnRuntime,
} from '@app/core/models/application/application-model-utils';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { getRewriteCommonMediaTypesTooltip, getValuesArray, modifyDataOnFormBlur } from '../utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { Store } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { RuntimeOption } from '../runtime-option.enum';
import { getFile } from '../file-utils';
import { ApplicationStepperController } from '../application-stepper/application-stepper-controller';
import { MatStepper } from '@angular/material/stepper';
import { Connector, ConnectorSpec, Environment, Organisation } from '@agilicus/angular';
import { CorsTemplateType } from '../cors-template-type';
import { getCorsTemplateTooltipText, getCorsTemplateTypes } from '../cors-config/cors-config-utils';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { TlsType } from '../tls-type.enum';
import { getDisableHttp2CheckboxTooltip } from '@app/core/application-service-state/application-services-utils';
import { validateFqdnAliasValues } from '../validation-utils';
import { MatChipInputEvent } from '@angular/material/chips';
import { FilterChipOptions } from '../filter-chip-options';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { getPrimaryExternalName } from '@app/core/models/application/application-model-api-utils';

@Component({
  selector: 'portal-application-host-method-stepper',
  templateUrl: './application-host-method-stepper.component.html',
  styleUrls: ['./application-host-method-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationHostMethodStepperComponent implements OnChanges, OnInit {
  @Input() public currentApplicationModel: ApplicationModel;
  @Input() public rewriteMediaTypesFormGroup: UntypedFormGroup;
  @Input() public upstreamServiceFormGroup: UntypedFormGroup;
  @Input() public runtimeFormGroup: UntypedFormGroup;
  @Input() public connectorFormGroup: UntypedFormGroup;
  @Input() public corsTypeFormGroup: UntypedFormGroup;
  @Input() public http2FormGroup: UntypedFormGroup;
  @Input() public webApplicationFirewallFormGroup: UntypedFormGroup;
  @Input() public commonPathPrefixFormGroup: UntypedFormGroup;
  @Input() public fqdnAliasOptionFormGroup: UntypedFormGroup;
  @Input() public currentOrg: Organisation;

  // Workaround for angular stepper bug:
  // See: https://github.com/angular/components/issues/20923
  @Input() public accessOptionChanged = false;
  @Input() public connectors: Array<Connector> = [];
  @Output() public resetHostMethodOptionChanged = new EventEmitter<any>();
  public runtimeOptionData = getRuntimeOptionData();
  public isUsingOwnRuntime = isUsingOwnRuntime;
  public isUsingAgilicusRuntime = isUsingAgilicusRuntime;
  public isUploadingBundle = false;
  public proxyLocationOptionData = getProxyLocationOptionData();

  // This is required in order to reference the enums in the html template.
  public accessOption = AccessOption;

  public isAccessedOnPrem = isAccessedOnPrem;
  public isAccessedViaAgent = isAccessedViaAgent;
  public canConfigureRemapRules = canConfigureRemapRules;
  public canConfigureRuntime = canConfigureRuntime;
  public getRuntimeOptionValue = getRuntimeOptionValue;
  public isAccessedViaVpn = isAccessedViaVpn;
  public isTlsEnabled = isTlsEnabled;
  public getCorsTemplateTypes = getCorsTemplateTypes;
  public getCorsTemplateTooltipText = getCorsTemplateTooltipText;
  public getDisableHttp2CheckboxTooltip = getDisableHttp2CheckboxTooltip;
  public getFqdnAliasManualOptionDisplayValue = getFqdnAliasManualOptionDisplayValue;
  public expose_types = ['path_prefix', 'hostname'];
  public tlsTypes = TlsType;
  public authRulesProductGuideLink = `https://www.agilicus.com/anyx-guide/authentication-rules/`;
  public showWebAppFirewallStep = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public appStepperController: ApplicationStepperController = new ApplicationStepperController();

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  @ViewChild('hostMethodStepper') public stepper: MatStepper;

  constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.currentApplicationModel.hosting.on_prem.upstream_services.length <= 1) {
      this.currentApplicationModel.hosting.on_prem.upstream_services[0].expose_type = 'application';
      if (!this.currentApplicationModel.hosting.on_prem.upstream_services[0].protocol_config?.http_config?.disable_http2) {
        this.currentApplicationModel.hosting.on_prem.upstream_services[0].protocol_config = {
          http_config: {
            disable_http2: false,
          },
        };
      }
    }
  }

  public ngOnChanges(): void {
    // Workaround for angular stepper bug:
    // See: https://github.com/angular/components/issues/20923
    if (this.accessOptionChanged && !!this.stepper && !!this.stepper.steps && this.stepper.steps.length > 0) {
      this.stepper.selectedIndex = 0;
      this.resetHostMethodOptionChangedEvent();
    }
  }

  public changeShowWebAppFirewallStep(): void {
    if (!this.showWebAppFirewallStep) {
      this.onCheckboxChange('hasCors', false);
      this.onCheckboxChange('disable_http2', false);
      this.onProxyLocationOptionChange(false);
      this.currentApplicationModel.hosting.on_prem.cors.corsType = null;
      if (!!this.currentApplicationModel.authorization?.application_model_routing?.common_path_prefix) {
        this.currentApplicationModel.authorization.application_model_routing.common_path_prefix = '';
      }
      this.corsTypeFormGroup.get('hasCors').setValue(false);
      this.corsTypeFormGroup.get('corsType').setValue(null);
      this.http2FormGroup.get('disable_http2').setValue(false);
      this.webApplicationFirewallFormGroup.get('proxy_location').setValue(false);
      this.commonPathPrefixFormGroup.get('common_path_prefix').setValue('');
    }
  }

  public upstreamService(): UntypedFormArray {
    return this.upstreamServiceFormGroup.get('upstreamService') as UntypedFormArray;
  }

  public newUpstreamService(): UntypedFormGroup {
    const hostnameValidators = [this.customValidatorsService.hostnameOrIP4Validator(), Validators.maxLength(100)];
    const portValidators = [this.customValidatorsService.portValidator(), Validators.max(65535)];
    const ipValidators = [this.customValidatorsService.ip4AddressValidator()];
    if (isAccessedOnPrem(this.currentApplicationModel.hosting)) {
      hostnameValidators.push(Validators.required);
      portValidators.push(Validators.required);
    }
    if (isAccessedViaVpn(this.currentApplicationModel.hosting)) {
      ipValidators.push(Validators.required);
    }
    return this.fb.group({
      hostname: ['', hostnameValidators],
      port: ['', portValidators],
      ip_address: ['', ipValidators],
      tls_enabled: false,
      tls_verify: false,
      expose_type: 'application',
      hostname_alias: '',
      tls: 'no_tls',
    });
  }

  public addUpstreamService() {
    this.upstreamService().push(this.newUpstreamService());
    this.currentApplicationModel.hosting.on_prem.upstream_services.push({
      hostname: '',
      port: null,
      ip_address: '',
      tls_enabled: false,
      tls_verify: false,
      expose_type: 'application',
      hostname_alias: '',
      tls: 'no_tls',
    });
  }

  public removeUpstreamService(index: number) {
    if (index >= 0) {
      this.upstreamService().removeAt(index);
      this.currentApplicationModel.hosting.on_prem.upstream_services.splice(index, 1);
    }
  }

  public isExposeTypeHostName(formField: string, index: number): boolean {
    const selectedService = this.upstreamService().at(index).value;
    if (selectedService.expose_type === formField) {
      return true;
    }
    return false;
  }

  public onTlsValueChange(obj: UpstreamService, index: number): void {
    const selectedService = this.upstreamService().at(index).value;
    if (selectedService.tls === TlsType.Tls) {
      obj.tls_enabled = true;
      obj.tls_verify = false;
    } else if (selectedService.tls === TlsType.TlsAndVerify) {
      obj.tls_enabled = true;
      obj.tls_verify = true;
    } else {
      obj.tls_enabled = false;
      obj.tls_verify = false;
    }
  }

  public onUpstreamServiceFormBlur(formField: string, obj: UpstreamService, index: number): void {
    const selectedService = this.upstreamService().at(index).value;
    obj[formField] = selectedService[formField];
  }

  public onFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyAppStepperDataOnFormBlur.bind(this), obj);
  }

  public onCommonPathPrefixFormBlur(): void {
    const form = this.commonPathPrefixFormGroup;
    const formField = 'common_path_prefix';
    if (!this.currentApplicationModel.authorization) {
      this.currentApplicationModel.authorization = getDefaultApplicationModel().authorization;
    }
    modifyDataOnFormBlur(
      form,
      formField,
      this.modifyAppStepperDataOnFormBlur.bind(this),
      this.currentApplicationModel.authorization.application_model_routing
    );
  }

  private modifyAppStepperDataOnFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    let formValue = form.get(formField).value;
    if (formField === 'port') {
      formValue = parseInt(formValue, 10);
    }
    obj[formField] = formValue;
  }

  public modifyAppStepperDataOnFormSelectionChange<T extends object>(
    form: UntypedFormGroup,
    formField: string,
    obj: T,
    value: string
  ): void {
    if (form.controls[formField].invalid) {
      return;
    }
    obj[formField] = value;
  }

  public onRuntimeOptionChange(runtimeOption: RuntimeOption): void {
    if (runtimeOption === RuntimeOption.own) {
      this.appStepperController.handleOwnRuntimeSelection(this.currentApplicationModel);
    }
    if (runtimeOption === RuntimeOption.agilicus) {
      this.appStepperController.handleAgilicusRuntimeSelection(this.currentApplicationModel);
    }
  }

  public updateConnector(connectorName: string): void {
    this.currentApplicationModel.hosting.on_prem.connector_name = connectorName;
  }

  public onReadBundleFile(event: any): void {
    const file = getFile(event);
    if (!file) {
      return;
    }
    return;
    // TODO: add this functionality.
  }

  private resetHostMethodOptionChangedEvent(): void {
    this.resetHostMethodOptionChanged.emit();
  }

  private getRelevantConnectorType(appModelHosting: ApplicationModelHosting): ConnectorSpec.ConnectorTypeEnum | undefined {
    if (isAccessedViaAgent(appModelHosting)) {
      return ConnectorSpec.ConnectorTypeEnum.agent;
    }
    if (isAccessedViaVpn(appModelHosting)) {
      return ConnectorSpec.ConnectorTypeEnum.ipsec;
    }
    // TODO: will need to add logic for other types of connectors when they exist.
    return undefined;
  }

  public getFilteredConnectorsList(): Array<Connector> {
    const desiredConnectorType = this.getRelevantConnectorType(this.currentApplicationModel.hosting);
    return this.connectors.filter((connector) => connector.spec.connector_type === desiredConnectorType);
  }

  public onProxyLocationOptionChange(isBoxChecked: boolean): void {
    this.currentApplicationModel.hosting.on_prem.agent.proxy_location = Environment.ProxyLocationEnum.on_site;
    if (isBoxChecked) {
      this.currentApplicationModel.hosting.on_prem.agent.proxy_location = Environment.ProxyLocationEnum.in_cloud;
    }
  }

  public onCheckboxChange(param: string, isBoxChecked: boolean, index?: number): void {
    if (param === 'rewrite_common_media_types') {
      this.currentApplicationModel.hosting.on_prem.rewrite_common_media_types = isBoxChecked;
    }
    if (param === 'hasCors') {
      this.appStepperController.handleCorsOptionSelection(this.currentApplicationModel);
      this.currentApplicationModel.hosting.on_prem.cors.enabled = isBoxChecked;
    }
    if (param === 'disable_http2') {
      this.appStepperController.handleHttpConfigOptionSelection(this.currentApplicationModel);
      this.currentApplicationModel.hosting.on_prem.upstream_services[0].protocol_config.http_config.disable_http2 = isBoxChecked;
    }
  }

  public getRewriteCommonMediaTypesCustomTooltip(): string {
    return `${getRewriteCommonMediaTypesTooltip()} Only some default media types will be rewritten. You may later modify this list in the Define screen.`;
  }

  public getFullWebApplicationFirewallCheckboxTooltip(): string {
    return `This mode enables a web application firewall with per http transaction diagnostic logging and OWASP top-10 path processing.`;
  }

  public onCorsTypeChange(corsType: CorsTemplateType): void {
    this.currentApplicationModel.hosting.on_prem.cors.corsType = corsType;
  }

  public getStepperCorsTemplateDescriptiveText(corsType: CorsTemplateType): string {
    if (corsType === CorsTemplateType.API) {
      return 'is an API for other applications';
    }
    if (corsType === CorsTemplateType.SELF) {
      return 'is an API for itself';
    }
    return '';
  }

  public getCorsTemplateTypesForStepper(): Array<CorsTemplateType> {
    const corsTemplateTypes = getCorsTemplateTypes();
    return corsTemplateTypes.filter((type) => type !== CorsTemplateType.NONE);
  }

  public showNestedCorsOptions(): boolean {
    return !!this.corsTypeFormGroup.get('hasCors').value;
  }

  public isWebAppFirewallStepValid(): boolean {
    return (
      this.commonPathPrefixFormGroup.valid &&
      this.rewriteMediaTypesFormGroup.valid &&
      this.corsTypeFormGroup.valid &&
      this.http2FormGroup.valid &&
      this.webApplicationFirewallFormGroup.valid &&
      this.fqdnAliasOptionFormGroup.valid
    );
  }

  public getCorsCheckboxTooltip(): string {
    return `Check this box to enable the Cross-Origin Resource Sharing (CORS) policy of this Application. 
    This allows an application to control which origins may request content from it.`;
  }

  public preventDeleteUpstreamServiceOnEnter(event: any): void {
    event.preventDefault();
  }

  public getAuthClientsLink(): string {
    return `https://${window.location.hostname}/application-authentication-clients?${this.currentOrg.id}`;
  }

  public onFqdnAliasOptionChange(isChecked: boolean): void {
    if (!isChecked) {
      this.appStepperController.handleAutoFqdnAliasOptionSelection(this.currentApplicationModel);
    } else {
      this.appStepperController.handleManualFqdnAliasOptionSelection(this.currentApplicationModel);
    }
  }

  public removeFqdnAliasChip(fqdnAlias: string, fqdnAliases: Array<string>): void {
    const index = fqdnAliases.indexOf(fqdnAlias);
    if (index >= 0) {
      fqdnAliases.splice(index, 1);
    }
  }

  public async addFqdnAliasOnInputEvent(event: MatChipInputEvent): Promise<void> {
    const input = event.input;
    const validateFqdnAliasValuesResult = await validateFqdnAliasValues(
      event,
      this.currentApplicationModel.hosting.fqdnAliases.fqdnAliasList,
      this.notificationService,
      this.customValidatorsService
    );
    if (!validateFqdnAliasValuesResult.isValid) {
      if (!!validateFqdnAliasValuesResult.message) {
        this.notificationService.error(validateFqdnAliasValuesResult.message);
      }
      return;
    }
    const valuesArray = getValuesArray(event, this.currentApplicationModel.hosting.fqdnAliases.fqdnAliasList, this.notificationService);
    this.currentApplicationModel.hosting.fqdnAliases.fqdnAliasList = [
      ...this.currentApplicationModel.hosting.fqdnAliases.fqdnAliasList,
      ...valuesArray,
    ];
    // Resets the input value so that the next chip to be entered starts as empty
    if (input) {
      input.value = '';
    }
    this.changeDetector.detectChanges();
  }

  public showFqdnAliasListStep(): boolean {
    return this.fqdnAliasOptionFormGroup.get('selectedFqdnAlias').value === true;
  }

  public getHostnameAliasTooltipText(): string {
    let appNameText = '<applicationName>';
    let externalName = `${appNameText}.${this.currentOrg?.subdomain}`;
    if (!!this.currentApplicationModel.name) {
      appNameText = this.currentApplicationModel.name;
      externalName = getPrimaryExternalName(this.currentApplicationModel.name, this.currentOrg);
    }
    const orgName = this.currentOrg?.organisation || 'unknown';
    return `You may access each application with a well-known pattern, which is "${externalName}".
            When you set up, you either picked an Agilicus-supplied domain name,
            which might look like "${orgName}.agilicus.cloud",
            or, provided your own domain. If you supplied "cloud.example.com",
            then the application would be "${appNameText}.cloud.example.com". 
            However, you may wish to use a non-patterned name (e.g. www.example.com),
            and may make an alias here.`;
  }
}
