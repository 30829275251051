import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { NotificationService } from '../notifications/notification.service';
import { PatchErrorImpl } from '@agilicus/angular';
import { createDialogData } from '@app/shared/components/dialog-utils';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { ImplementsAction } from '../api/implements-action';
import { getCrudActionTypeName, getGuidFromObject } from '../api/state-driven-crud/state-driven-crud';
import { CrudActions, ResetListStateAction, ResetStateAction } from '../api/state-driven-crud/state-driven-crud.actions';
import { capitalizeFirstLetter } from '@app/shared/components/utils';
import { getProductLinkViaLocalStorage } from '@app/shared/components/product-guide-link/product-guide-link.utils';

const DEFAULT_ACTION_MSG = 'log out then log in again';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends ErrorHandler {
  constructor(private store: Store<AppState>, private notificationService: NotificationService, public errorDialog: MatDialog) {
    super();
  }

  private getConflictMessage(actionMsg: string = DEFAULT_ACTION_MSG): string {
    let message = 'There was a conflict when updating an object in the API. Somebody else likely modified it before you. ';
    if (actionMsg !== null) {
      message += `Please ${actionMsg} to refresh the data. `;
    }
    return message;
  }

  private postMessageHome(displayMessage, error: Error | HttpErrorResponse) {
    //
    // This posts errors back home.
    try {
      var stack = '';
      if (error instanceof HttpErrorResponse) {
        stack = error.error.stack;
      } else {
        stack = error.stack;
      }
      var org_id = undefined;
      try {
        org_id = window.localStorage.organisations.current_organisation.id;
      } catch (e) {}
      const url = '/log/';
      const data = {
        org_id: org_id,
        user: window.localStorage.email,
        version: environment.versions.app,
        origin: window.origin,
        severity: 'error',
        agent: 'admin',
        msg: displayMessage,
        error: {
          message: error.message,
          name: error.name,
          stack: stack,
        },
      };
      const config = {
        method: 'POST',
        mode: 'no-cors' as RequestMode,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      fetch(url, config);
    } catch (er) {}
  }

  public handleError(error: Error | HttpErrorResponse): void {
    let displayMessage = 'An error occurred: ' + error.message;

    if (!environment.production) {
      displayMessage += '. See console for details.';
    }

    if (error instanceof HttpErrorResponse && error.status === 409) {
      displayMessage = this.getConflictMessage();
    } else if (error.message === 'Request failed with status code 403') {
      displayMessage = 'You do not have sufficient permissions to view this page';
    }

    this.postMessageHome(displayMessage, error);

    if (
      displayMessage.includes(
        'iat is in the future' || 'iat was not provided' || 'nbf is in the future' || 'exp was not provided' || 'exp is in the past'
      )
    ) {
      const messagePrefix = 'Failed To Login';
      const message = `ERROR: Your local machine time is not properly setup or synced. This will cause incorrect behaviour in encryption and sign-in. Please enable your time sync service before continuing. You can check your browser time against real time at <a href="https://time.is/" target="_blank">https://time.is/</a>. For more information, see <a href="${getProductLinkViaLocalStorage(
        'https://www.agilicus.com/anyx-guide/time-synchronisation/'
      )}" target="_blank">${getProductLinkViaLocalStorage('https://www.agilicus.com/anyx-guide/time-synchronisation/')}</a>.`;
      const dialogData = createDialogData(messagePrefix, message);
      dialogData.informationDialog = true;
      dialogData.buttonText = { confirm: '', cancel: 'Close' };
      this.errorDialog.open(ConfirmationDialogComponent, {
        data: dialogData,
      });
    } else {
      this.notificationService.error(displayMessage);
      super.handleError(error);
    }
  }

  public getErrorMessageForUserDisplay(
    error: Error | PatchErrorImpl | HttpErrorResponse | undefined,
    baseMessage: string,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): string {
    if (error instanceof PatchErrorImpl) {
      baseMessage += ` ${this.getConflictMessage(actionMsg)}`;
    }
    if (error instanceof HttpErrorResponse && (!!error.error?.error_message || !!error.error?.detail)) {
      if (!!error.error?.error_message) {
        baseMessage += ` ${capitalizeFirstLetter(error.error?.error_message)}`;
      } else if (!!error.error?.detail) {
        baseMessage += ` ${capitalizeFirstLetter(error.error?.detail)}`;
      }
    }
    return baseMessage;
  }

  public handlePotentialConflict(error: Error | PatchErrorImpl, baseMessage: string, action: string = DEFAULT_ACTION_MSG): void {
    /*
    Disable this if you're testing. It leads to too much noise in the UT.
    if (!environment.production) {
      console.log(error);
    }
    */
    const errorMessageForUserDisplay = this.getErrorMessageForUserDisplay(error, baseMessage, action);
    this.notificationService.error(errorMessageForUserDisplay);
  }

  private getErrorMessageDialogText(
    error: Error | PatchErrorImpl | undefined,
    baseMessage: string,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): string {
    let message = `Warning: Your most recent changes have not been saved. `;
    message += this.getErrorMessageForUserDisplay(error, baseMessage, actionMsg);
    if (message.charAt(message.length - 1) !== '.') {
      message += '.';
    }
    message += ` You can try again or cancel the dialog to reset the data to the last successfully saved version.`;
    return message;
  }

  private getErrorMessageDialogData(
    error: Error | PatchErrorImpl | undefined,
    baseMessage: string,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): ConfirmationDialogData {
    const messagePrefix = `Failed to Save`;
    const message = this.getErrorMessageDialogText(error, baseMessage, actionMsg);
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.buttonText = {
      confirm: 'Confirm',
      cancel: 'Cancel',
    };
    return dialogData;
  }

  private getCrudStateErrorMessageDialogText(
    error: Error | PatchErrorImpl | undefined,
    baseMessage: string,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): string {
    let message = this.getErrorMessageDialogText(error, baseMessage, actionMsg);
    message += ` You can try again or cancel the dialog to reset the data to the last successfully saved version.`;
    return message;
  }

  private getCrudStateErrorMessageDialogData(
    error: Error | PatchErrorImpl | undefined,
    baseMessage: string,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): ConfirmationDialogData {
    const messagePrefix = `Failed to Save`;
    const message = this.getCrudStateErrorMessageDialogText(error, baseMessage, actionMsg);
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.buttonText = {
      confirm: 'Try Again',
      cancel: 'Cancel',
    };
    return dialogData;
  }

  public openErrorMessageDialog(
    error: Error | PatchErrorImpl,
    baseMessage: string,
    confirmFunc: () => void,
    cancelFunc: () => void,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): void {
    const dialogData = this.getErrorMessageDialogData(error, baseMessage, actionMsg);
    const dialogRef = this.errorDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        confirmFunc();
        return;
      }
      cancelFunc();
    });
  }

  public openCrudStateErrorMessageDialog<IDType>(
    error: Error | PatchErrorImpl,
    baseMessage: string,
    crudRegistyName: string,
    guid: IDType,
    confirmAction: ImplementsAction,
    crudAction: CrudActions,
    notifyUser: boolean,
    refreshData: boolean,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): void {
    const dialogData = this.getCrudStateErrorMessageDialogData(error, baseMessage, actionMsg);
    const dialogRef = this.errorDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.store.dispatch(
          new confirmAction(getCrudActionTypeName(crudAction, crudRegistyName), crudRegistyName, guid, notifyUser, refreshData)
        );
        return;
      }
      this.store.dispatch(
        new ResetStateAction<IDType>(getCrudActionTypeName(CrudActions.RESET_STATE, crudRegistyName), crudRegistyName, guid)
      );
    });
  }

  public openCrudStateMultipleErrorMessageDialog<DataType, IDType>(
    baseMessage: string,
    crudRegistyName: string,
    list: Array<DataType | IDType>,
    confirmAction: ImplementsAction,
    crudAction: CrudActions,
    notifyUser: boolean,
    refreshData: boolean,
    actionMsg: string = DEFAULT_ACTION_MSG
  ): void {
    const dialogData = this.getCrudStateErrorMessageDialogData(undefined, baseMessage, actionMsg);
    const dialogRef = this.errorDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.store.dispatch(
          new confirmAction(getCrudActionTypeName(crudAction, crudRegistyName), crudRegistyName, list, notifyUser, refreshData)
        );
        return;
      }
      const guidList: Array<IDType> = list.map((obj) => getGuidFromObject(obj));
      this.store.dispatch(
        new ResetListStateAction<IDType>(getCrudActionTypeName(CrudActions.RESET_LIST_STATE, crudRegistyName), crudRegistyName, guidList)
      );
    });
  }
}
