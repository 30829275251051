import { DesktopResourceSpec, DesktopConnectionInfo, NetworkServiceConfig, DesktopRemoteApp } from '@agilicus/angular';
import { getDefaultNetworkServiceConfig } from '../network-service-config/network-service-config-model-utils';
import { ResourceModel } from '../resource/resource-model';

export interface DesktopModel extends ResourceModel {
  name: string;
  address: string;
  desktop_type: DesktopResourceSpec.DesktopTypeEnum;
  connector_name: string;
  connection_info?: DesktopConnectionInfo;
  config: NetworkServiceConfig | undefined;
  allow_non_domain_joined_users: boolean | undefined;
  remote_app?: DesktopRemoteApp;
  is_demo?: boolean;
  vnc_username: boolean | undefined;
}

export function defaultDesktopModel(): DesktopModel {
  return {
    id: undefined,
    name: '',
    address: '',
    desktop_type: DesktopResourceSpec.DesktopTypeEnum.rdp,
    connector_name: '',
    connection_info: {
      vnc_connection_info: {
        password_authentication_info: {
          read_write_username: '',
          read_write_password: '',
          read_only_username: '',
          read_only_password: '',
        },
      },
    },
    config: getDefaultNetworkServiceConfig(),
    allow_non_domain_joined_users: undefined,
    is_demo: false,
    vnc_username: undefined,
  };
}
