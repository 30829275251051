<div class="dialog-container">
  <mat-dialog-content>
    <h2 class="message-prefix">Deleting organisation {{ orgName }}</h2>
    <div class="icon-container">
      <mat-icon class="warning-icon">warning</mat-icon>
    </div>
    <div class="message-container">
      <p>You are permanently deleting this organisation and all of the associated applications. Please enter your organisation name below to confirm.</p>
    </div>
    <form 
      [formGroup]="orgForm" 
      spellcheck="false"
      autocomplete="off"
    >
      <mat-form-field class="org-name-form">
        <mat-label>Confirm Organisation Name</mat-label>
        <input
          matInput
          type="text" 
          formControlName="orgName" 
        >
    </mat-form-field>
  </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" [disabled]="!orgForm.valid" (click)="onConfirmClick()" tabindex="1">Delete</button>
    <button mat-raised-button mat-dialog-close tabindex="-1">Cancel</button>
  </mat-dialog-actions>
</div>