<portal-page-info
  pageTitle="Audit Subsystem Records"
  [pageDescriptiveText]="pageDescriptiveText"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions" class="audit-container audit-subsystem-container">
  <div class="audit-form-container">
    <form
      class="audit-form"
      [formGroup]="auditForm"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="date-filters-container">
        <p class="audit-filter-field audit-filter-field-title">Start Time</p>

        <mat-form-field class="audit-filter-field">
          <mat-label>Choose start time</mat-label>
          <input
            matInput
            [max]="maxDate"
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            formControlName="dtFrom"
            [owlDateTimeFilter]="getStartDateMaxSetter(auditForm)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          />
          <owl-date-time #dt1></owl-date-time>
          <mat-error>Enter valid date and time</mat-error>
        </mat-form-field>


        <p class="audit-filter-field audit-filter-field-title">End Time</p>

        <mat-form-field class="audit-filter-field">
          <mat-label>Choose end time</mat-label>
          <input
            matInput
            [owlDateTime]="dt2"
            [owlDateTimeTrigger]="dt2"
            formControlName="dtTo"
            [owlDateTimeFilter]="getEndDateMinSetter(auditForm)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          />
          <owl-date-time #dt2></owl-date-time>
          <mat-error>Enter valid date and time</mat-error>
        </mat-form-field>
        </div>

      <div class="button-container">
        <button
          type="button"
          mat-raised-button
          color="primary"
          class="show-logs-button"
          [disabled]="!auditForm.valid"
          (click)="viewAudits()"
          matTooltip="Click to update the information in the audit table"
        >
          <fa-icon [icon]="['fas', 'search']"></fa-icon> VIEW AUDITS 
        </button>

        <portal-download-button
          class="download-button-container"
          [buttonDescription]="downloadButtonDescription"
          tooltipText="Click to download the audit information"
          (downloadFile)="downloadAuditData()"
        >
        </portal-download-button>
      </div>
    </form>
  </div>

  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [filterMenuOptions]="filterMenuOptions"
    [fixedTable]="fixedTable"
    [readonlyInputs]="readonlyInputs"
    [paginatorConfig]="paginatorConfig"
    [linkDataSource] = "linkDataSource"
    [useBackendFilter]="true"
    (filterBySearchParam)="filterBySearchParam()"
  >
  </portal-table-layout>

</div>
