<div class="dialog-container">
  <mat-dialog-content>
    <h2 class="title">Manage Proxies for Connector "{{ agentConnector.spec.name }}"</h2>
    <h4>Outer Connector</h4>
    <form
      [formGroup]="connectorProxiesFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="outer-proxy-container">
        <div
          class="outer-proxy-form-table-container"
          formArrayName="proxy_list"
        >
          <table class="outer-proxy-table-container">
            <div
              *ngFor="let proxy of getProxyListFormArray().controls; let i=index"
              [formGroupName]="i"
            >
              <tr>
                <td class="outer-connector-column">
                  <portal-connector-selection
                    [connectorFormGroup]="proxy"
                    [connectors]="getFilteredConnectorList()"
                    descriptiveText=""
                  >
                  </portal-connector-selection>
                </td>

                <td class="port-column">
                  <mat-form-field>
                    <mat-label>Port</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="bind_port"
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                      required
                    />
                    <mat-error>A valid port is required</mat-error>
                  </mat-form-field>
                  <mat-icon
                    class="help-icon"
                    [matTooltip]="getBindPortTooltipText()"
                  >
                    help_outline
                  </mat-icon>
                </td>

                <td class="hostname-column">
                  <mat-form-field>
                    <mat-label>Hostname/IP</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="bind_host"
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                      required
                    />
                    <mat-error>A valid hostname/IP is required</mat-error>
                  </mat-form-field>
                  <mat-icon
                    class="help-icon"
                    [matTooltip]="getBindHostTooltipText()"
                  >
                    help_outline
                  </mat-icon>
                </td>

                <td>
                  <div class="delete-proxy-button">
                    <button
                      mat-raised-button
                      color="warn"
                      (click)="removeProxy(i)"
                      (keydown.enter)="preventDeleteOnEnter($event)"
                    >
                      DELETE
                    </button>
                  </div>
                </td>
              </tr>
            </div>
          </table>
        </div>
        <div *ngIf="getProxyListFormArray().length === 0" class="add-proxy-button-container">
          <span class="buttonAsText" (click)="addProxy()">+ Add Outer Connector</span>
        </div>
      </div>
    </form>

    <div *ngIf="tableData.length === 0" class="inner-connector-container">
      <h4>Inner Connectors: None</h4>
    </div>

    <div *ngIf="tableData.length !== 0" class="inner-connector-container">
      <h4>Inner Connectors</h4>
      <portal-table-layout-readonly
        [tableData]="tableData"
        [columnDefs]="columnDefs"
        [hideFilter]="hideFilter"
      >
      </portal-table-layout-readonly>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirmClick()"
      [disabled]="disableSaveButton()"
      tabindex="1"
    >
      SAVE
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      CANCEL
    </button>
  </mat-dialog-actions>
</div>