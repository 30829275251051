<portal-table-filter
  [hideFilter]="hideFilter"
  [filterMenuOptions]="filterMenuOptions"
  [filterManager]="filterManager"
  [paramSpecificFilterManager]="paramSpecificFilterManager"
  [columnDefs]="columnDefs"
  [dataSource]="dataSource"
  [useBackendFilter]="useBackendFilter"
  [keyTabManager]="keyTabManager"
  (filterSearchDelay)="filterSearchDelayEventFunc($event)"
  (filterBySearchParam)="filterBySearchParamEventFunc()"
>
</portal-table-filter>

<button
  #rightClickMenuTrigger="matMenuTrigger"
  mat-icon-button
  [matMenuTriggerFor]="rightClickMenu"
  [id]="rightClickMenuId"
  style="height: 0; width: 0; padding: 0; position: fixed;"
>
</button>

<mat-menu
  #rightClickMenu="matMenu"
  xPosition="after"
  yPosition="below"
>
  <div class="button-container">
    <button
      mat-menu-item
      (click)="onCollapseColumn()"
    >
      Hide column
    </button>
  </div>
</mat-menu>

<div class="table-container mat-elevation-z8">
  <table
    mat-table
    #tableSort="matSort"
    [dataSource]="dataSource"
    matSort
    spellcheck="false"
    multiTemplateDataRows
  >
    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of getColumnDefs(columnDefs, columnTypes.ICON)"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        (contextmenu)="onHeaderRightClick($event, column)"
        [ngClass]="{
          'first-column': getFirstColumnName() === column.name,
          'last-column': getLastColumnName() === column.name
        }"
      >
        {{ column.displayName }}
        <mat-icon
          *ngIf="column.getHeaderTooltip"
          [matTooltip]="column.getHeaderTooltip()"
          class="column-tooltip-icon"
        >
          help_outline
        </mat-icon>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="icon-row"
        [ngClass]="{
          'first-column': getFirstColumnName() === column.name,
          'last-column': getLastColumnName() === column.name,
          'large-cell': column.inputSize === inputSize.LARGE
        }"
        matTooltip="{{ column.getTooltip(element) }}"
        [matTooltipDisabled]="column.getTooltip(element) === ''"
      >
        <mat-icon>{{ column.getDisplayValue(element, column) }}</mat-icon>
      </td>
    </ng-container>  

    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of getColumnDefs(columnDefs, columnTypes.READONLY)"
    >
      <ng-container *ngIf="column.showColumn">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name
          }"
        >
          {{ column.displayName }}
          <mat-icon
            *ngIf="column.getHeaderTooltip"
            [matTooltip]="column.getHeaderTooltip()"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="column.getTooltip(element, column)"
          [matTooltipDisabled]="column.getTooltip(element, column) === ''"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
          'last-column': getLastColumnName() === column.name,
            'large-cell': column.inputSize === inputSize.LARGE,
            'medium-cell': column.inputSize === inputSize.MEDIUM,
            'small-cell': column.inputSize === inputSize.SMALL,
            'date-cell': column.inputSize === inputSize.DATE,
            'warn-of-value': column.warnValue(element)
          }"
        >
          <mat-icon 
            *ngIf="column.hasIconPrefix"
            [ngClass]="{ 
              'success-color': column.getIconColor(element) === iconColor.success,
              'intermediate-color': column.getIconColor(element) === iconColor.intermediate,
              'warn-color': column.getIconColor(element) === iconColor.warn,
              'disabled-color': column.getIconColor(element) === iconColor.disabled
            }"
          >
            {{ column.getIconPrefix(element) }}
          </mat-icon>
          {{ column.getDisplayValue(element, column) }}
        </td>
      </ng-container>
    </ng-container>

    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of getColumnDefs(columnDefs, columnTypes.EXPAND)"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="{
          'first-column': getFirstColumnName() === column.name,
          'last-column': getLastColumnName() === column.name
        }"
      >
        {{ column.displayName }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'first-column': getFirstColumnName() === column.name,
          'last-column': getLastColumnName() === column.name
        }"
      >
        <a
          class="expand-icon"
          (click)="$event.stopPropagation(); toggleRowExpansionIcon(element, column)"
          [matTooltip]="column.getTooltip(element, column)"
          [matTooltipDisabled]="column.disableField(element)"
          [ngClass]="{ 'disable-expander': column.disableField(element) }"
        >
          <mat-icon *ngIf="!element.isRowExpanded">chevron_right</mat-icon>
          <mat-icon *ngIf="element.isRowExpanded">expand_more</mat-icon>
        </a>
      </td>
    </ng-container>

    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of getColumnDefs(columnDefs, columnTypes.COLLAPSED)"
    >
      <ng-container *ngIf="column.showColumn">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="collapsed-column-header"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name
          }"
        >
          <span class="expand-column-icon" (click)="onExpandColumn(column)" matTooltip="Click to expand hidden columns">
            <mat-icon style="margin-right: -15px">
              arrow_left
            </mat-icon>
            <mat-icon>
              arrow_right
            </mat-icon>
          </span>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="collapsed-column-data"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name
          }"
        >
        </td>
      </ng-container>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" *ngIf="!!isNestedTable">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="getDisplayedColumnNames(columnDefs).length"
        class="expanded-cell"
      >
        <div
          class="expanded-element-detail"
          [ngClass]="{ 'expanded-element-detail-flex': !element.nestedFormColumnDefs }"
          [@detailExpand]="element?.index === expandedElementId ? 'expanded' : 'collapsed'"
        >
          <div class="inner-form" *ngIf="!!element.nestedFormColumnDefs">
            <portal-form-layout
              #nestedFormLayout
              [columnDefs]="element.nestedFormColumnDefs"
              [element]="element"
            >
            </portal-form-layout>
          </div>
          <div class="inner-table mat-elevation-z8" *ngIf="expandedElementId !== undefined && expandedElementId !== null && !!element.expandedData">
            <portal-table-layout-readonly
              #nestedTable
              [tableData]="element.expandedData.nestedTableData"
              [columnDefs]="element.expandedData.nestedColumnDefs"
              [filterManager]="filterManager"
              [keyTabManager]="keyTabManager"
              [hideFilter]="!!element.expandedData.hideNestedFilter"
              [rightClickMenuId]="rightClickMenuIdNested"
              (filterSearchDelay)="filterSearchDelayEventFunc($event)"
              (filterBySearchParam)="filterBySearchParamEventFunc($event)"
              >
            </portal-table-layout-readonly>
          </div>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="getDisplayedColumnNames(columnDefs); sticky: true"
    >
    </tr>

    <div *ngIf="!isNestedTable">
      <tr
        tabindex="0"
        mat-row
        *matRowDef="let row; columns: getDisplayedColumnNames(columnDefs)"
        class="alternate-row-color"
        [hidden]="hideRow(row)"
      >
      </tr>
    </div>

    <div *ngIf="!!isNestedTable">
      <tr
        tabindex="0"
        mat-row
        *matRowDef="let row; columns: getDisplayedColumnNames(columnDefs)"
        [hidden]="hideRow(row)"
        [ngClass]="{
          'expandable-row': isExpandableRow(row),
          'alternate-row-color-parent-table': isEvenRow(row)
        }"
        [class.expanded-row]="expandedElementId === row?.index"
        (click)="toggleRowOnRowClick(row)"
      >
      </tr>

      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="expanded-detail-row"
      >
      </tr>
    </div>
  </table>
  <mat-paginator
    [hidden]="!usePaginator(dataSource)"
    [disabled]="!usePaginator(dataSource)"
    [pageSize]="paginatorPageSize"
    [pageSizeOptions]="useSharedPageSizeOptions(dataSource)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>