import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  catchError,
  combineLatest,
  concat,
  concatMap,
  delay,
  EMPTY,
  forkJoin,
  map,
  merge,
  Observable,
  Observer,
  of,
  reduce,
  Subject,
  takeUntil,
} from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState, NotificationService } from '@app/core';
import { select, Store } from '@ngrx/store';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { createNewResource$, getResouces, updateExistingResource$ } from '@app/core/api/resources/resources-api-utils';
import { selectConnectorList } from '@app/core/connector-state/connector.selectors';
import {
  ApplicationService,
  ApplicationServicesService,
  Connector,
  Resource,
  ResourceMember,
  ResourcesService,
  ResourceTypeEnum,
} from '@agilicus/angular';
import { getEmptyStringIfUnset } from '../utils';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { Papa } from 'ngx-papaparse';
import { getFile, uploadIsCsv } from '../file-utils';
import { addRowNumbers, CsvData, removeInvalidColumns, removeWhitespace, UploadStatus } from '../csv-utils';
import { NetworkElement } from '../network-overview/network-overview.component';
import {
  createNewApplicationServiceAndUpdateIfAlreadyExists$,
  setNetworkFromNetworkElement,
} from '@app/core/application-service-state/application-services-utils';
import { isValidFQDNSingleLabel, isValidHostnameOrIp4, isValidIp4, isValidPortRangeList } from '../validation-utils';

export interface NetworkCsvDialogData {
  orgId: string;
}

export interface NetworkCsvElement {
  connector_name: string;
  resource_group_name: string;
}

export interface UploadedNetwork extends NetworkElement, CsvData {
  network?: ApplicationService;
}

@Component({
  selector: 'portal-network-csv-dialog',
  templateUrl: './network-csv-dialog.component.html',
  styleUrls: ['./network-csv-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkCsvDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public connectors: Array<Connector>;
  public resourceGroups: Array<Resource> = [];
  private resourceGroupIdToResourceGroupMap: Map<string, Resource> = new Map();
  private resourceGroupNameToResourceGroupMap: Map<string, Resource> = new Map();
  private connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public allForms: UntypedFormGroup;
  public connectorFormGroup: UntypedFormGroup;
  public resourceGroupFormGroup: UntypedFormGroup;
  public networkCsvElement: NetworkCsvElement = {
    connector_name: '',
    resource_group_name: '',
  };
  public networkCsvList: Array<UploadedNetwork> = [];
  public isUploading = false;
  public buttonDescription = 'NETWORKS';
  public uploadButtonTooltipText = 'Upload a CSV file in format "name", "hostname", "ports", "override_ip"';
  public addDisabled = false;
  public validHeaders = new Set(['name', 'hostname', 'ports', 'override_ip']);
  public replaceMembersFalseValue = false;
  public replaceMembersTrueValue = true;
  public replaceMembersFalseText = 'be added to the existing members of the above resource group';
  public replaceMembersTrueText = 'replace the existing members of the above resource group';

  public getRelaceMembersTextFromValue(): string {
    const replaceAllInGroupValue = this.getReplaceAllInGroupValueFromForm();
    if (replaceAllInGroupValue) {
      return this.replaceMembersTrueText;
    }
    return this.replaceMembersFalseText;
  }

  public progressBarController: ProgressBarController = new ProgressBarController();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: NetworkCsvDialogData,
    public dialogRef: MatDialogRef<NetworkCsvDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private resourcesService: ResourcesService,
    private applicationServicesService: ApplicationServicesService,
    private papa: Papa
  ) {
    if (data) {
      this.orgId = data.orgId;
    }
  }

  public ngOnInit(): void {
    this.setAllFormsData();
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const allResources$ = getResouces(this.resourcesService, this.orgId, undefined, [ResourceTypeEnum.service_forwarder]);
    combineLatest([connectorListState$, allResources$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([connectorListStateResp, allResourcesResp]) => {
        this.connectors = connectorListStateResp;
        const allResources = !!allResourcesResp ? allResourcesResp : [];
        this.setResourceGroupsMaps();
        this.setConnectorMaps();
        this.resourceGroups = allResources.filter((resource) => resource.spec.resource_type === ResourceTypeEnum.group);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.changeDetector.detach();
  }

  private setResourceGroupsMaps(): void {
    this.resourceGroupIdToResourceGroupMap.clear();
    this.resourceGroupNameToResourceGroupMap.clear();
    for (const resourceGroup of this.resourceGroups) {
      this.resourceGroupIdToResourceGroupMap.set(resourceGroup.metadata.id, resourceGroup);
      this.resourceGroupNameToResourceGroupMap.set(resourceGroup.spec.name, resourceGroup);
    }
  }

  private setConnectorMaps(): void {
    this.connectorNameToConnectorMap.clear();
    for (const connector of this.connectors) {
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private initializeConnectorFormGroup(): void {
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: [getEmptyStringIfUnset(this.networkCsvElement?.connector_name), [Validators.required]],
    });
  }

  private getConnectorNameFromForm(): string | undefined {
    return this.connectorFormGroup.get('connector_name').value;
  }

  private initializeResourceGroupFormGroup(): void {
    this.resourceGroupFormGroup = this.formBuilder.group({
      resource_group_name: [getEmptyStringIfUnset(this.networkCsvElement?.resource_group_name), [Validators.required]],
      replace_all_in_group: false,
    });
  }

  private getResourceGroupNameFromForm(): string | undefined {
    return this.resourceGroupFormGroup.get('resource_group_name').value;
  }

  private getReplaceAllInGroupValueFromForm(): boolean {
    return this.resourceGroupFormGroup.get('replace_all_in_group').value;
  }

  private initializeFormGroups(): void {
    this.initializeConnectorFormGroup();
    this.initializeResourceGroupFormGroup();
  }

  private setAllFormsData(): void {
    this.initializeFormGroups();
    this.allForms = this.formBuilder.group({
      connectorFormGroup: this.connectorFormGroup,
    });
    this.changeDetector.detectChanges();
  }

  public updateConnector(connectorName: string): void {
    this.networkCsvElement.connector_name = connectorName;
  }

  public updateSelectedResourceGroup(value: string): void {
    if (this.resourceGroupFormGroup.controls.resource_group_name.invalid) {
      return;
    }
    this.networkCsvElement.resource_group_name = value;
  }

  public onReadNetworks(event: any): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.resetProgressBar();
    const readNetworksObservable$ = this.readNetworks(event);
    if (readNetworksObservable$ === undefined) {
      return;
    }
    readNetworksObservable$.forEach((next) => {});
  }

  /**
   * Parses the csv into JSON to be submitted to the api via http requests.
   */
  public readNetworks(event: any): Observable<unknown> {
    const uploadContent = getFile(event);
    if (uploadContent === undefined) {
      return EMPTY;
    }
    if (!uploadIsCsv(uploadContent)) {
      this.notificationService.error(
        'This file does not appear to be in CSV format. Please upload a CSV file or rename the file with ".csv" extension.' +
          ' File is of type "' +
          uploadContent.type +
          '". Expected type "text/csv".'
      );
      return EMPTY;
    }
    const papaObservable$ = new Observable((observer) => {
      this.papa.parse(uploadContent, {
        complete: (result) => {
          if (result.data.length === 0) {
            this.notificationService.error('No networks to upload');
            observer.complete();
            this.isUploading = false;
            return;
          }
          this.parseNetworksToUpload(result.data, observer);
        },
        error: (error) => {
          this.notificationService.error('Failed to read file. ' + error.message);
        },
        header: true,
        transformHeader: (result) => {
          const strArr = result.trim().split(/[\ ]+/);
          const newHeader = strArr.join('_').toLowerCase();
          return newHeader;
        },
        skipEmptyLines: 'greedy',
      });
    });
    return papaObservable$;
  }

  /**
   * Searches for invalid/duplicated users, as well as non-existent groups.
   * If any are found, the user is notified and the upload is canceled.
   * If all users are valid they are uploaded via http requests.
   */
  private parseNetworksToUpload(csvParseResult: Array<UploadedNetwork>, observer: Observer<UploadStatus>): void {
    const updatedCsvParseResult = removeInvalidColumns(csvParseResult, this.validHeaders);
    addRowNumbers(updatedCsvParseResult);
    if (this.checkForUndefinedEntries(updatedCsvParseResult)) {
      observer.complete();
      return;
    }
    for (const network of updatedCsvParseResult) {
      removeWhitespace(network);
    }
    const validNetworks: Array<UploadedNetwork> = [];
    const invalidNetworks: Array<UploadedNetwork> = [];
    this.setValidAndInvalidNetworks(updatedCsvParseResult, validNetworks, invalidNetworks);
    const duplicatedNetworks = this.getDuplicatedNetworks(validNetworks);
    if (invalidNetworks.length === 0 && duplicatedNetworks.length === 0) {
      this.uploadNetworks(updatedCsvParseResult, observer);
    } else {
      this.createUploadErrorNotification(invalidNetworks, duplicatedNetworks);
      observer.complete();
      this.isUploading = false;
    }
  }

  private getDuplicatedNetworks(validNetworks: Array<UploadedNetwork>): Array<UploadedNetwork> {
    const duplicatedNetworks: Array<UploadedNetwork> = [];
    for (const network of validNetworks) {
      if (this.checkDuplicatedNetwork(network, validNetworks)) {
        duplicatedNetworks.push(network);
      }
    }
    return duplicatedNetworks;
  }

  private checkDuplicatedNetwork(targetNetwork: UploadedNetwork, validNetworks: Array<UploadedNetwork>): boolean {
    let count = 0;
    for (const network of validNetworks) {
      if (network.name.toLowerCase() === targetNetwork.name.toLowerCase()) {
        count++;
      }
    }
    if (count > 1) {
      return true;
    }
    return false;
  }

  private checkForUndefinedEntries(csvParseResult: Array<UploadedNetwork>): boolean {
    for (const network of csvParseResult) {
      if (network.name === undefined || network.hostname === undefined || network.ports === undefined) {
        this.notificationService.error('The following CSV row is missing a field: ' + network.csvRowNumber);
        return true;
      }
    }
    return false;
  }

  private createUploadErrorNotification(invalidNetworks: Array<UploadedNetwork>, duplicatedNetworks: Array<UploadedNetwork>): void {
    let message = '';
    if (invalidNetworks.length > 0) {
      message += 'The following CSV rows are invalid: "' + this.createNotificationStringFromObject(invalidNetworks, 'csvRowNumber') + '" ';
    }
    if (duplicatedNetworks.length > 0) {
      message +=
        'The following CSV rows contain duplicated networks: "' +
        this.createNotificationStringFromObject(duplicatedNetworks, 'csvRowNumber') +
        '" ';
    }
    this.notificationService.error(message);
  }

  private createNotificationStringFromObject(networks: Array<UploadedNetwork>, displayValue: string): string {
    return networks.map((network) => network[displayValue]).join('; ');
  }

  private setValidAndInvalidNetworks(
    csvParseResult: Array<UploadedNetwork>,
    validNetworks: Array<UploadedNetwork>,
    invalidNetworks: Array<UploadedNetwork>
  ): void {
    for (const network of csvParseResult) {
      if (this.checkValidUpload(network)) {
        this.setUploadedNetworkProperties(network);
        validNetworks.push(network);
      } else {
        invalidNetworks.push(network);
      }
    }
  }

  private checkValidUpload(network: UploadedNetwork): boolean {
    if (!isValidFQDNSingleLabel(network.name)) {
      return false;
    }
    if (!isValidHostnameOrIp4(network.hostname)) {
      return false;
    }
    if (!isValidPortRangeList(network.ports)) {
      return false;
    }
    if (!!network.override_ip && !isValidIp4(network.override_ip)) {
      return false;
    }
    return true;
  }

  private setUploadedNetworkProperties(network: UploadedNetwork): void {
    network.org_id = this.orgId;
  }

  private getResourceGroupFromForm(): Resource | undefined {
    const selectedResourceGroupName = this.getResourceGroupNameFromForm();
    const targetResourceGroup = this.resourceGroupNameToResourceGroupMap.get(selectedResourceGroupName);
    return targetResourceGroup;
  }

  private updateNetworkCsvListProperties(networks: Array<UploadedNetwork>): void {
    const selectedConnectorName = this.getConnectorNameFromForm();
    const targetConnector = this.connectorNameToConnectorMap.get(selectedConnectorName);
    for (const network of networks) {
      network.connector_name = selectedConnectorName;
      network.connector_id = targetConnector.metadata.id;
      setNetworkFromNetworkElement(network);
    }
  }

  private uploadNetworks(networks: Array<UploadedNetwork>, observer: Observer<UploadStatus>): void {
    this.isUploading = true;
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.changeDetector.detectChanges();
    this.updateNetworkCsvListProperties(networks);
    const observablesArray$ = this.prepareNetworksToUpload$(networks, observer);
    this.joinUserObservables(observablesArray$, observer);
  }

  private prepareNetworksToUpload$(
    networks: Array<UploadedNetwork>,
    observer: Observer<UploadStatus>
  ): Array<Observable<Array<UploadedNetwork>>> {
    const totalNetworksToUpload = networks.length;
    let uploadsComplete = 0;
    const observablesArray = [];
    for (const network of networks) {
      observablesArray.push(
        createNewApplicationServiceAndUpdateIfAlreadyExists$(this.applicationServicesService, network).pipe(
          map((resp) => {
            network.network = resp;
            uploadsComplete++;
            // Need to reassign the progressBarController in order to
            // trigger the update in the template.
            this.progressBarController = this.progressBarController.updateProgressBarValue(totalNetworksToUpload, uploadsComplete);
            this.changeDetector.detectChanges();
            return network;
          }),
          catchError((err) => {
            return of({ network, error: err });
          })
        )
      );
    }
    const forkJoinArray: Array<Observable<Array<UploadedNetwork>>> = [];
    let accumulatorArray: Array<Observable<UploadedNetwork>> = [];
    for (const obs of observablesArray) {
      accumulatorArray.push(obs);
      if (accumulatorArray.length % 5 == 0) {
        forkJoinArray.push(forkJoin(accumulatorArray));
        accumulatorArray = [];
      }
    }
    if (accumulatorArray.length > 0) {
      forkJoinArray.push(forkJoin(accumulatorArray));
    }
    return forkJoinArray;
  }

  private joinUserObservables(observablesArray: Array<Observable<Array<UploadedNetwork>>>, observer: Observer<UploadStatus>): void {
    concat(...observablesArray)
      .pipe(
        reduce((services, service) => services.concat(service), []),
        concatMap((createdNetworksResp) => {
          const resourceMembersToAdd = this.getResourceMembersFromNetworksList(
            createdNetworksResp.map((uploadedNetwork) => uploadedNetwork.network)
          );
          const resourceGroup = this.getResourceGroupFromForm();
          let resourceGroup$: Observable<Resource | undefined> = of(undefined);
          if (!resourceGroup) {
            const resourceGroupToCreate: Resource = {
              spec: {
                name: this.getResourceGroupNameFromForm(),
                resource_members: resourceMembersToAdd,
                resource_type: ResourceTypeEnum.group,
                org_id: this.orgId,
              },
            };
            // Need to create a new resource group:
            resourceGroup$ = createNewResource$(this.resourcesService, resourceGroupToCreate);
          } else {
            const replaceAllInGroup = this.getReplaceAllInGroupValueFromForm();
            if (replaceAllInGroup) {
              resourceGroup.spec.resource_members = [...resourceMembersToAdd];
            } else {
              for (const resourceMember of resourceMembersToAdd) {
                // Add the resource member to the existing list
                resourceGroup.spec.resource_members.push(resourceMember);
              }
            }
            // Need to add networks to existing resource group:
            resourceGroup$ = updateExistingResource$(this.resourcesService, resourceGroup);
          }
          return resourceGroup$.pipe(map((resourceGroupResp) => createdNetworksResp));
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (respArray) => {
          const failedUploads = [];
          respArray.forEach((resp) => {
            // If the response is not an error it will not have an 'error' property
            // and, therefore, resp.error will be undefined for successful responses
            if (resp.error !== undefined) {
              observer.next(UploadStatus.FAIL);
              failedUploads.push(resp.network);
            } else {
              observer.next(UploadStatus.PASS);
            }
          });
          if (failedUploads.length === 0) {
            this.notificationService.success('All networks successfully uploaded');
            this.delayHideProgressBar();
            setTimeout(() => {
              this.dialogRef.close(true);
            }, 2000);
          } else {
            this.notificationService.error(
              'The following networks failed to upload: "' + this.createNotificationStringFromObject(failedUploads, 'email') + '"'
            );
            // Stop buffering when uploads fail.
            // Need to reassign the progressBarController in order to
            // trigger the update in the template.
            this.progressBarController = this.progressBarController.onFailedUpload();
            this.isUploading = false;
            this.changeDetector.detectChanges();
          }
        },
        (errorResp) => {
          this.notificationService.error('There was an error uploading the file. One or more network names may already exist.');
          this.progressBarController = this.progressBarController.onFailedUpload();
          this.isUploading = false;
          this.changeDetector.detectChanges();
        },
        () => {
          observer.complete();
          this.changeDetector.detectChanges();
        }
      );
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }

  private getResourceMembersFromNetworksList(networks: Array<ApplicationService>): Array<ResourceMember> {
    return networks.map((network) => {
      return { id: network.id };
    });
  }
}
