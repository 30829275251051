import { Component, ChangeDetectionStrategy, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { FilterChipOptions } from '../filter-chip-options';
import { FilterType } from '../filter-type.enum';
import { FilterManager, FilterOption } from '../filter/filter-manager';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ParamSpecificFilterManager, ParamSpecificSearchOption } from '../param-specific-filter-manager/param-specific-filter-manager';
import { Column } from '../table-layout/column-definitions';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { capitalizeFirstLetter } from '../utils';
import { TableElement } from '../table-layout/table-element';
import { MatSelect } from '@angular/material/select';

export enum FilterMenuOptionType {
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  text = 'text',
}

export interface FilterMenuOption {
  name: string;
  displayName: string;
  icon: string;
  type: FilterMenuOptionType;
  allowedValues?: () => Array<string>;
  placeholder?: string | undefined;
}

@Component({
  selector: 'portal-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFilterComponent<T extends TableElement> implements OnChanges {
  @Input() public hideFilter = false;
  @Input() public filterMenuOptions: Map<string, FilterMenuOption> = new Map();
  @Input() public filterManager: FilterManager;
  @Input() public paramSpecificFilterManager: ParamSpecificFilterManager;
  @Input() public columnDefs: Map<string, Column<T>> = new Map();
  @Input() public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  @Input() public useBackendFilter = false;
  @Input() public keyTabManager: KeyTabManager = new KeyTabManager();
  @Input() public placeholder = 'Search the table';
  @Input() public tooltip = `Enter keywords to search the table's contents. Search is not case-sensitive.`;
  @Output() public filterSearchDelay = new EventEmitter<any>();
  @Output() public filterBySearchParam = new EventEmitter<any>();
  private filterTypeSeparator = ':';

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  public capitalizeFirstLetter = capitalizeFirstLetter;

  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

  constructor() {}

  public ngOnChanges(): void {
    if (!this.hideFilter) {
      this.filterManager.createNestedFilterPredicate(this.dataSource, this.columnDefs);
      this.filterManager.applyFilter(this.dataSource);
    }
  }

  public getFilterMenuOptions(): Array<FilterMenuOption> {
    return Array.from(this.filterMenuOptions.values());
  }

  public handleSearchFilterOnKeyUp(inputValue: string, option: ParamSpecificSearchOption): void {
    this.filterSearchDelay.emit({
      inputValue,
      option,
      filterBar: this.filterManager.filterBar,
    });
  }

  public addParamSpecificFilterOptionWithValueToFilterBarOnBlur(
    value: string,
    option: ParamSpecificSearchOption,
    filterInput: HTMLInputElement | MatSelect
  ): void {
    if (!value) {
      return;
    }
    this.paramSpecificFilterManager.addParamSpecificFilterOptionWithValueToFilterBar(value, option, this.filterManager.filterBar);
    // Close the filter menu.
    this.trigger.closeMenu();
    // Need to delay clearing the input value so that it doesn't use '' as the value when filtering.
    setTimeout(() => {
      filterInput.value = '';
    }, 1000);
  }

  private filterBySearchParamEventFunc(): void {
    this.filterBySearchParam.emit();
  }

  public addInputChipFilterOptionOnBlur(event: MatChipInputEvent, dataSource: MatTableDataSource<T>): void {
    const filterInputValue = event.value;
    const filterValueStringArray = filterInputValue.split(this.filterTypeSeparator);
    if (filterValueStringArray.length > 1) {
      // Check if the typed input matches a checkbox option:
      const filterType = filterValueStringArray[0].trim().toLowerCase();
      const filterValue = filterValueStringArray.slice(1).join(this.filterTypeSeparator).trim().toLowerCase();
      let customFilterMatchFound = false;
      for (const checkboxOption of this.filterManager.checkboxOptions) {
        if (checkboxOption.label.toLowerCase().includes(filterType) && checkboxOption.displayName.toLowerCase().includes(filterValue)) {
          this.filterManager.onCheckBoxToggle(checkboxOption, dataSource);
          customFilterMatchFound = true;
        }
      }
      for (const paramSpecificSearchOption of this.paramSpecificFilterManager.paramSpecificSearchOptions) {
        if (paramSpecificSearchOption.label.toLowerCase().includes(filterType)) {
          this.paramSpecificFilterManager.addParamSpecificFilterOptionWithValueToFilterBar(
            filterValue,
            paramSpecificSearchOption,
            this.filterManager.filterBar
          );
          customFilterMatchFound = true;
        }
      }
      if (!!customFilterMatchFound) {
        const input = event.input;
        if (input) {
          input.value = '';
        }
        return;
      }
    }
    if (this.useBackendFilter) {
      this.filterManager.addInputChipFilterOption(event, dataSource, this.filterBySearchParamEventFunc.bind(this));
      return;
    }
    this.filterManager.addInputChipFilterOption(event, dataSource);
  }

  public removeInputChipFilterOptionOnBlur(option: FilterOption, dataSource: MatTableDataSource<T>): void {
    if (option.type === FilterType.PARAM_SPECIFIC_SEARCH) {
      const paramSpecificSearchOption = option as ParamSpecificSearchOption;
      this.paramSpecificFilterManager.removeParamSpecificSearchOptionFromFilterBar(paramSpecificSearchOption, this.filterManager.filterBar);
      return;
    }
    if (this.useBackendFilter && option.type === FilterType.INPUT) {
      this.filterManager.removeOptionFromFilterBar(option, dataSource, this.filterBySearchParamEventFunc.bind(this));
      return;
    }
    this.filterManager.removeOptionFromFilterBar(option, dataSource);
  }

  public getParamSpecificSearchTextOptions(menuOption: FilterMenuOption): Array<ParamSpecificSearchOption> {
    if (!this.paramSpecificFilterManager) {
      return [];
    }
    if (menuOption.type !== FilterMenuOptionType.text) {
      return [];
    }
    return this.paramSpecificFilterManager.getParamSpecificSearchOptions(menuOption.displayName);
  }

  public getParamSpecificSearchDropdownOptions(menuOption: FilterMenuOption): Array<ParamSpecificSearchOption> {
    if (!this.paramSpecificFilterManager) {
      return [];
    }
    if (menuOption.type !== FilterMenuOptionType.dropdown) {
      return [];
    }
    return this.paramSpecificFilterManager.getParamSpecificSearchOptions(menuOption.displayName);
  }

  public getFilterDisplayName(option: FilterOption): string {
    if (option.label !== '') {
      return option.label + ': ' + option.displayName;
    } else {
      return option.name;
    }
  }

  public clearAllChipsFromFilterBar(): void {
    const existingFilterOptions = this.filterManager.getActiveFilterOptions();
    while (existingFilterOptions.length !== 0) {
      for (const option of existingFilterOptions) {
        this.removeInputChipFilterOptionOnBlur(option, this.dataSource);
      }
    }
  }
}
