import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { Log, DiagnosticsService } from '@agilicus/angular';
import { combineLatest, Observable, of } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { startWith, map, takeUntil, concatMap } from 'rxjs/operators';
import { Application } from '@agilicus/angular';
import { fillOptionalDataFromForm } from '@app/shared/components/utils';
import {
  TimeIntervalOption,
  getDefaultTimeIntervalOptions,
  setStartAndEndDatesForFilter,
  getStartDateMaxSetter,
  getEndDateMinSetter,
} from '@app/shared/components/date-utils';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject } from 'rxjs';
import { ApplicationsService } from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectCanReadDiagnostics } from '@app/core/user/permissions/diagnostics.selectors';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { OrgQualifiedPermission, createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { isANumber } from '../validation-utils';
import { InputData } from '../custom-chiplist-input/input-data';
import { Column, createReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { FilterMenuOption } from '../table-filter/table-filter.component';
import { MapObject, UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';

export enum DtSort {
  asc = 'asc',
  desc = 'desc',
}
export interface LogRequest {
  app?: string;
  dt_from?: string;
  dt_sort: DtSort;
  dt_to?: string;
  env?: string;
  limit?: number;
  org_id?: string;
  sub_org_id?: string;
}

export interface LogElement extends Log, InputData {}

export interface CombinedPermissionsAndData {
  permission: OrgQualifiedPermission;
  applications: Array<Application>;
}

@Component({
  selector: 'portal-application-diagnose',
  templateUrl: './application-diagnose.component.html',
  styleUrls: ['./application-diagnose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationDiagnoseComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<LogElement>> = new Map();
  public tableData: Array<LogElement>;
  private orgId: string;
  private applications: Array<Application>;
  private logs$: Observable<Array<Log>>;
  public dataSource: MatTableDataSource<Log> = new MatTableDataSource();
  public hasPermissions: boolean;
  public timeIntervalOptions: Array<TimeIntervalOption> = getDefaultTimeIntervalOptions();
  public maxDate: Date = new Date();
  public diagnoseAppForm: UntypedFormGroup;
  public filteredAppOptions: Observable<Array<string>>;
  public filterManager: FilterManager = new FilterManager();
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getStartDateMaxSetter = getStartDateMaxSetter;
  public getEndDateMinSetter = getEndDateMinSetter;
  public pageDescriptiveText = `For applications hosted on the Agilicus platform, see the diagnostic (stdout/stderr) logs`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-diagnose`;

  @ViewChild('logsTableSort', { static: true }) public logsTableSort: MatSort;

  constructor(
    private logsQueryService: DiagnosticsService,
    private formBuilder: UntypedFormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private changeDetector: ChangeDetectorRef,
    private papa: Papa,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private applicationsService: ApplicationsService
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.initializeColumnDefs();
    this.getPermissionsAndData();
  }

  private getApplicationsForLogs(): Observable<Array<Application>> {
    return this.applicationsService
      .listApplications({
        org_id: this.orgId,
        assigned: true,
        owned: true,
        show_status: true,
        application_type: ['user_defined'],
      })
      .pipe(
        map((applicationsResp) => {
          return applicationsResp.applications;
        })
      );
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndData> {
    const permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanReadApps, selectCanReadDiagnostics)));
    return permissions$.pipe(
      concatMap((hasPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasPermissionsResp?.orgId;
        this.hasPermissions = hasPermissionsResp?.hasPermission;
        let applications$: Observable<Array<Application>> = of(undefined);
        if (!!this.orgId && this.hasPermissions) {
          applications$ = this.getApplicationsForLogs();
        }
        return combineLatest([of(hasPermissionsResp), applications$]);
      }),
      map(([hasPermissionsResp, applicationsResp]: [OrgQualifiedPermission, Array<Application>]) => {
        const combinedPermissionsAndData: CombinedPermissionsAndData = {
          permission: hasPermissionsResp,
          applications: applicationsResp,
        };
        return combinedPermissionsAndData;
      })
    );
  }

  public getPermissionsAndData(): void {
    const combinedPermissionsAndData$ = this.getCombinedPermissionsAndData$();
    combinedPermissionsAndData$.pipe(takeUntil(this.unsubscribe$)).subscribe((combinedPermissionsAndDataResp) => {
      if (!this.hasPermissions) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      if (!combinedPermissionsAndDataResp?.applications) {
        return;
      }
      this.applications = combinedPermissionsAndDataResp?.applications;
      this.setAppAutocomplete();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.diagnoseAppForm = this.formBuilder.group({
      dtFrom: null,
      dtTo: null,
      quick_select_time: 'custom',
      dtSort: 'desc',
      limit: ['', [this.customValidatorsService.customValidator(isANumber)]],
      app: '',
      env: '',
    });
  }

  public getLogsFilterData(): LogRequest {
    const logsFilterData: LogRequest = {
      dt_sort: this.diagnoseAppForm.value.dtSort,
      org_id: this.orgId,
    };
    fillOptionalDataFromForm(logsFilterData, this.diagnoseAppForm, ['app', 'env', 'limit']);
    const targetDates = setStartAndEndDatesForFilter(this.diagnoseAppForm);
    if (targetDates.startDate) {
      logsFilterData.dt_from = targetDates.startDate.toISOString();
    }
    if (targetDates.endDate) {
      logsFilterData.dt_to = targetDates.endDate.toISOString();
    }
    return logsFilterData;
  }

  public getLogs(): void {
    const logsFilterData = this.getLogsFilterData();
    this.logs$ = this.logsQueryService
      .listLogs({
        org_id: logsFilterData.org_id,
        dt_from: logsFilterData.dt_from,
        dt_to: logsFilterData.dt_to,
        app: logsFilterData.app,
        limit: logsFilterData.limit,
        sub_org_id: logsFilterData.sub_org_id,
        env: logsFilterData.env,
        dt_sort: logsFilterData.dt_sort,
      })
      .pipe(
        map((logsResp) => {
          return logsResp.logs;
        })
      );
    this.logs$.pipe(takeUntil(this.unsubscribe$)).subscribe((logsResp) => {
      this.tableData = logsResp;
      this.changeDetector.detectChanges();
    });
  }

  private initializeColumnDefs(): void {
    const timestampColumn = createReadonlyColumn('timestamp');
    const appColumn = createReadonlyColumn('app');
    const streamColumn = createReadonlyColumn('stream');
    const logColumn = createReadonlyColumn('log');

    setColumnDefs([timestampColumn, appColumn, streamColumn, logColumn], this.columnDefs);
  }

  public appSearchFilter(value: string): Array<string> {
    const filterValue = value.toLowerCase();
    const appNames = this.applications.map((e) => e.name);
    return appNames.filter((option) => option.toLowerCase().includes(filterValue));
  }

  private setAppAutocomplete(): void {
    const appControl = this.diagnoseAppForm.get('app');
    if (!this.applications || !appControl) {
      return;
    }
    this.filteredAppOptions = appControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      startWith(''),
      map((value) => this.appSearchFilter(value))
    );
  }

  /**
   * Rearranges the order of the columns so the csv display format
   * will match the table display format.
   */
  private reformatLogsData(data: Array<Log>): UnparseData {
    const logsData: Array<Array<string>> = [];
    const fields: string[] = ['timestamp', 'application', 'stream', 'message'];
    for (const log of data) {
      const logData = [log.timestamp.toString(), log.app, log.stream, log.log];
      logsData.push(logData);
    }
    const mapObject: MapObject = {
      fields: fields,
      data: logsData,
    };
    return mapObject;
  }

  public unparseDataToCsv(data: Array<Log>): string {
    const logsData = this.reformatLogsData(data);
    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };
    return this.papa.unparse(logsData, options);
  }

  private downloadLogs(data: Array<Log>): void {
    const logsCsv = this.unparseDataToCsv(data);
    const link = this.renderer.createElement('a');
    const blob = new Blob([logsCsv], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.download = 'logs_data.csv';
    link.click();
  }

  public getLogsAndDownload(): void {
    const logsFilterData = this.getLogsFilterData();
    this.logsQueryService
      .listLogs({
        org_id: logsFilterData.org_id,
        dt_from: logsFilterData.dt_from,
        dt_to: logsFilterData.dt_to,
        app: logsFilterData.app,
        limit: logsFilterData.limit,
        sub_org_id: logsFilterData.sub_org_id,
        env: logsFilterData.env,
        dt_sort: logsFilterData.dt_sort,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((logsResp) => {
        this.downloadLogs(logsResp.logs);
      });
  }
}
